import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import AuthContext from '../../context/AuthContext'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import SupplierAPI from '../../api/SupplierAPI'
import Loader from '../../components/Loader/Loader'
import Card from '../../components/Card/Card'
import styles from './SupplierProductBidsView.module.scss'
import moment from 'moment/moment'
import OrganizationAPI from '../../api/OrganizationAPI'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import InputWithError from '../../components/InputWithError/InputWithError'
import Button from '../../components/Button/Button'
import { toast } from 'react-toastify'
import Badge from '../../components/Badge/Badge'

const SupplierProductBidsView = () => {
    const { user } = useContext(AuthContext)
    const { id } = useParams()

    const [showBidPopup, setShowBidPopup] = useState(false)
    const [bidPrice, setBidPrice] = useState('')
    const [comment, setComment] = useState('')

    const openBidPopup = () => setShowBidPopup(true)
    const closeBidPopup = () => setShowBidPopup(false)

    const getBidQuery = useQuery(['bid', id], () => SupplierAPI.getBidById({ token: user.token, id }), {
        select: (data) => {
            const bidData = data?.data
            console.log(bidData)
            return {
                id: bidData.id,
                created_at: moment(bidData.created_at).format('DD/MM/YYYY'),
                note_to_supplier: bidData.note_to_supplier,
                bid_expiration_date: moment(bidData.bid_expiration_date).format('DD/MM/YYYY'),
                bid_status: bidData?.bid_status,
                auction_header: {
                    id: bidData.auction_header.id,
                    title: bidData.auction_header.title,
                    product_category: bidData.auction_header.product_category,
                    product_sub_category: bidData.auction_header.product_sub_category,
                    need_by_date: moment(bidData.auction_header.need_by_date).format('DD/MM/YYYY'),
                    created_at: moment(bidData.auction_header.created_at).format('DD/MM/YYYY'),
                    is_open: bidData.auction_header.is_open,
                    status: bidData.auction_header.status,
                    partial_bidding: bidData.auction_header.partial_bidding,
                    partial_quantity_bidding: bidData.auction_header.partial_quantity_bidding,
                },
            }
        },
    })
    const bidData = getBidQuery?.data
    console.log(bidData)

    // const getAuctionLinesQuery = useQuery(['auctionLines', id], async () => {
    //     const apiUrl = `http://metics.us:8000/auction-lines/${id}`
    //     const response = await fetch(apiUrl, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: 'Token ' + user.token,
    //         },
    //     })
    //     if (!response.ok) {
    //         throw new Error('Error fetching auction lines')
    //     }
    //     return response.json()
    // })

    // const bidData = getBidQuery?.data?.dataZ

    const getAuctionByIdQuery = useQuery(
        ['auctions-by-id'],
        () => OrganizationAPI.getAuctionById({ token: user.token, id: bidData.auction_header.id }),
        { enabled: !!bidData }
    )

    const organisationId = getAuctionByIdQuery.data?.data.organisation

    const getOrganizationQuery = useQuery(
        ['organization', organisationId],
        () =>
            OrganizationAPI.getOrganizationById({
                id: organisationId,
                token: user.token,
            }),
        {
            enabled: !!organisationId,
            select: (data) => {
                return {
                    Name: data.data.name,
                    Email: data.data.email,
                    Contact: data.data.contact,
                    Address: data.data.address_line1 + ', ' + data.data.address_line2,
                }
            },
        }
    )
    const getAuctionLinesQuery = useQuery(
        ['auction-lines'],
        () =>
            OrganizationAPI.getAuctionLines({
                id: bidData.auction_header.id,
                token: user.token,
            }),
        {
            select: (data) => {
                return data?.data.map((auction) => {
                    return {
                        Name: auction.product_name,
                        Quantity: `${auction.quantity} ${auction.uom_code}`,
                        Brand: auction.brand,
                        'Target Price': auction.target_price,
                        'Created At': moment(auction.created_at).format('DD/MM/YYYY'),
                    }
                })
            },
            enabled: !!bidData,
        }
    )

    const BidLines = useQuery(['bid-lines'], () => OrganizationAPI.getBidLines({ token: user.token, id: bidData.id }), {
        enabled: !!bidData,
    })

    const auctionLinesData = getAuctionLinesQuery.data || []
    const bidHeaderComments = useQuery(
        ['auction-comments'],
        () => OrganizationAPI.getBidHeaderComments({ token: user.token, id: bidData?.id }),
        {
            enabled: !!bidData?.id,
            select: (data) => {
                return data.data.map((comment) => ({
                    created_at: moment(comment.created_at).format('MMMM DD, YYYY'),
                    create_by: comment.author,
                    message: comment.message,
                }))
            },
        }
    )
    const [itemCount, setItemCount] = useState(auctionLinesData.length || 0)
    useEffect(() => {
        setItemCount(auctionLinesData.length || 0)
    }, [auctionLinesData])

    const addBidHeaderComment = useMutation(
        (data) => OrganizationAPI.addBidHeaderComment({ token: user.token, id: bidData?.id, data }),
        {
            onSuccess: () => {
                toast.success('Comment added successfully')
                bidHeaderComments.refetch()
            },
            onError: () => {
                toast.error('Error adding comment')
            },
        }
    )

    const handlePost = (e) => {
        e.preventDefault()
        addBidHeaderComment.mutate({ message: comment })
        setComment('')
    }

    return (
        <SupplierLayout title="Bids" active="bids">
            {getBidQuery.isError ? (
                <Loader refresh />
            ) : getBidQuery.isLoading ? (
                <Loader />
            ) : bidData ? (
                <>
                    <div className={styles.Details}>
                        <Card className={styles.Bid}>
                            <p className={styles.Bid__subTitle}>
                                <span>{bidData.auction_header.product_category}</span> {'>'}{' '}
                                <span>{bidData.auction_header.product_sub_category}</span>
                            </p>
                            <p className={styles.Bid__title}>{bidData.auction_header.title}</p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Created Date</span>
                                <span className={styles.Bid__note__sub}>{bidData.auction_header.created_at}</span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Quotation Deadline</span>
                                <span className={styles.Bid__note__sub}>{bidData.auction_header.need_by_date}</span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>RFQ Type</span>
                                <span className={styles.Bid__note__sub}>
                                    {bidData.auction_header.is_open ? 'Open' : 'Closed'}
                                </span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>RFQ Status</span>
                                {bidData.auction_header.status === 'draft' ? (
                                    <Badge
                                        value={bidData.auction_header.status}
                                        color="#f7a64f"
                                        backgroundColor="#fdebd8"
                                    />
                                ) : bidData.auction_header.status === 'In-Progress' ? (
                                    <Badge
                                        value={bidData.auction_header.status}
                                        color="#1BBB6B"
                                        backgroundColor="#EDF8F3"
                                    />
                                ) : (
                                    <Badge
                                        value={bidData.auction_header.status}
                                        color="#FC555B"
                                        backgroundColor="#FED7D8"
                                    />
                                )}
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Note to Supplier</span>
                                <span className={styles.Bid__note__sub}>{bidData.note_to_supplier}</span>
                            </p>
                        </Card>
                        <Card>
                            <p className={styles.Bid__title}>Buyer Details</p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Name</span>
                                <span className={styles.Bid__note__sub}>{getOrganizationQuery?.data?.Name}</span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Email</span>
                                <span className={styles.Bid__note__sub}>{getOrganizationQuery?.data?.Email}</span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Contact</span>
                                <span className={styles.Bid__note__sub}>{getOrganizationQuery?.data?.Contact}</span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Address</span>
                                <span className={styles.Bid__note__sub}>{getOrganizationQuery?.data?.Address}</span>
                            </p>
                        </Card>
                    </div>
                    <div className={styles.DetailsContainer}>
                        <Card>
                            <p className={styles.Bid__title}>Bid Details</p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Status</span>
                                {bidData.bid_status === 'draft' ? (
                                    <Badge value={bidData.bid_status} color="#f7a64f" backgroundColor="#fdebd8" />
                                ) : (
                                    <span className={styles.Bid__note__sub}>{bidData.bid_status}</span>
                                )}
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Bidded Date</span>
                                <span className={styles.Bid__note__sub}>{bidData.created_at}</span>
                            </p>
                            <p className={styles.Bid__note}>
                                <span className={styles.Bid__note__main}>Bid Expiration date</span>
                                <span className={styles.Bid__note__sub}>{bidData.bid_expiration_date}</span>
                            </p>
                        </Card>
                        <Card>
                            <p className={styles.Bid__title}>Comments</p>
                            <div className={styles.timeline}>
                                <div className={styles.timeline__Content}>
                                    {bidHeaderComments?.data?.map((item, index) => (
                                        <div className={styles.tlContent} key={index}>
                                            <div className={styles.tlHeader}>
                                                <span className={styles.tlMarker}></span>
                                                <div className={styles.tlAuthor}>
                                                    {item.create_by}
                                                    <time className={styles.tlTime} dateTime={item.created_at}>
                                                        {item.created_at}
                                                    </time>
                                                </div>
                                            </div>
                                            <div className={styles.tlBody}>
                                                <p>{item.message}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <p className={styles.Note}>
                                    <b>Note</b>: Avoid disclosing private information here.
                                </p>
                                <form className={styles.commentForm} onSubmit={handlePost}>
                                    <div className={styles.comment}>
                                        <InputWithError
                                            name={comment}
                                            placeholder={'Comment here'}
                                            type={'text'}
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                    <Button
                                        className={styles.commentBtn}
                                        text="Post"
                                        type="submit"
                                        loading={addBidHeaderComment.isLoading}
                                    />
                                </form>
                            </div>
                        </Card>
                    </div>
                </>
            ) : (
                <p>No bid data available.</p>
            )}
            <br />
            {getAuctionByIdQuery.isError || getAuctionLinesQuery.isError || getOrganizationQuery.isError ? (
                <Loader refresh />
            ) : getAuctionByIdQuery.isLoading || getAuctionLinesQuery.isLoading || getOrganizationQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    <Card className={styles.AuctionLines}>
                        {/* <span className={styles.AuctionLines__title}>Items</span> */}
                        <div className={styles.AuctionLinesHeader}>
                            <span className={styles.AuctionLines__title}>Items</span>
                            <span className={styles.AuctionLines__counter}>{itemCount}</span>
                        </div>
                        <p className={styles.Note}>
                            <b>Note</b>: Avoid disclosing private information here.
                        </p>
                        {/* {getAuctionLinesQuery.isLoading ? (
                    <Loader />
                ) : getAuctionLinesQuery.isError ? (
                    <p>Error loading auction lines</p>
                ) : (
                    auctionLinesData.map((auctionLine, index) => (
                        <div key={index} className={styles.AuctionLine}>
                            <p>Auction Line Title: {auctionLine.title}</p>
                            <p>Description: {auctionLine.description}</p>
                        </div>
                    ))
                )} */}
                        <Table>
                            <TableContent>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Brand</th>
                                        <th>Target Price</th>
                                        <th>Created At</th>
                                        <th>Price</th>
                                        <th>Promised Date</th>
                                        {bidData?.auction_header?.partial_quantity_bidding && <th>Quantity</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {getAuctionLinesQuery.data?.length === 0 && (
                                        <tr>
                                            <td colSpan={8} style={{ textAlign: 'center' }}>
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                    {getAuctionLinesQuery.data?.map((row, id) => {
                                        const bidLine = BidLines?.data?.data[id]
                                        const isBidded =
                                            bidLine && (bidLine.price || bidLine.promised_date || bidLine.bid_quantity)

                                        return (
                                            <tr key={id}>
                                                <td>{id + 1}</td>
                                                {Object.values(row).map((field, index) => (
                                                    <td key={index}>{field}</td>
                                                ))}
                                                {!isBidded ? (
                                                    <td
                                                        colSpan={
                                                            bidData?.auction_header?.partial_quantity_bidding ? 3 : 2
                                                        }
                                                        className={styles.HighlightPrice}
                                                    >
                                                        <Badge
                                                            value={'Not Bidded'}
                                                            color="#FC555B"
                                                            backgroundColor="#FED7D8"
                                                        />
                                                    </td>
                                                ) : (
                                                    <>
                                                        <td className={styles.HighlightPrice}>
                                                            {bidLine?.price || '-'}
                                                        </td>
                                                        <td className={styles.HighlightPromisedDate}>
                                                            {bidLine?.promised_date
                                                                ? moment(bidLine.promised_date).format('DD/MM/YYYY')
                                                                : '-'}
                                                        </td>
                                                        {bidData?.auction_header?.partial_quantity_bidding && (
                                                            <td className={styles.HighlightPrice}>
                                                                {bidLine?.bid_quantity || '-'}
                                                            </td>
                                                        )}
                                                    </>
                                                )}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </TableContent>
                        </Table>
                    </Card>
                    <br />
                </>
            )}
        </SupplierLayout>
    )
}

export default SupplierProductBidsView
