import Table from '../../../components/Table/Table'
import TableContent from '../../../components/Table/TableContent/TableContent'
import styles from './LineTable.module.scss'
import { FaEdit, FaTrash } from 'react-icons/fa'

const LineTable = ({ data = [], handleRemove, handleEditItem }) => {
    const truncate = (input) => (input && input.length > 40 ? `${input.substring(0, 40)}...` : input)
    return (
        <Table outline={false}>
            <TableContent hoverable>
                <thead>
                    <tr>
                        <th>Line No</th>
                        <th>Item Name</th>
                        <th>Product ID</th>
                        <th>Quantity</th>
                        <th>UOM Code</th>
                        <th>Target Price</th>
                        <th>Description</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((line, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{line.product_name}</td>
                                <td>{line.product_id}</td>
                                <td>{line.quantity}</td>
                                <td>{line.uom_code}</td>
                                <td>{line.target_price}</td>
                                <td title={line.product_description}>{truncate(line.product_description)}</td>
                                <td>
                                    <FaEdit
                                        className={styles.CreateRFQ__editIcon}
                                        onClick={() => handleEditItem(line)}
                                    />
                                </td>
                                <td>
                                    <FaTrash
                                        className={styles.CreateRFQ__editIcon}
                                        onClick={() => handleRemove(line.id)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </TableContent>
        </Table>
    )
}

export default LineTable
