// styles
import styles from './OrganizationCreateRFQ.module.scss'
// context
import AuthContext from '../../context/AuthContext'

// api
import OrganizationAPI from '../../api/OrganizationAPI'

// packages
import { useState, useContext, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import cx from 'classnames'

import React from 'react'

// components
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import InputWithError from '../../components/InputWithError/InputWithError'
import Card from '../../components/Card/Card'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import Button from '../../components/Button/Button'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import StepSlider from '../../components/StepSlider/StepSlider'
import Modal from '../../components/Modal/Modal'
import FormSelect from '../../components/FormSelect/FormSelect'
import Loader from '../../components/Loader/Loader'
import AttachmentTable from './AttachmentTable/AttachmentTable'
import FocalPointModal from './FocalPointModal/FocalPointModal'
import HeaderAttachmentModal from './HeaderAttachmentModal/HeaderAttachmentModal'
import AddLineModal from './AddLineModal/AddLineModal'
import LineTable from './LineTable/LineTable'
import FocalPointTable from './FocalPointTable/FocalPointTable'
import { toast } from 'react-toastify'
import ProductAPI from '../../api/ProductAPI'
import FormRadio from '../../components/FormRadio/FormRadio'
import SuccessModal from './SuccessModal/SuccessModal'
import { useNavigate } from 'react-router-dom'
import EditLineModal from './AddLineModal/EditLineModal'
import Toggle from '../../components/Toggle/Toggle'

const AuthorizedSellersPopup = ({ sellers, selectedSellers, onClose, onSelect }) => {
    const [selected, setSelected] = useState(selectedSellers)

    const handleSelectSeller = (seller) => {
        const sellerIndex = selected.findIndex((s) => s.id === seller.id)
        if (sellerIndex >= 0) {
            setSelected(selected.filter((s) => s.id !== seller.id))
        } else {
            setSelected([...selected, seller])
        }
    }

    const [searchTerm, setSearchTerm] = useState('')

    const filteredSellers = searchTerm
        ? sellers.filter((seller) => seller.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : sellers

    const handleSearchChange = (value) => {
        setSearchTerm(value)
    }
    const handleSubmit = () => {
        onSelect(selected)
        onClose()
    }

    return (
        <Modal onClose={onClose}>
            <div className={styles.CreateRFQ__popupContainer}>
                <h2 className={styles.CreateRFQ__title}>Select Authorized Sellers</h2>
                <div className={styles.CreateRFQ__searchWrapper}>
                    <TableControlSearch onSearchChange={handleSearchChange} />
                </div>
                <div className={styles.CreateRFQ__tableScroll}>
                    <Table>
                        <TableContent>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Name</th>
                                    <th>City</th>
                                    <th>Contact</th>
                                    <th>Total Turnover</th>
                                    <th>No: of Employees</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSellers.map((seller) => (
                                    <tr key={seller.id}>
                                        <td>
                                            <input
                                                className={styles.CreateRFQ__checkbox}
                                                type="checkbox"
                                                checked={selected.some((s) => s.id === seller.id)}
                                                onChange={() => handleSelectSeller(seller)}
                                            />
                                        </td>
                                        <td>{seller.name}</td>
                                        <td>{seller.city}</td>
                                        <td>{seller.contact}</td>
                                        <td>{seller.total_turnover}</td>
                                        <td>{seller.number_of_employees}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </TableContent>
                    </Table>
                </div>
                <Button className={styles.CreateRFQ__submit_btn} text="Select Sellers" onClick={handleSubmit} />
            </div>
        </Modal>
    )
}

const OrganizationCreateRFQ = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [pageNumber, setPageNumber] = useState(0)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [editLineModalActive, setEditLineModalActive] = useState(false)
    const [editLineData, setEditLineData] = useState({})
    const [id, setId] = useState('')
    const [showAuthorizedSellersPopup, setShowAuthorizedSellersPopup] = useState(false)
    const [selectedSellersDisplay, setSelectedSellersDisplay] = useState('')

    const handleAuthorizedSellerSelection = (selected) => {
        setHeaderForm((prev) => ({ ...prev, authorized_sellers: selected.map((sel) => sel.id) }))
        setSelectedSellersDisplay(selected.map((sel) => sel.name).join(', '))
    }

    const [headerForm, setHeaderForm] = useState({
        additional_attributes: [],
        is_open: true,
        authorized_sellers: [],
        partial_bidding: false,
    })

    const [headerFormErrors, setHeaderFormErrors] = useState({})

    const handleHeaderFormChange = (e) => {
        const { name, value } = e.target
        const isCheckbox = e.target.type === 'checkbox'
        const newValue = isCheckbox ? e.target.checked : value

        setHeaderForm((prev) => {
            // If the category is changed, reset the subcategory
            if (name === 'product_category') {
                return { ...prev, [name]: newValue, product_sub_category: '' }
            }

            // For other inputs, update as usual
            return { ...prev, [name]: newValue }
        })

        setHeaderFormErrors((prev) => ({ ...prev, [name]: undefined }))
    }

    const productCatgoriesQuery = useQuery(
        ['product-categories'],
        () =>
            ProductAPI.getProductCategories({
                token: user.token,
            }),
        {
            select: (data) => {
                const categories = {}
                data?.data.forEach((category) => (categories[category.name] = category.slug))
                return categories
            },
        }
    )

    const productSubCategoriesQuery = useQuery(
        ['product-subcategories', headerForm.product_category],
        () =>
            ProductAPI.getProductSubCategories({
                token: user.token,
                category: headerForm.product_category,
            }),
        {
            select: (data) => {
                const categories = {}
                data.data?.forEach((category) => (categories[category.name] = category.slug))
                return categories
            },
            enabled: !!headerForm.product_category,
        }
    )

    const measurements = useQuery(
        ['measurements'],
        () =>
            ProductAPI.getMeasurements({
                token: user.token,
            }),
        {
            select: (data) => {
                const measures = Object.entries(data.data).reduce((acc, [key, value]) => {
                    acc[value.description + ' ( ' + key + ' )'] = key
                    return acc
                }, {})
                return measures
            },
            enabled: pageNumber === 1,
        }
    )

    const authorizedSellersQuery = useQuery(
        ['authorized-sellers'],
        () => {
            return OrganizationAPI.getAuthorizedSellers({
                orgId: user.organisation,
                token: user.token,
            })
        },
        {
            enabled: pageNumber === 2,
        }
    )

    const getAuctionLinesQuery = useQuery(
        ['get-auction-lines', id],
        () => OrganizationAPI.getAuctionLines({ token: user.token, id }),
        {
            onSuccess: (data) => {
                const updatedData = data.data.map((item) => ({
                    ...item,
                }))

                setLineForm(updatedData)
            },
            onError: (err) => {
                toast.error('Some error occurred!')
            },
            enabled: !!id,
        }
    )

    const createAuctionHeaderMutation = useMutation(OrganizationAPI.createAuctionHeader, {
        onSuccess: (data) => {
            toast.success('Auction Header Created')
            setId(data.data?.id)
            console.log(data)
        },
        onError: (err) => {
            if (err.response.status === 400) {
                setPageNumber(0)
                return setHeaderFormErrors(err.response.data)
            }
            toast.error('Some error occured!')
            console.error(err)
        },
    })

    const createAuctionLineMutation = useMutation(OrganizationAPI.createAuctionLine, {
        onSuccess: (data) => {
            console.log(data)

            getAuctionLinesQuery.refetch()
            toast.success('Auction Lines Added')
        },
        onError: (err) => {
            toast.error('Some error occured!')
            console.error(err)
        },
    })

    const updateAuctionLineMutation = useMutation(OrganizationAPI.UpdateAuctionLine, {
        onSuccess: (data) => {
            toast.success('Auction Lines Updated')
            getAuctionLinesQuery.refetch()
        },
        onError: (err) => {
            toast.error('Some error occured!')
        },
    })

    const deleteAuctionHeaderAttachmentMutation = useMutation(OrganizationAPI.deleteAuctionHeaderAttachment, {
        onSuccess: (data) => {
            toast.success('Auction Header Attachment Deleted')
        },
        onError: (err) => {
            toast.error('Some error occured!')
        },
    })

    const deleteAuctionLineMutation = useMutation(OrganizationAPI.DeleteAuctionLine, {
        onSuccess: (data) => {
            toast.success('Auction Lines Deleted')
            getAuctionLinesQuery.refetch()
        },
        onError: (err) => {
            toast.error('Some error occured!')
        },
    })

    const updateAuctionHeaderMutation = useMutation(OrganizationAPI.updateAuctionHeader, {
        onSuccess: (data) => {
            console.log(data)
            toast.success('Auction Header Updated')
        },
        onError: (err) => {
            toast.error('Some error occured!')
            console.error(err)
        },
    })

    const deleteAuctionLineAttachmentMutation = useMutation(OrganizationAPI.deleteAuctionLineAttachment, {
        onSuccess: (data) => {
            toast.success('Auction Line Attachment Deleted')
            getAuctionLinesQuery.refetch()
        },
        onError: (err) => {
            toast.error('Some error occured!')
        },
    })

    const handleAuthorizedSellerChange = (e) => {
        console.log(e.target)
        setHeaderForm((prev) => {
            return {
                ...prev,
                authorized_sellers: [...prev.authorized_sellers, ...e.target.value],
            }
        })
        setShowAuthorizedSellersPopup(true)
    }

    const [focalPointData, setFocalPointData] = useState([])

    const [addFocalPointModalActive, setAddFocalPointModalActive] = useState(false)
    const getOrgStaffsQuery = useQuery(
        ['organization-staffs'],
        () => OrganizationAPI.getOrganizationStaffs({ token: user.token, id: user.organisation }),
        {
            select: (data) => data?.data,
            enabled: addFocalPointModalActive,
        }
    )

    const getFilteredStaffData = (staffData) => {
        console.log(staffData)
        return staffData.filter((staff) => !focalPointData.find((data) => data.id === staff.id))
    }

    const handleFocalPointModalSubmit = (staffs) => {
        setFocalPointData((prev) => [...prev, ...staffs])
        setAddFocalPointModalActive(false)
        console.log({ focalPointData })
    }

    const handleFocalPointRemove = (id) => {
        setFocalPointData(focalPointData.filter((d) => d.id !== id))
    }

    const handleAttachmentTableRemove = (id) => {
        setHeaderAttachmentData(
            headerAttachementData.filter((d) => {
                const attachmentId = d.file?.info?.id || d.id
                return attachmentId !== id
            })
        )
    }
    // Focal Point Funcations - End

    // Header Attachments Functions - Begin
    const [headerAttachementData, setHeaderAttachmentData] = useState([])
    const [addHeaderAttachementModalActive, setAddHeaderAttachementModalActive] = useState(false)

    const handleHeaderAttachmentModalSubmit = (data) => {
        setHeaderAttachmentData((prev) => [...prev, data])
        setAddHeaderAttachementModalActive(false)
    }
    // Header Attachments Functions - End

    // Line Functions - Begin
    const [addLineModalActive, setAddLineModalActive] = useState(false)
    const [lineForm, setLineForm] = useState([])
    const [headerId, setHeaderId] = useState('')

    const handleLineModalSubmit = async (data) => {
        const payload = {
            ...data,
            attachments: data.attachments.map((a) => ({
                name: a.title,
                description: a.description,
                file: a.file.data, // Keeping the base64 data string
            })),
        }
        await createAuctionLineMutation.mutateAsync({
            token: user.token,
            data: payload,
            headerId,
        })
        // setLineForm((prev) => [...prev, data])
        setAddLineModalActive(false)
    }

    const handleEditLineModalSubmit = async (data) => {
        console.log(data)
        const filteredAttachments = data.attachments
            .filter((a) => a.file && a.file.data) // Filter condition
            .map((a) => ({
                name: a.title,
                description: a.description,
                file: a.file.data, // Keeping the base64 data string
            }))
        const payload = {
            ...data,
            attachments: filteredAttachments,
        }
        if (payload.attachments && payload.attachments.length === 0) {
            delete payload.attachments
        }
        if (payload.additional_attributes && payload.additional_attributes.length === 0) {
            delete payload.additional_attributes
        }

        await updateAuctionLineMutation.mutateAsync({
            token: user.token,
            data: payload,
            headerId: payload.id,
        })
        setEditLineModalActive(false)
    }

    const handleLineRemove = async (id) => {
        await deleteAuctionLineMutation.mutateAsync({
            token: user.token,
            headerId: id,
        })
        // setLineForm(lineForm.filter((l) => l.id !== id))
    }
    // Line Functions - End

    const handleLineAttachmentTableRemove = (id) => {
        deleteAuctionLineAttachmentMutation.mutate({
            token: user.token,
            headerId: id,
        })

        setEditLineData((prev) => ({
            ...prev,
            attachments: prev.attachments.filter((d) => {
                const attachmentId = d.file?.info?.id || d.id
                return attachmentId !== id
            }),
        }))
    }

    const steps = [
        {
            title: 'Auction Header',
            onClick: () => setPageNumber(0),
        },
        {
            title: 'Add Lines',
            onClick: () => setPageNumber(1),
        },
        {
            title: 'RFQ Attributes',
            onClick: () => setPageNumber(2),
        },
    ]

    const handleBeforeUnload = (event) => {
        if (lineForm.length > 0 || headerAttachementData.length > 0 || focalPointData.length > 0) {
            const message = 'You have unsaved changes. Are you sure you want to leave?'
            event.returnValue = message
            return message
        }
    }
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        const success = () => {
            let timeoutId

            if (showSuccessModal) {
                timeoutId = setTimeout(() => {
                    setShowSuccessModal(false)
                    navigate('/organization/rfq')
                }, 3000)
            }
        }
        success()
    }, [showSuccessModal])

    const validateHeaderForm = (fields) => {
        const errors = {}
        if (!headerForm.title) {
            errors.title = 'Title is required'
        }
        if (!headerForm.requisition_number) {
            errors.requisition_number = 'Requisition Number is required'
        }
        if (!headerForm.need_by_date) {
            errors.need_by_date = 'Quotation Deadline is required'
        }
        if (!headerForm.product_category) {
            errors.product_category = 'Product Category is required'
        }
        if (!headerForm.product_sub_category) {
            errors.product_sub_category = 'Product Sub Category is required'
        }
        if (Object.keys(errors).length > 0) {
            setHeaderFormErrors(errors)
            return
        }
    }

    const handleContinue = async (page, e) => {
        e.preventDefault()
        validateHeaderForm(headerForm)

        let headerData = {
            ...headerForm,
            focal_points: focalPointData.map((p) => ({ user: p.email, role: p.role })),
            attachments: headerAttachementData.map((a) => ({
                name: a.title,
                description: a.description,
                file: a.file.data,
            })),
            status: 'In-Progress',
            bln_incoterms: incotermsEnabled,
        }

        if (incotermsEnabled && selectedIncoterm) {
            headerData.incoterms = selectedIncoterm
        } else {
            delete headerData.incoterms
        }

        console.log('Final payload for page:', page, headerData)

        switch (page) {
            case 1:
                const res = await createAuctionHeaderMutation.mutateAsync({
                    data: headerData,
                    token: user.token,
                })
                const headerId = res.data?.id
                setHeaderId(headerId)
                navigate(`/organization/rfq/edit/${headerId}`, { state: { page } })
                setPageNumber(page)
                break
            case 2:
                setPageNumber(page)
                break
            default:
                break
        }
    }

    const handleToggleIncoterms = () => {
        setIncotermsEnabled(!incotermsEnabled)
        if (!incotermsEnabled && !selectedIncoterm && incotermsQuery.data && incotermsQuery.data.length > 0) {
            setSelectedIncoterm(incotermsQuery.data[0].slug)
        }
        console.log('Toggle Incoterms:', !incotermsEnabled)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        validateHeaderForm(headerForm)

        let headerData = {
            ...headerForm,
            focal_points: focalPointData.map((p) => ({ user: p.email, role: p.role })),
            attachments: headerAttachementData.map((a) => ({
                name: a.title,
                description: a.description,
                file: a.file.data,
            })),
            status: 'In-Progress',
            bln_incoterms: incotermsEnabled,
        }

        if (incotermsEnabled && selectedIncoterm) {
            headerData.incoterms = selectedIncoterm
        }

        console.log('Final payload:', headerData)

        try {
            await updateAuctionHeaderMutation.mutateAsync({
                token: user.token,
                data: headerData,
                headerId,
            })
            toast.success('Auction Published')
            setShowSuccessModal(true)
        } catch (error) {
            console.error('Error updating auction header:', error)
        }
    }

    const handlePreviousBtnClick = (page) => {
        setPageNumber(page)
    }

    const handleSaveAsDraft = async (e, page) => {
        e.preventDefault()
        validateHeaderForm(headerForm)
        const headerData = {
            ...headerForm,
            focal_points: focalPointData.map((p) => ({ user: p.email, role: p.role })),
            attachments: headerAttachementData.map((a) => ({
                name: a.title,
                description: a.description,
                file: a.file.data,
            })),
        }

        switch (page) {
            case 1: {
                const res = await createAuctionHeaderMutation.mutateAsync({
                    data: headerData,
                    token: user.token,
                })
                toast.success('Draft Saved')
                const headerId = res.data?.id
                setId(headerId)
                console.log({ headerId })
                navigate(`/organization/rfq/edit/${headerId}`)
                setHeaderId(headerId)
                break
            }
            case 2: {
                toast.success('Draft Saved')
                navigate(`/organization/rfq/edit/${headerId}?lineId=${id}`)
                break
            }
            default:
                break
        }
    }

    const [incotermsEnabled, setIncotermsEnabled] = useState(false)
    const [selectedIncoterm, setSelectedIncoterm] = useState('')

    const incotermsQuery = useQuery(['incoterms'], () => OrganizationAPI.getIncoterms({ token: user.token }), {
        select: (data) => {
            console.log('Incoterms data:', data)
            return data.data || []
        },
        enabled: !!user.token,
    })

    // const handleToggleIncoterms = () => {
    //     setIncotermsEnabled(!incotermsEnabled);
    //     console.log("Toggle Incoterms:", !incotermsEnabled);
    // }

    const handleIncotermChange = (e) => {
        setSelectedIncoterm(e.target.value)
    }

    const incotermOptions = React.useMemo(() => {
        if (!incotermsQuery.data || !Array.isArray(incotermsQuery.data)) {
            return {}
        }

        return incotermsQuery.data.reduce((acc, incoterm) => {
            acc[`${incoterm.short_form} - ${incoterm.full_form}`] = incoterm.slug
            return acc
        }, {})
    }, [incotermsQuery.data])

    useEffect(() => {
        if (incotermsQuery.data && incotermsQuery.data.length > 0 && !selectedIncoterm) {
            setSelectedIncoterm(incotermsQuery.data[0].slug)
        }
    }, [incotermsQuery.data])

    const handleEditItem = (line) => {
        console.log({ line })
        setEditLineModalActive(true)
        setEditLineData(line)
    }
    // ------------------
    // RENDER
    // --------------------
    return (
        <OrganizationLayout active="rfq" title="Create RFQ">
            {productCatgoriesQuery.isError ? (
                <Loader refresh />
            ) : productCatgoriesQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.CreateRFQ__titleWrapper}>
                        <h2 className={styles.CreateRFQ__title}>Create Request for Proposal</h2>
                        <p className={styles.CreateRFQ__subtitle}>Please provide details of this proposal</p>
                    </div>
                    <div className={styles.CreateRFQ__stepSlider}>
                        <StepSlider steps={steps} activeIndex={pageNumber} />
                    </div>
                    <Card className={styles.CreateRFQ__container}>
                        <form onSubmit={handleSubmit}>
                            {pageNumber === 0 && (
                                <div className={styles['row']}>
                                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-8'])}>
                                        <InputWithError
                                            onChange={handleHeaderFormChange}
                                            name="title"
                                            label="Title"
                                            placeholder="TODO-A-A"
                                            type="text"
                                            value={headerForm.title}
                                            errorMsg={headerFormErrors.title}
                                        />
                                    </div>
                                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                        <InputWithError
                                            onChange={handleHeaderFormChange}
                                            name="requisition_number"
                                            label="Requesition Number"
                                            placeholder="RFQ Requesition Number"
                                            type="text"
                                            value={headerForm.requisition_number}
                                            errorMsg={headerFormErrors.requisition_number}
                                        />
                                    </div>

                                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                        <InputWithError
                                            onChange={handleHeaderFormChange}
                                            name="need_by_date"
                                            label="Quotation Deadline"
                                            placeholder="Please choose a deadline for this request"
                                            type="date"
                                            value={headerForm.need_by_date}
                                            errorMsg={headerFormErrors.need_by_date}
                                        />
                                    </div>
                                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-8'])}></div>

                                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-6'])}>
                                        <FormSelect
                                            label="Product Category"
                                            name="product_category"
                                            options={productCatgoriesQuery.data}
                                            placeholder="Select Product Category"
                                            value={headerForm.product_category}
                                            errorMsg={headerFormErrors.product_category}
                                            onChange={handleHeaderFormChange}
                                        />
                                    </div>

                                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-6'])}>
                                        <FormSelect
                                            label="Product Sub Category"
                                            name="product_sub_category"
                                            options={productSubCategoriesQuery.data || {}}
                                            placeholder="Select Product Sub Category"
                                            value={headerForm.product_sub_category}
                                            errorMsg={headerFormErrors.product_sub_category}
                                            onChange={handleHeaderFormChange}
                                            isLoading={productSubCategoriesQuery.isLoading}
                                        />
                                    </div>

                                    <div className={styles.CreateRFQ__horizontalRule}></div>

                                    <div className={cx(styles['col-12'])}>
                                        <h2 className={styles.CreateRFQ__secondaryTitle}>RFQ Incharge</h2>
                                        <FocalPointTable data={focalPointData} handleRemove={handleFocalPointRemove} />
                                        <div className={styles.CreateRFQ__buttonWrapper}>
                                            <Button
                                                text="Add Focal Point"
                                                color="rgb(14, 44, 36)"
                                                onClick={() => setAddFocalPointModalActive(true)}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.CreateRFQ__horizontalRule}></div>
                                    <div className={cx(styles['col-12'])}>
                                        <h2 className={styles.CreateRFQ__secondaryTitle}>Incoterms</h2>
                                        <Toggle
                                            // label="Incoterms"
                                            name="incoterms_enabled"
                                            onToggle={handleToggleIncoterms}
                                            checked={incotermsEnabled}
                                        />
                                        {incotermsEnabled && (
                                            <FormSelect
                                                label="Select Incoterm"
                                                name="incoterm"
                                                options={incotermOptions}
                                                value={selectedIncoterm}
                                                onChange={handleIncotermChange}
                                                placeholder="Choose an Incoterm"
                                                isLoading={incotermsQuery.isLoading}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.CreateRFQ__horizontalRule}></div>
                                    <h2 className={styles.CreateRFQ__secondaryTitle}>Details</h2>

                                    <div className={cx(styles.CreateRFQ__inputWrapper)}>
                                        <FormTextarea
                                            name="note_to_supplier"
                                            placeholder="Any details to suppliers"
                                            value={headerForm.note_to_supplier}
                                            onChange={handleHeaderFormChange}
                                            label="Note to Suppliers"
                                            minRows={5}
                                        />
                                    </div>

                                    <div className={cx(styles['col-12'], styles.CreateRFQ__attachmentsWrapper)}>
                                        <h2 className={styles.CreateRFQ__tertiaryTitle}>Attachments</h2>
                                        <AttachmentTable
                                            data={headerAttachementData}
                                            handleDelete={handleAttachmentTableRemove}
                                        />
                                        <div className={styles.CreateRFQ__buttonWrapper}>
                                            <Button
                                                text="Add Attachment"
                                                color="rgb(14, 44, 36)"
                                                onClick={() => setAddHeaderAttachementModalActive(true)}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.CreateRFQ__horizontalRule}></div>
                                    <div className={styles.ButtonContainer}>
                                        {/* <Button
                                            text="Previous"
                                            className={styles.CreateRFQ__fullWidthButton__Cancel}
                                            onClick={() => handlePreviousBtnClick(1)}
                                            disabled={pageNumber === 0}
                                        /> */}
                                        <Button
                                            text="Save as Draft"
                                            className={styles.CreateRFQ__fullWidthButton__Draft}
                                            onClick={(e) => handleSaveAsDraft(e, 1)}
                                        />
                                        <Button
                                            text="Continue"
                                            className={styles.CreateRFQ__fullWidthButton}
                                            onClick={(e) => handleContinue(1, e)}
                                            loading={createAuctionHeaderMutation.isLoading}
                                        />
                                    </div>
                                </div>
                            )}
                            {pageNumber === 1 && (
                                <div>
                                    <h2 className={styles.CreateRFQ__secondaryTitle}>Add Lines</h2>
                                    <LineTable
                                        data={lineForm}
                                        handleRemove={handleLineRemove}
                                        handleEditItem={handleEditItem}
                                    />
                                    <div className={styles.CreateRFQ__buttonWrapper}>
                                        <Button
                                            text="Add Lines"
                                            color="rgb(14, 44, 36)"
                                            onClick={() => setAddLineModalActive(true)}
                                        />
                                    </div>

                                    <div className={styles.CreateRFQ__horizontalRule}></div>

                                    <div className={styles.ButtonContainer}>
                                        <Button
                                            text="Previous"
                                            className={styles.CreateRFQ__fullWidthButton__Cancel}
                                            onClick={() => handlePreviousBtnClick(0)}
                                        />
                                        <Button
                                            text="Save as Draft"
                                            className={styles.CreateRFQ__fullWidthButton__Draft}
                                            onClick={(e) => handleSaveAsDraft(e, 2)}
                                        />
                                        <Button
                                            text="Continue"
                                            className={styles.CreateRFQ__fullWidthButton}
                                            onClick={(e) => handleContinue(2, e)}
                                            loading={createAuctionLineMutation.isLoading}
                                        />
                                    </div>
                                </div>
                            )}

                            {pageNumber === 2 && (
                                <>
                                    <div className={styles.CreateRFQ__bidTypeWrapper}>
                                        <Toggle
                                            label="Enable Partial Bidding"
                                            name="partial_bidding"
                                            onToggle={handleHeaderFormChange}
                                            errorMsg={headerFormErrors.partial_bidding}
                                        />
                                        {headerForm.partial_bidding && (
                                            <Toggle
                                                label="Allow Bidding on Partial Quantities"
                                                name="partial_quantity_bidding"
                                                onToggle={handleHeaderFormChange}
                                                errorMsg={headerFormErrors.partial_quantity_bidding}
                                            />
                                        )}

                                        <Toggle
                                            label="Show Bid Prices Before Deadline"
                                            name="can_compare"
                                            onToggle={handleHeaderFormChange}
                                            errorMsg={headerFormErrors.can_compare}
                                        />
                                        <div className={styles.CreateRFQ__bidTypeInput}>
                                            {[
                                                {
                                                    label: 'Open Bid',
                                                    value: true,
                                                    checked: headerForm.is_open,
                                                },
                                                {
                                                    label: 'Closed Bid',
                                                    value: false,
                                                    checked: !headerForm.is_open,
                                                },
                                            ].map((radio, index) => (
                                                <FormRadio
                                                    key={index}
                                                    value={radio.value}
                                                    label={radio.label}
                                                    onChange={handleHeaderFormChange}
                                                    name="is_open"
                                                    checked={radio.checked}
                                                />
                                            ))}
                                        </div>
                                        {!headerForm.is_open && (
                                            <div className={styles.CreateRFQ__inputWrapper}>
                                                <Button
                                                    className={styles.CreateRFQ__sellerButton}
                                                    text="Select Authorized Sellers"
                                                    color="rgb(14, 44, 36)"
                                                    onClick={() => setShowAuthorizedSellersPopup(true)}
                                                />
                                                <input
                                                    className={styles.CreateRFQ__inputbox}
                                                    type="text"
                                                    value={selectedSellersDisplay}
                                                    placeholder="Authorized Sellers"
                                                    readOnly
                                                />
                                            </div>
                                        )}

                                        {showAuthorizedSellersPopup && (
                                            <AuthorizedSellersPopup
                                                sellers={authorizedSellersQuery.data?.data || []}
                                                selectedSellers={headerForm.authorized_sellers
                                                    .map((sellerId) => {
                                                        const seller = authorizedSellersQuery.data?.data.find(
                                                            (s) => s.id === sellerId
                                                        )
                                                        return seller ? { id: sellerId, name: seller.name } : null
                                                    })
                                                    .filter(Boolean)} // Filter out any nulls in case seller is not found
                                                onClose={() => setShowAuthorizedSellersPopup(false)}
                                                onSelect={handleAuthorizedSellerSelection}
                                            />
                                        )}

                                        {/* <div className={styles.CreateRFQ__rfqModifiableWrapper}> */}
                                        {/*     <FormCheckbox */}
                                        {/*         name="is_modifiable" */}
                                        {/*         handleChange={handleHeaderFormChange} */}
                                        {/*         label="Allow RFQ Modification" */}
                                        {/*     /> */}
                                        {/* </div> */}
                                    </div>

                                    <div className={styles.ButtonContainer}>
                                        <Button
                                            text="Previous"
                                            className={styles.CreateRFQ__fullWidthButton__Cancel}
                                            onClick={() => handlePreviousBtnClick(1)}
                                        />
                                        <Button
                                            type="submit"
                                            text="Publish"
                                            onClick={handleSubmit}
                                            className={styles.CreateRFQ__fullWidthButton}
                                            loading={updateAuctionHeaderMutation.isLoading}
                                        />
                                    </div>
                                </>
                            )}
                        </form>
                    </Card>

                    {/* =========
                        Modals
                    ============= */}

                    {addLineModalActive && (
                        <AddLineModal
                            onClose={() => setAddLineModalActive(false)}
                            handleSubmit={handleLineModalSubmit}
                            errors={headerFormErrors}
                            setPageNumber={setPageNumber}
                            measurements={measurements.data || []}
                            isLoading={createAuctionLineMutation.isLoading}
                        />
                    )}

                    {editLineModalActive && (
                        <EditLineModal
                            onClose={() => setEditLineModalActive(false)}
                            handleSubmit={handleEditLineModalSubmit}
                            errors={headerFormErrors}
                            setPageNumber={setPageNumber}
                            data={editLineData}
                            measurements={measurements.data}
                            isLoading={updateAuctionLineMutation.isLoading}
                            handleAttachmentTableRemove={handleLineAttachmentTableRemove}
                        />
                    )}

                    {addFocalPointModalActive && (
                        <FocalPointModal
                            onClose={() => setAddFocalPointModalActive(false)}
                            data={getFilteredStaffData(getOrgStaffsQuery.data || [])}
                            handleSubmit={handleFocalPointModalSubmit}
                            loader={getOrgStaffsQuery.isLoading}
                        />
                    )}

                    {addHeaderAttachementModalActive && (
                        <HeaderAttachmentModal
                            onClose={() => setAddHeaderAttachementModalActive(false)}
                            handleSubmit={handleHeaderAttachmentModalSubmit}
                            errors={headerFormErrors}
                        />
                    )}
                    {showSuccessModal && <SuccessModal type="success" msg={'RFQ Created'} />}
                </>
            )}
        </OrganizationLayout>
    )
}

export default OrganizationCreateRFQ
