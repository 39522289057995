import React from 'react'
import './SuccessModal.scss'

const SuccessModal = ({ type, msg }) => {
    // pass "success" or "error" as type
    const popupId = `js_${type}-popup`

    return (
        <div className={`popup popup--icon -${type} ${popupId}  popup--visible `}>
            <div className="popup__background"></div>
            <div className="popup__content">
                <h3 className="popup__content__title">{msg}</h3>
            </div>
        </div>
    )
}

export default SuccessModal
