import { Bars } from 'react-loader-spinner'
import styles from './Loader.module.scss'
import Button from '../Button/Button'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

const Loader = ({ refresh = false, height = 'auto' }) => {
    const navigate = useNavigate()

    const X = () => (
        <div className={styles.Loader}>
            <div className={styles.Loader__wrapper}>
                <Bars
                    height="50"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />

                <div className={cx(styles.Loader__buttonWrapper, refresh && styles.Loader__buttonVisible)}>
                    <Button text="Reload" onClick={() => navigate(0)} />
                </div>
            </div>
        </div>
    )

    if (height === 'auto') return <X />
    else
        return (
            <div style={{ height }}>
                <X />
            </div>
        )
}

export default Loader
