import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierProductCatalogueEdit.module.scss'
import cx from 'classnames'
import InputWithError from '../../components/InputWithError/InputWithError'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import { useContext, useState } from 'react'
import Button from '../../components/Button/Button'
import AuthContext from '../../context/AuthContext'
import { toast } from 'react-toastify'
import SupplierAPI from '../../api/SupplierAPI'
import { useMutation } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import { useLocation, useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import { useNavigate } from 'react-router-dom'

const SupplierProductCatalogueEdit = () => {
    const { id } = useParams()
    const { user } = useContext(AuthContext)
    const location = useLocation()
    const productData = location.state.data
    const [form, setForm] = useState({
        ...productData,
    })
    const [formErrors, setFormErrors] = useState({})
    const navigate = useNavigate()

    const submitMutation = useMutation(SupplierAPI.updateProductById, {
        onSuccess: () => {
            toast.success('Updated Product')
            navigate('/supplier/products')
        },
        onError: (err) => {
            console.error(err)
            if (err.response.status === 400) {
                return setFormErrors(err.response.data)
            }
            return toast.error(err.response.data?.message)
        },
    })

    const handleChange = (e) => {
        setForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log({ form })
        submitMutation.mutate({
            token: user.token,
            id: id,
            data: form,
        })
    }

    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files)
        const images = []
        files.forEach((file) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {
                images.push({ image: reader.result })
            }
        })
        setForm((prev) => ({ ...prev, images }))
    }

    const getSpanEstimate = (size) => {
        if (size > 250) {
            return 2
        }

        return 1
    }

    const handleCancel = () => {
        setForm({
            ...productData,
        })
        navigate('/supplier/products')
    }

    return (
        <SupplierLayout active="products" title="Product Catelogue">
            {productData.isError ? (
                <Loader refresh />
            ) : productData.isLoading ? (
                <Loader />
            ) : (
                <form className={styles.AddProduct__container} onSubmit={handleSubmit} encType="multipart/form-data">
                    <h2 className={styles.AddProduct__title}>Edit Product</h2>
                    <Card>
                        <div className={cx('row')}>
                            <div className={cx('col-5')}>
                                <div className={cx(styles['mb-4'])}>
                                    <InputWithError
                                        onChange={handleChange}
                                        name="name"
                                        label="Product Name"
                                        placeholder=""
                                        type="text"
                                        value={form.name}
                                        errorMsg={formErrors.name}
                                    />
                                </div>

                                <div className={cx(styles['mb-4'])}>
                                    <FormTextarea
                                        onChange={handleChange}
                                        name="description"
                                        label="Description"
                                        placeholder=""
                                        type="text"
                                        value={form.description}
                                        errorMsg={formErrors.description}
                                    />
                                </div>
                            </div>
                            <div className={cx(styles['col-7'])}>
                                <div className={cx(styles['mb-4'])}>
                                    <div className={styles.AddProduct__imageWrapper}>
                                        {form.images.map((image) => {
                                            const style = {
                                                gridColumnEnd: `span ${getSpanEstimate(image.width)}`,
                                                gridRowEnd: `span ${getSpanEstimate(image.height)}`,
                                            }
                                            return (
                                                <img
                                                    className={styles.AddProduct__image}
                                                    style={style}
                                                    src={image.image}
                                                />
                                            )
                                        })}
                                    </div>
                                    <InputWithError
                                        onChange={handleImageChange}
                                        name="images"
                                        label="Product Images"
                                        type="file"
                                        multiple
                                    />
                                </div>
                                <div className={cx(styles['row'], styles['mb-4'])}>
                                    <div className={cx(styles['col-6'])}>
                                        <InputWithError
                                            onChange={handleChange}
                                            name="list_price_per_unit"
                                            label="Price Per Item"
                                            placeholder=""
                                            type="text"
                                            value={form.list_price_per_unit}
                                            errorMsg={formErrors.list_price_per_unit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.Btn__Container}>
                            <Button text="Cancel" className={styles.AddProduct__cancelButton} onClick={handleCancel} />
                            <Button text="Update" loading={submitMutation.isLoading} onClick={(e) => handleSubmit(e)} />
                        </div>
                    </Card>
                </form>
            )}
        </SupplierLayout>
    )
}

export default SupplierProductCatalogueEdit
