import styles from './Templates.module.scss'
import React, { useState, useContext } from 'react'
import Button from '../../components/Button/Button'
import AuthContext from '../../context/AuthContext'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import { useQuery } from '@tanstack/react-query'
import SupplierAPI from '../../api/SupplierAPI'
import Loader from '../../components/Loader/Loader'
import TableControls from '../../components/Table/TableControls/TableControls'
import { HiOutlineViewList } from 'react-icons/hi'
import { IoShareSocialSharp } from 'react-icons/io5'
import { toast } from 'react-toastify'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import SeaIcon from '../../assets/icons/sea.svg'
import TableButton from '../../components/Table/TableButton/TableButton'
import { FaTrash } from 'react-icons/fa'

const Templates = () => {
    const [searchText, setSearchText] = useState('')
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [showDeletedMessage, setShowDeletedMessage] = useState(false)

    const [isDeleting, setIsDeleting] = useState(false)

    const getTemplatesQuery = useQuery(['templates'], () => getTemplateList(user.token))

    const getTemplateList = async (token) => {
        try {
            const response = await SupplierAPI.getTemplate(token)
            return response.data
        } catch (error) {
            console.error('Error fetching templates:', error)
            throw error
        }
    }

    const handleDelete = async (id) => {
        setIsDeleting(true)
        try {
            await SupplierAPI.deleteTemplate(user.token, id)
            getTemplatesQuery.refetch()
            setShowDeletedMessage(true)
        } catch (error) {
            console.error('Error deleting template:', error)
        } finally {
            setIsDeleting(false)
        }
    }

    const handleSearchChange = (value) => {
        setSearchText(value)
    }

    const handleShare = () => {
        navigator.clipboard.writeText(`${window.location.origin}/products/${user.organisation}`)
        toast.success('Link copied')
    }

    return (
        <SupplierLayout title="Organization" active="organization">
            <div className={styles.Cards}>
                <div className={styles.Cards__card}>
                    <OrganizationCard
                        icon={SeaIcon}
                        color="#5E81F41A"
                        name="Templates"
                        value={getTemplatesQuery.data?.length || 0}
                    />
                </div>
            </div>

            <Table>
                <TableControls>
                    <div className={styles.Products__top}>
                        <div className={styles.Products__rightContainer}>
                            <Button text="Export CSV" color="#0E2C24" />
                            <Button text={<IoShareSocialSharp />} color="#0047ab" onClick={handleShare} />
                        </div>
                        <div className={styles.Products__rightContainer}>
                            <TableControlSearch onSearchChange={handleSearchChange} />
                            <Button text="Add template" onClick={() => navigate('/supplier/organization/temp/add')} />
                        </div>
                    </div>
                </TableControls>
                <TableContent hoverable>
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTemplatesQuery.isLoading || getTemplatesQuery.isError ? (
                            <tr>
                                <td colSpan="4">Loading...</td>
                            </tr>
                        ) : getTemplatesQuery.data.length === 0 ? (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                    No templates found
                                </td>
                            </tr>
                        ) : (
                            getTemplatesQuery.data.map((template, index) => (
                                <tr key={template.id}>
                                    <td>{index + 1}</td>
                                    <td>{template.name}</td>
                                    <td>{template.description}</td>

                                    <TableButton>
                                        <FaTrash
                                            className={styles.CreateRFQ__editIcon}
                                            onClick={() => handleDelete(template.id)}
                                        />
                                    </TableButton>
                                </tr>
                            ))
                        )}
                    </tbody>
                </TableContent>
            </Table>
        </SupplierLayout>
    )
}

export default Templates
