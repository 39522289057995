import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import styles from './Back.module.scss'

const Back = ({ to }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const pathSegments = location.pathname.split('/').filter(Boolean) // Filter Boolean removes empty strings

    const handleBackClick = () => {
        to ? navigate(to) : navigate(-1)
    }

    if (
        pathSegments.length <= 2 ||
        pathSegments[2] === 'create_new' ||
        (pathSegments[1] === 'rfq' && pathSegments[2] === 'edit')
    ) {
        return null
    }

    return (
        <div className={styles.Back} onClick={handleBackClick}>
            <FaAngleLeft size={20} />
        </div>
    )
}

export default Back
