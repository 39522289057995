import Header from '../Header/Header'
import styles from './SupplierLayout.module.scss'
import Supplier from '../Sidebar/Sidebar'

const SupplierLayout = ({ children, active, title }) => {
    return (
        <div className={styles.Layout}>
            <div className={styles.Layout__container}>
                <Supplier active={active} type="supplier" />
                <div className={styles.Layout__contentContainer}>
                    <Header title={title || ''} />
                    <div className={styles.Layout__content}>
                        <div>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplierLayout
