import Card from '../Card/Card'
import styles from './Table.module.scss'
import TableContent from './TableContent/TableContent'
import TableControls from './TableControls/TableControls'

const Table = ({ children, outline = true }) => {
    return outline ? <Card className={styles.Table}>{children}</Card> : <>{children}</>
}

export default Table
