import axios from 'axios'

class SupplierAPI {
    static baseURL = process.env.REACT_APP_API_BASE_URL

    static async getSupplierDashboard({ token }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/supplier-dashboard/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
    }

    static async becomeSupplier({ token, id, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}/become-seller`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getSupplierCategories({ token }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/organisation-selling-category`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getSupplierCategoriesPublic({ id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}/selling-categories/`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    static async getFilteredProduct({ id, product_category_id }) {
        return await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/product/product-catalogue-filter/${id}/${product_category_id}/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }
    static async updateSupplier({ token, id, data }) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}/become-seller`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
    static async deleteSupplier({ token, id, data }) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}/become-seller`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
            data,
        })
    }

    static async addProduct({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/product-list/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getProductById({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/product/product-detail/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getSupplierPurchaseOrder({ token }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/purchase-order-status/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    // static async postSupplierPO({ token }) {
    //     return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order-status/`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: 'Token ' + token,
    //         },
    //     })
    // }

    static async postSupplierPO({ token, po_id, status }) {
        return await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/purchase-order-status/`,
            {
                po_id,
                status,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }

    static async getSupplierPO({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/purchase-orders/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async deleteProductById({ token, id }) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/product/product-detail/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async updateProductById({ token, id, data }) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/product/product-detail/${id}/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getAuctions(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getProductList(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/product/product-list/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async addTemplate(data, token) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/templates/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async editTemplate(token, id, data) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/templates/${id}/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getTemplate(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/list-templates/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async deleteTemplate(token, id) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/templates/${id}/delete/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getClientDetails(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/clients/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async inviteClient(data, token) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        }
        const jsonData = JSON.stringify(data)
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/invite-client`, jsonData, config)
    }

    static async createBidHeader({ token, data, id }) {
        console.log({ data })
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/bids`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async createBidHeaders({ token, data, id }) {
        console.log({ data })
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/bids`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getMyBids(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all-bids/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidById({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bids/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getSupplierDetails({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getUserDetails({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user-details/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getDocumentList({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/supplier-documents/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async uploadDocument({ token, data, id }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/supplier-documents/${id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async deleteDocument({ token, id, docId }) {
        return await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/organisations/supplier-documents/${id}/${docId}/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }
    static async getActivityLog({ token, viewAll }) {
        return await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/activity-logs/suppliers/${viewAll ? '?all=true' : ''}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }

    static async addProductEnquiry({ data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/enquiries/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }
}

export default SupplierAPI
