import styles from './SupplierClientMgmt.module.scss'
import { useNavigate } from 'react-router'
import { useState, useContext, useRef } from 'react'
import AuthContext from '../../context/AuthContext'
import SeaIcon from '../../assets/icons/sea.svg'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import cx from 'classnames'
import Modal from '../../components/Modal/Modal'
import InviteSentPopup from '../../components/Modal/InviteSentPopup'
import { toast } from 'react-toastify'
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox'
import Button from '../../components/Button/Button'
import DropdownCard from '../../components/DropdownCard/DropdownCard'
import InputWithError from '../../components/InputWithError/InputWithError'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import { useQuery } from '@tanstack/react-query'
import SupplierAPI from '../../api/SupplierAPI'
import Badge from '../../components/Badge/Badge'
import Loader from '../../components/Loader/Loader'

const SupplierClientMgmt = () => {
    const [dropdownActive, setDropdownActive] = useState(false)
    const dropdownToggleRef = useRef(null)

    const [showModal, setShowModal] = useState(false)
    const [spinnerActive, setSpinnerActive] = useState(false)
    const [inviteForm, setInviteForm] = useState({})
    const [orgName, setOrgName] = useState('')
    const [showInviteSentModal, setShowInviteSentModal] = useState(false)
    const [form, setForm] = useState({ additional_attributes: [] })
    const [agree, setAgree] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [reload, setReload] = useState(false)

    const navigate = useNavigate()

    const { user } = useContext(AuthContext)

    const getClientDetails = useQuery(['clients', user.token], () => SupplierAPI.getClientDetails(user.token))

    const toggleDropdown = () => {
        setDropdownActive((prev) => !prev)
    }

    const handleOpenModal = () => {
        setShowModal(true)
        setInviteForm({ email: '' })
        setOrgName('')
    }

    const handleCloseModal = () => {
        setShowModal(false)
        setInviteForm({ email: '' })
        setOrgName('')
    }

    const handlePopUpClose = () => {
        setShowPopup(false)
        setReload(true)
    }

    const handleInviteClient = async (e) => {
        e.preventDefault()
        setSpinnerActive(true)

        const data = {
            email: inviteForm.email,
            name: orgName,
        }

        const res = await SupplierAPI.inviteClient(data, user.token)
        console.log('API response:', res)

        if (res.status >= 200 && res.status < 300) {
            setShowModal(false)
            setShowPopup(true)
            setSpinnerActive(false)
        } else {
            toast('Error Processing Request !!!!', {
                type: 'error',
            })
            setSpinnerActive(false)
        }
        setShowModal(false)
        setShowPopup(true)
        setSpinnerActive(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        let agree_with_terms = form.agree_with_terms
        let agree = form.agree

        if (name === 'agree_with_terms') {
            agree_with_terms = !agree_with_terms
        }

        if (name === 'agree') {
            agree = !agree
        }

        const isEnabled = inviteForm.email && orgName && agree_with_terms && agree

        setForm((prev) => {
            return {
                ...prev,
                [name]: value,
                agree_with_terms,
                agree,
                isEnabled,
            }
        })
    }

    const handleInviteSupplierChange = (e) => {
        const { name, value } = e.target
        setInviteForm((prev) => ({ ...prev, [name]: value }))
        setOrgName(value)
    }

    const handleRowClick = (clientData) => {
        navigate('/supplier/clients/detail/:id', { state: { clientData } })
    }

    const checkboxHandler = () => {
        setAgree(!agree)
    }
    function handleOrgNameChange(event) {
        setOrgName(event.target.value)
    }

    const handleEmailChange = (event) => {
        setInviteForm({ ...inviteForm, email: event.target.value })
    }

    return (
        <div>
            {reload ? window.location.reload() : null}
            <SupplierLayout title="Client Management" active="clients">
                {showModal && (
                    <Modal title="Invite Client" onClose={handleCloseModal}>
                        <form onSubmit={handleInviteClient} className={styles.Modal}>
                            <InputWithError
                                type="text"
                                label="Client Name"
                                name="name"
                                placeholder="Enter Client Name"
                                value={orgName}
                                onChange={handleOrgNameChange}
                            />

                            <InputWithError
                                type="email"
                                label="Email"
                                name="email"
                                placeholder="Enter Client email"
                                value={inviteForm.email}
                                onChange={handleEmailChange}
                            />
                            <br />
                            <div className={cx(styles.Modal__bottom)}>
                                <div>
                                    <div className={styles.Modal__formCheckbox}>
                                        <FormCheckbox
                                            onChange={handleInputChange}
                                            name="agree_with_terms"
                                            label="I have read and agree with the Terms and Use"
                                            id="RegisterSupplier_agree_with_terms"
                                        />
                                    </div>
                                    <br />
                                    <div className={styles.Modal__formCheckbox}>
                                        <FormCheckbox
                                            onChange={handleInputChange}
                                            name="agree"
                                            label="I hereby agree that Metics will make parts of my (company) information 
                                            accessible to other users and the public based on my role within the  platform 
                                            and the applicable profile visibility settings.
                                            Please see the Privacy Statement to learn how we process personal data."
                                            id="RegisterSupplier_agree"
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/* <Button
                                        type="submit"
                                        className={styles.button}
                                        text="Submit"
                                        color="#979797"
                                        loading={spinnerActive}
                                    /> */}
                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        text="Submit"
                                        color="#0038FF"
                                        loading={spinnerActive}
                                        disabled={!form.isEnabled}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal>
                )}
                {showInviteSentModal && <InviteSentPopup orgName={orgName} onClose={() => setShowPopup(false)} />}
                {showPopup && <InviteSentPopup orgName={orgName} onClose={handlePopUpClose} />}
                <div className={styles.Supplier__container}>
                    <div className={styles.Cards}>
                        <div className={styles.Cards__card}>
                            <OrganizationCard
                                icon={SeaIcon}
                                color="#5E81F41A"
                                name="All"
                                value={getClientDetails?.data?.data?.length}
                            />
                        </div>
                        <div className={styles.Cards__card}>
                            <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Approved" value="" />
                        </div>
                        <div className={styles.Cards__card}>
                            <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Public" value="" />
                        </div>
                        {/* <div className={styles.Cards__card}>
                            <OrganizationCard
                                icon={SeaIcon}
                                color="#FFFFFF"
                                style={{
                                    backgroundColor: '#7800EF',
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                                value="Add a new Client"
                                onClick={() => navigate('/supplier/clients')}
                            />
                        </div> */}
                    </div>
                    <div className="table-container">
                        <Table>
                            <TableControls>
                                <div className={styles.Supplier__tableControls}>
                                    <div>
                                        <Button text="Export CSV" color="#0E2C24" />
                                    </div>
                                    <div className={styles.Supplier__row}>
                                        <TableControlSearch />
                                        <div className={styles.Supplier__buttonDropWrapper}>
                                            <Button
                                                reference={dropdownToggleRef}
                                                text="Add a New Client"
                                                onClick={() => setDropdownActive(!dropdownActive)}
                                            />

                                            <DropdownCard
                                                active={dropdownActive}
                                                close={setDropdownActive}
                                                parentRef={dropdownToggleRef}
                                            >
                                                <li onClick={handleOpenModal}>Invite Client to Register</li>
                                                <li>Add New Client</li>
                                            </DropdownCard>
                                        </div>
                                    </div>
                                </div>
                            </TableControls>
                            <TableContent hoverable={!(getClientDetails.isLoading || getClientDetails.isError)}>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Name</th>
                                        <th>Contact</th>
                                        <th>Currency</th>
                                        <th>Location</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getClientDetails.isLoading || getClientDetails.isError ? (
                                        <tr>
                                            <td colSpan="7">
                                                <Loader refresh={getClientDetails.isError} height="30vh" />
                                            </td>
                                        </tr>
                                    ) : getClientDetails?.data?.data?.length === 0 ? (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: 'center' }}>
                                                No data found
                                            </td>
                                        </tr>
                                    ) : (
                                        getClientDetails?.data?.data?.map((row, id) => {
                                            return (
                                                <tr key={id} onClick={() => handleRowClick(row)}>
                                                    <td>{id + 1}</td>
                                                    <td>{row.name}</td>
                                                    <td>{row.contact}</td>
                                                    <td>{row.default_currency}</td>
                                                    <td>{row.city}</td>
                                                    <td>{row.email}</td>
                                                    <td>
                                                        {row.is_active ? (
                                                            <Badge
                                                                color="#1BBB6B"
                                                                backgroundColor="#EDF8F3"
                                                                value="Active"
                                                            />
                                                        ) : (
                                                            <Badge
                                                                color="#FC555B"
                                                                backgroundColor="#FED7D8"
                                                                value="Not Active"
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )}
                                </tbody>
                            </TableContent>
                        </Table>
                    </div>
                </div>
            </SupplierLayout>
        </div>
    )
}

export default SupplierClientMgmt
