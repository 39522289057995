// stylesheet
import styles from './SupplierProductBids.module.scss'

// context
import AuthContext from '../../context/AuthContext'

// api
import SupplierAPI from '../../api/SupplierAPI'

// packages
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import SeaIcon from '../../assets/icons/sea.svg'
// components
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import Loader from '../../components/Loader/Loader'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import Button from '../../components/Button/Button'
import moment from 'moment/moment'

const SupplierProductBids = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const getMyBidsQuery = useQuery(['bids'], () => SupplierAPI.getMyBids(user.token), {
        select: (data) => {
            return data?.data
                .map((bid) => {
                    return {
                        id: bid.id,
                        auction: bid.auction_header?.title,
                        organization: bid.auction_header?.organization_name,
                        category: bid.auction_header?.product_category,
                        bidDate: moment(bid.bid_expiration_date).format('DD/MM/YYYY'),
                        bidDateRaw: bid.bid_expiration_date,
                    }
                })
                .sort((a, b) => new Date(b.bidDateRaw) - new Date(a.bidDateRaw))
        },
    })

    return (
        <SupplierLayout title="Bids" active="bids">
            <div className={styles.Cards}>
                <div className={styles.Cards__card}>
                    <OrganizationCard
                        icon={SeaIcon}
                        color="#5E81F41A"
                        name="All"
                        value={getMyBidsQuery?.data?.length}
                    />
                </div>
            </div>
            <br />

            <Table>
                <TableControls>
                    <div className={styles.Supplier__tableControls}>
                        <div>
                            <Button text="Export CSV" color="#0E2C24" />
                        </div>
                        <div className={styles.Supplier__row}>
                            <TableControlSearch />
                        </div>
                    </div>
                </TableControls>
                <TableContent hoverable={!(getMyBidsQuery.isError || getMyBidsQuery.isLoading)}>
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Auction</th>
                            <th>Buyer</th>
                            <th>Category</th>
                            <th>Bid Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getMyBidsQuery.isError || getMyBidsQuery.isLoading ? (
                            <tr>
                                <td colSpan="5">
                                    <Loader refresh={getMyBidsQuery.isError} height="30vh" />
                                </td>
                            </tr>
                        ) : getMyBidsQuery?.data?.length === 0 ? (
                            <tr>
                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                    No Bids
                                </td>
                            </tr>
                        ) : (
                            getMyBidsQuery?.data?.map((row, id) => {
                                return (
                                    <tr key={id} onClick={() => navigate(`/supplier/bids/${row.id}`)}>
                                        <td>{id + 1}</td>
                                        {['auction', 'organization', 'category', 'bidDate']
                                            .filter((field) => row.hasOwnProperty(field))
                                            .map((field, index) => (
                                                <td key={index}>{row[field]}</td>
                                            ))}
                                    </tr>
                                )
                            })
                        )}
                    </tbody>
                </TableContent>
            </Table>
        </SupplierLayout>
    )
}

export default SupplierProductBids
