import styles from './Products.module.scss'
import React, { useState, useMemo, useContext, useEffect } from 'react'
import Button from '../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import { useQuery, useMutation } from '@tanstack/react-query'
import ProductAPI from '../../api/ProductAPI'
import SupplierAPI from '../../api/SupplierAPI' // Import SupplierAPI
import Loader from '../../components/Loader/Loader'
import TableControls from '../../components/Table/TableControls/TableControls'
import { HiViewGrid, HiOutlineViewList } from 'react-icons/hi'
import Card from '../../components/Card/Card'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import MeticsLogo from '../../assets/images/Metics-blue 1.png'
import Modal from '../../components/Modal/Modal'
import AuthContext from '../../context/AuthContext'

const Products = () => {
    const [searchText, setSearchText] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [enquiries, setEnquiries] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [isFiltered, setIsFiltered] = useState(false)
    const [visibleCategoriesCount, setVisibleCategoriesCount] = useState(5)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [showAllCategories, setShowAllCategories] = useState(false)

    const { user } = useContext(AuthContext)
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        console.log('Organisation ID:', id)
    }, [id])

    const filteredTableData = (data, text) => {
        let filteredData = data
            ? data.filter((item) => {
                  var values = Object.values(item)
                  var pattern = new RegExp(text, 'i')
                  return values.some((value) => pattern.test(value))
              })
            : []

        return filteredData.map((item, index) => ({
            ...item,
            slNo: index + 1,
        }))
    }

    const productListQuery = useQuery(['product', 'product-list'], () => ProductAPI.getProducts({ id }), {
        select: (data) => {
            const products = data?.data.products.map((product, index) => ({
                id: product.id,
                slNo: index + 1,
                name: product.name,
                price: product.list_price_per_unit,
                email: product.email,
                product_category: product.product_sub_category,
                status: '',
                enquiry: '',
            }))
            return { supplier: data?.data?.organisation_name, products: products }
        },
    })

    const supplierCategoriesQuery = useQuery(
        ['supplier-categories', id],
        () => SupplierAPI.getSupplierCategoriesPublic({ id }),
        {
            enabled: !!id,
        }
    )

    const { mutate: fetchFilteredProducts } = useMutation(
        ({ id, product_category_id }) => SupplierAPI.getFilteredProduct({ id, product_category_id }),
        {
            onSuccess: (data) => {
                const filteredProducts = data?.data.products.map((product, index) => ({
                    id: product.id,
                    slNo: index + 1,
                    name: product.name,
                    price: product.list_price_per_unit,
                    email: product.email,
                    product_category: product.product_sub_category,
                    status: '',
                    enquiry: '',
                }))
                setFilteredProducts(filteredProducts)
                setIsFiltered(true)
                console.log('Filtered Products:', filteredProducts)
            },
        }
    )

    useEffect(() => {
        if (supplierCategoriesQuery.data) {
            console.log('Supplier Categories Data:', filteredProducts)
        } else {
            console.log('Supplier Categories Data not loaded yet or is empty.')
        }
    }, [supplierCategoriesQuery.data])

    const categories = supplierCategoriesQuery?.data?.data?.selling_categories || []
    const filteredData = useMemo(
        () => filteredTableData(productListQuery?.data?.products, searchText),
        [productListQuery.data, searchText]
    )

    const handleSearchChange = (value) => {
        setSearchText(value)
    }

    const [viewMode, setViewMode] = useState('list')

    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === 'list' ? 'gallery' : 'list'))
    }

    const handleViewProduct = (id) => navigate(`/products/view/${id}`, { state: productListQuery?.data?.supplier })

    const handleEnquireClick = (product) => {
        setSelectedProduct(product)
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
        setQuantity(1)
    }

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value)
    }

    const handleSubmitEnquiry = () => {
        console.log('Enquiry submitted for product:', selectedProduct, 'Quantity:', quantity)

        setEnquiries((prevEnquiries) => [
            ...prevEnquiries,
            {
                slNo: prevEnquiries.length + 1,
                id: selectedProduct.id,
                name: selectedProduct.name,
                quantity: quantity,
                email: selectedProduct.email,
            },
        ])

        handleCloseModal()
    }

    // const handleCategoryClick = (categoryId) => {
    //     fetchFilteredProducts({ id, product_category_id: categoryId })
    // }
    const handleCategoryClick = (categoryId) => {
        if (selectedCategory === categoryId) {
            setSelectedCategory(null)
            setIsFiltered(false)
        } else {
            setSelectedCategory(categoryId)
            fetchFilteredProducts({ id, product_category_id: categoryId })
        }
    }

    //

    const handleToggleCategories = () => {
        setShowAllCategories((prev) => !prev)
        setVisibleCategoriesCount(showAllCategories ? 5 : categories.length)
    }

    const ListTableBody = React.memo(({ data }) => (
        <>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Product Category</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((row) => (
                    <tr key={row.id} onClick={() => handleViewProduct(row.id)}>
                        <td>{row.slNo}</td>
                        {['name', 'price', 'product_category'].map((field) => (
                            <td key={field}>{row[field]}</td>
                        ))}
                        <td>
                            <Button
                                className={styles.Enquire}
                                text="Enquire +"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleEnquireClick(row)
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    ))

    const GalleryView = React.memo(({ data }) => (
        <div className={styles.galleryContainer}>
            {data.map((row) => (
                <Card
                    className={styles.galleryItem}
                    key={row.id}
                    onClick={() => {
                        console.log('Clicked on gallery item:', row.id)
                        handleViewProduct(row.id)
                    }}
                >
                    <div className={styles.galleryItemHeader}>
                        <div className={styles.galleryItemTitle}>{row.name}</div>
                        <div className={styles.galleryItemPrice}>${row.price}</div>
                    </div>
                    <div className={styles.galleryItemDetails}>{row.product_category}</div>
                    <Button
                        className={styles.Enquire}
                        text="Enquire +"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleEnquireClick(row)
                        }}
                    />
                </Card>
            ))}
        </div>
    ))

    return (
        <div className={styles.Container}>
            <div className={styles.LogoContainer}>
                <img className={styles.Logo} src={MeticsLogo} alt="logo" />
            </div>
            <div className={styles.Header}>
                <h1 className={styles.Header__title}>Product Catalogue</h1>
                <h1 className={styles.Header__title}>{productListQuery?.data?.supplier}</h1>
            </div>

            {supplierCategoriesQuery.isLoading ? (
                <Loader />
            ) : (
                <div className={styles.Categories}>
                    {categories.slice(0, visibleCategoriesCount).map((category) => (
                        <Card
                            key={category.id}
                            className={`${styles.CategoryCard} ${
                                selectedCategory === category.id ? styles.Selected : ''
                            }`}
                            onClick={() => handleCategoryClick(category.id)}
                        >
                            <div className={styles.CategoryName}>{category.name}</div>
                        </Card>
                    ))}

                    <Button
                        className={styles.see_more}
                        text={showAllCategories ? 'Show Less' : 'See More'}
                        onClick={handleToggleCategories}
                    />
                </div>
            )}
            <div className={styles.Content}>
                {productListQuery.isError ? (
                    <Loader refresh />
                ) : productListQuery.isLoading ? (
                    <Loader />
                ) : (
                    <Table>
                        <TableControls>
                            <div className={styles.Products__top}>
                                <div className={styles.Products__rightContainer}>
                                    <Button text="Export CSV" color="#0E2C24" />
                                    <span className={styles.Products__view} onClick={toggleViewMode}>
                                        {viewMode === 'gallery' ? (
                                            <HiOutlineViewList size={20} />
                                        ) : (
                                            <HiViewGrid size={20} />
                                        )}
                                    </span>
                                </div>
                                <div className={styles.Products__rightContainer}>
                                    <TableControlSearch onSearchChange={handleSearchChange} />
                                    <Button
                                        text="Enquiry"
                                        onClick={() => navigate('/products/enquiry', { state: { enquiries } })}
                                    />
                                </div>
                            </div>
                        </TableControls>
                        <TableContent hoverable>
                            {viewMode === 'list' ? (
                                <ListTableBody data={isFiltered ? filteredProducts : filteredData} />
                            ) : (
                                <GalleryView data={isFiltered ? filteredProducts : filteredData} />
                            )}
                        </TableContent>
                    </Table>
                )}
            </div>

            {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                    <h2>Enquire for {selectedProduct?.name}</h2>
                    <label className={styles.popup_label}>
                        Quantity:
                        <input
                            className={styles.popup_input}
                            type="number"
                            value={quantity}
                            min="1"
                            onChange={handleQuantityChange}
                        />
                    </label>
                    <div className={styles.btns}>
                        <Button className={styles.save} text="Submit" onClick={handleSubmitEnquiry} />
                        <Button className={styles.cancel} text="Cancel" onClick={handleCloseModal} />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Products
