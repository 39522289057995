import Card from '../../../components/Card/Card'
import styles from './SupplierCard.module.scss'

const SupplierCard = ({ icon, color, style, name, value, ...rest }) => {
    return (
        <Card className={styles.SupplierCard} style={style} {...rest}>
            <div className={styles.SupplierCard__left}>
                <p>{name}</p>
                <p>{value}</p>
            </div>
            <div className={styles.SupplierCard__right} style={{ backgroundColor: color }}>
                <img src={icon} alt="card-img" />
            </div>
        </Card>
    )
}

export default SupplierCard
