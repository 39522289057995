import styles from './VerificationConfirm.module.scss'
import EmailImage from '../../assets/images/confirm.png'
import { useNavigate } from 'react-router-dom'
import HeaderLogo from '../../components/Header/HeaderLogo'

const Verification = () => {
    const navigate = useNavigate()
    return (
        <div className={styles.Verification}>
            {/* <Header /> */}
            <div className={styles.Register__headerLogo}>
                <HeaderLogo onClick={() => navigate('/login')} />
            </div>
            <div className={styles.Verification__container}>
                <div className={styles.Verification__content}>
                    <h1 className={styles.Verification__title}>Verification Successful</h1>
                    <p className={styles.Verification__text}>We are really excited to have you as our partner</p>
                    <button
                        className={styles.Verification__button}
                        onClick={() => {
                            navigate('/login')
                        }}
                    >
                        Go To Login
                    </button>
                </div>
                <div className={styles.Verification__imageContainer}>
                    <img className={styles.Verification__image} src={EmailImage} alt="email-img" />
                </div>
            </div>
        </div>
    )
}

export default Verification
