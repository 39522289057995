import styles from './FormTextarea.module.scss'
import TextareaAutosize from 'react-textarea-autosize'

const FormTextarea = ({ onChange, name, type, placeholder, disabled, errorMsg = '', label, minRows = 5, value }) => {
    return (
        <div className={styles.FormTextarea}>
            {label && <label className={styles.FormTextarea__label}>{label}</label>}
            <TextareaAutosize
                className={styles.FormTextarea__input}
                onChange={onChange}
                name={name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                minRows={minRows}
                value={value}
            />
            <span className={styles.FormTextarea__msg}>{errorMsg}</span>
        </div>
    )
}

export default FormTextarea
