import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierRFQ.module.scss'

import SeaIcon from '../../assets/icons/sea.svg'
import SupplierCard from './SupplierCard/SupplierCard'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import SupplierAPI from '../../api/SupplierAPI'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'

const SupplierRFQ = () => {
    const [tableData, setTableData] = useState([])

    const navigate = useNavigate()

    const { user } = useContext(AuthContext)

    useEffect(() => {
        const asyncFunc = async () => {
            const res = await SupplierAPI.getAuctions({
                token: user.token,
            })
            const data = await res.json()
            console.log(data)
            const auctions = data.open_auctions.map((auction) => {
                return {
                    Number: 'PO-0012',
                    Reference: auction.requisition_number,
                    From: 'Development  DEPT',
                    Date: new Date(auction.created_at).toDateString(),
                    'Due Date': '03 Nov 2021',
                    Replys: '9',
                    'Last Updation': new Date(auction.updated_at).toDateString(),
                    Status: 'Awaiting Payment',
                }
            })
            setTableData(auctions)
        }
        asyncFunc()
    }, [])

    return (
        <SupplierLayout active="rfq" title="RFQ">
            <div className={styles.Rfq__container}>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <SupplierCard icon={SeaIcon} color="#5E81F41A" name="All" value={tableData.length} />
                    </div>
                    <div className={styles.Cards__card}>
                        <SupplierCard icon={SeaIcon} color="#5E81F41A" name="Accepted" value="0" />
                    </div>
                    <div className={styles.Cards__card}>
                        <SupplierCard icon={SeaIcon} color="#5E81F41A" name="Draft" value="0" />
                    </div>
                    <div className={styles.Cards__card}>
                        <SupplierCard
                            icon={SeaIcon}
                            color="#FFFFFF"
                            style={{
                                backgroundColor: 'white',
                                color: '#1BBB6B',
                                cursor: 'pointer',
                                border: '3px solid #1BBB6B',
                            }}
                            value="Create New RFQ"
                            onClick={() => navigate('/organization/rfq/create_new')}
                        />
                    </div>
                </div>
                <Table>
                    <TableContent>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Reference</th>
                                <th>From</th>
                                <th>Date</th>
                                <th>Due Date</th>
                                <th>Replys</th>
                                <th>Last Updation</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, id) => {
                                return (
                                    <tr key={id}>
                                        {Object.values(row).map((field) => (
                                            <td>{field}</td>
                                        ))}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </TableContent>
                </Table>
            </div>
        </SupplierLayout>
    )
}

export default SupplierRFQ
