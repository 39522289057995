import Table from '../../../components/Table/Table'
import TableButton from '../../../components/Table/TableButton/TableButton'
import TableContent from '../../../components/Table/TableContent/TableContent'
import styles from './FocalPointTable.module.scss'
import { FaEdit, FaTrash } from 'react-icons/fa'

const FocalPointTable = ({ data, handleRemove }) => {
    return (
        <Table outline={false}>
            <TableContent>
                <thead>
                    <tr>
                        <th>SL No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((staff, index) => {
                        console.log(staff)
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{`${staff.first_name} ${staff.middle_name} ${staff.last_name}`}</td>
                                <td>{staff.email}</td>
                                <td>{staff.role}</td>
                                <TableButton>
                                    <FaTrash
                                        className={styles.CreateRFQ__editIcon}
                                        onClick={() => handleRemove(staff.id)}
                                    />
                                </TableButton>
                            </tr>
                        )
                    })}
                </tbody>
            </TableContent>
        </Table>
    )
}

export default FocalPointTable
