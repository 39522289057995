import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './Register.module.scss'
import { toast } from 'react-toastify'
import AuthContext from '../../context/AuthContext'
import InputWithError from '../../components/InputWithError/InputWithError'
import HeaderLogo from '../../components/Header/HeaderLogo'
import FormSelect from '../../components/FormSelect/FormSelect'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import Card from '../../components/Card/Card'
import { useMutation, useQuery } from '@tanstack/react-query'
import AuthAPI from '../../api/AuthAPI'
import axios from 'axios'
import Loader from '../../components/Loader/Loader'
import cx from 'classnames'
import CollabIllustration from '../../assets/illustrations/collaboration.svg'
import ReCAPTCHA from 'react-google-recaptcha'

const validatePassword = (password) => {
    const errors = []

    if (typeof password === 'undefined') {
        errors.push('Password is required')
    } else {
        if (!/[a-z]/.test(password)) {
            errors.push('Password must contain at least one lowercase letter')
        }
        if (!/[A-Z]/.test(password)) {
            errors.push('Password must contain at least one uppercase letter')
        }
        if (!/\d/.test(password)) {
            errors.push('Password must contain at least one number')
        }
        if (!/[@$!%*#?&]/.test(password)) {
            errors.push('Password must contain at least one special character (@, $, !, %, *, #, ?, or &)')
        }
        if (password.length < 8) {
            errors.push('Password must be at least 8 characters long')
        }
    }

    return errors
}

const Register = () => {
    const [form, setForm] = useState({ middle_name: '' })
    const [formErrors, setFormErrors] = useState({})
    const [showVerifyModal, setShowVerifyModal] = useState(false)

    const navigate = useNavigate()

    const { user, login, logout } = useContext(AuthContext)

    const countryChoicesQuery = useQuery(['country-choices'], AuthAPI.getCountryChoices, {
        select: (data) => {
            data = data?.data
            const list = {}
            const countryList = {}
            const dialCode = {}
            Object.keys(data).forEach((code) => {
                dialCode[`${data[code].name} ${data[code].dial_code}`] = data[code].dial_code
            })
            Object.keys(data).forEach((code) => {
                countryList[data[code].name] = code
            })
            list['countryList'] = countryList
            list['dialCode'] = dialCode
            list['data'] = data
            return list
        },
    })

    const registerMutation = useMutation({
        mutationKey: ['register'],
        mutationFn: () => AuthAPI.userRegister(form),
        onSuccess: (data) => {
            setShowVerifyModal(true)
            console.log(data)
            login(data)
        },
        onError: (error) => {
            if (error.response?.status === 400) {
                setFormErrors(error.response.data)
            } else {
                toast('Some error occurred!', {
                    type: 'error',
                })
            }
        },
    })

    const checkPasswordMatch = () => {
        if (form.password !== form.password2) {
            setFormErrors((prev) => ({ ...prev, password2: ["Passwords don't match"] }))
        } else {
            setFormErrors((prev) => {
                const { password2, ...rest } = prev
                return rest
            })
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        console.log(name, value)

        if (name === 'phone') {
            const numericValue = value.replace(/[^0-9]/g, '')

            setForm((prev) => ({
                ...prev,
                [name]: numericValue,
            }))
        } else {
            setForm((prev) => ({
                ...prev,
                [name]: value,
            }))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const passwordErrors = validatePassword(form.password)
        if (passwordErrors.length > 0) {
            setFormErrors({
                password: passwordErrors,
            })
            passwordErrors.forEach((error) => {
                toast.error(error)
            })
            return
        }

        if (form.password !== form.password2) {
            return setFormErrors({
                password: ['Passwords should be the same'],
                password2: ['Passwords should be the same'],
            })
        }

        registerMutation.mutate()
    }
    const handlePopupProceed = () => {
        setShowVerifyModal(false)
        navigate('/login')
    }

    const handleEmailValidation = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/check-email?email=${form.email}`)
            setFormErrors({ email: null })
        } catch (err) {
            if (err.response.status >= 400 && err.response.status < 500) {
                setFormErrors({ email: [err.response.data?.message] })
            }
        }
    }

    const handleRecaptchaChange = (value) => {
        console.log('ReCAPTCHA value:', value)
        // You can store the reCAPTCHA value in state or perform further validation
    }

    if (countryChoicesQuery.isLoading) {
        return <Loader height="100vh" />
    }

    if (countryChoicesQuery.isError) {
        toast('Failed to fetch country list', {
            type: 'error',
        })
        return <Loader height="100vh" refresh />
    }

    return (
        <div className={styles.Register}>
            {/* <Header /> */}
            {/* <HeaderLogo onClick={() => navigate('/login')} /> */}
            <div className={styles.Register__headerLogo}>
                <HeaderLogo onClick={() => navigate('/login')} />
            </div>
            <div className={styles.Register__scrollableContent}>
                <div className={styles.Register__sections}>
                    <section className={styles.Register__sections__left}>
                        <div className={styles.Register__contentContainer}>
                            <h1 className={styles.Register__title}>
                                <span className={styles.Register__title__main}>Sign up for an account</span>
                                <span className={styles.Register__title__sub}>Create new possiblities with us</span>
                            </h1>
                            <form className={styles.Register__form} onSubmit={handleSubmit}>
                                <div className={styles['row']}>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="username"
                                            label="Username"
                                            type="text"
                                            placeholder="Username"
                                            errorMsg={formErrors['username'] && formErrors['username'][0]}
                                            value={form.username}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            // onBlur={handleEmailBlur}
                                            onBlur={handleEmailValidation}
                                            name="email"
                                            type="email"
                                            label="Email"
                                            placeholder="Email"
                                            errorMsg={formErrors['email'] && formErrors['email'][0]}
                                            value={form.email}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="first_name"
                                            type="text"
                                            label="First Name"
                                            placeholder="First Name"
                                            errorMsg={formErrors['first_name'] && formErrors['first_name'][0]}
                                            value={form.first_name}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="last_name"
                                            type="text"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            errorMsg={formErrors['last_name'] && formErrors['last_name'][0]}
                                            value={form.last_name}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="password"
                                            label="Password"
                                            type="password"
                                            placeholder="Password"
                                            errorMsg={formErrors['password'] && formErrors['password'].join('\n')}
                                            value={form.password}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            label="Confirm Password"
                                            name="password2"
                                            type="password"
                                            placeholder="Confirm Password"
                                            errorMsg={formErrors['password2'] && formErrors['password2'][0]}
                                            value={form.password2}
                                            onBlur={checkPasswordMatch}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <FormSelect
                                            name="country"
                                            label="Country"
                                            onChange={handleInputChange}
                                            placeholder="Country"
                                            value={form.country}
                                            options={countryChoicesQuery?.data.countryList}
                                            errorMsg={formErrors['country'] && formErrors['country'][0]}
                                        />
                                    </div>

                                    <div className={styles['col-md-6']}>
                                        <div className={styles['row']}>
                                            <div className={styles['col-md-4']}>
                                                <FormSelect
                                                    name="dial_code"
                                                    label="Dial Code"
                                                    onChange={handleInputChange}
                                                    placeholder="Dial Code"
                                                    value={form.dial_code}
                                                    options={countryChoicesQuery?.data.dialCode}
                                                    errorMsg={formErrors['country'] && formErrors['country'][0]}
                                                />
                                            </div>
                                            <div className={styles['col-md-8']}>
                                                <InputWithError
                                                    onChange={handleInputChange}
                                                    name="phone"
                                                    type="tel"
                                                    placeholder="Phone"
                                                    label="Phone"
                                                    errorMsg={formErrors['phone'] && formErrors['phone'][0]}
                                                    value={form.phone}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="designation"
                                            label="Designation"
                                            type="text"
                                            placeholder="Designation"
                                            errorMsg={formErrors['designation'] && formErrors['designation'][0]}
                                            value={form.designation}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="address"
                                            label="Address"
                                            type="address"
                                            placeholder="Address"
                                            errorMsg={formErrors['address'] && formErrors['address'][0]}
                                            value={form.address}
                                        />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            name="city"
                                            label="City"
                                            type="city"
                                            placeholder="City"
                                            errorMsg={formErrors['city'] && formErrors['city'][0]}
                                            value={form.city}
                                        />
                                    </div>

                                    <div className={styles['col-md-6']}>
                                        <InputWithError
                                            onChange={handleInputChange}
                                            label="ZipCode"
                                            name="zipcode"
                                            type="zipcode"
                                            placeholder="Zipcode"
                                            errorMsg={formErrors['zipcode'] && formErrors['zipcode'][0]}
                                            value={form.zipcode}
                                        />
                                    </div>
                                </div>
                                <div className={styles['row']}>
                                    <div className={styles['col-md-6']}>
                                        <div className={styles['my-3']}>
                                            <ReCAPTCHA
                                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                onChange={handleRecaptchaChange}
                                            />
                                        </div>
                                        <Button type="submit" text="Proceed" loading={registerMutation.isLoading} />
                                    </div>
                                    <div className={styles['col-md-6']}>
                                        <p className={styles.Register__confirmText}>
                                            By creating an account, you are agreeing to our{' '}
                                            <strong>Privacy Policy</strong>, and{' '}
                                            <strong>Electronics Communication Policy</strong>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                    <section className={styles.Register__sections__right}>
                        <Card className={styles.Register__welcomeContainer}>
                            <h1 className={styles.Register__welcomeText}>
                                <span className={styles.Register__welcomeText__main}>Welcome to Metics</span>
                                <span className={styles.Register__welcomeText__sub}>Redefining business comfort</span>
                            </h1>
                            <img src={CollabIllustration} className={styles.Register__welcomeIllustration} />
                            <ul className={styles.Register__welcomeList}>
                                <li className={styles.Register__welcomeListItem}>
                                    Make your digital presence standout with our unshakable partner network
                                </li>
                                <li className={styles.Register__welcomeListItem}>
                                    Grow exponentially with Metics Hub stretching to new horizons and global network
                                </li>
                                <li className={styles.Register__welcomeListItem}>
                                    Always partner first approach serves you with un-beatable growth and customer
                                    support
                                </li>
                            </ul>
                            <p className={styles.Register__welcomeLink}>
                                Already have an account?&nbsp;
                                <Link to="/login">Login</Link>
                            </p>
                        </Card>
                    </section>
                </div>
            </div>
            {showVerifyModal && (
                <Modal title="Verification Email Sent!">
                    <div className={styles.Register__verifyModal}>
                        <p className={styles.Register__verifyModalText}>
                            Please check your email and follow the instructions to verify your account.
                        </p>
                        <Button text="Proceed to Login" onClick={handlePopupProceed} />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Register
