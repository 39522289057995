import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { Bars, Oval } from 'react-loader-spinner'
import Loader from './Loader/Loader'
import OrganizationAPI from '../api/OrganizationAPI'
import AuthAPI from '../api/AuthAPI'

const ProtectedRoutes = ({ children }) => {
    const { user, login } = useContext(AuthContext)
    const [loading, setLoading] = useState(true)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const asyncFunc = async () => {
            if (!user || !user.loggedIn) {
                const token = localStorage.getItem('token')
                console.log(token)
                if (token) {
                    try {
                        const userRes = await AuthAPI.getUserDetails(token)
                        const userData = userRes.data

                        let organizationData
                        if (userData.organisation) {
                            try {
                                const organizationRes = await OrganizationAPI.getOrganization(token)
                                organizationData = organizationRes.data
                            } catch (err) {
                                setLoading(false)
                            }
                        }
                        login({
                            ...userData,
                            organizationData,
                            token,
                        })
                    } catch (err) {
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }
        asyncFunc()
    }, [user])

    if (loading) {
        return <Loader height="100vh" />
    }

    if (!user || !user.loggedIn) {
        return <Navigate to="/login" />
    }

    if (/register\/organization\/?/.test(location.pathname)) {
        if (user.organisation) return navigate('organization/dashboard')
    }

    if (/organization\/.*/.test(location.pathname)) {
        if (!user.organisation) {
            if (!(location.pathname === '/register/organization')) {
                return navigate('/register/organization')
            }
        }
    }

    if (/supplier\/.*/.test(location.pathname)) {
        console.log('@@@@@@@@@@@@@@@@')
        console.log(user)
        if (!user.organisation) {
            if (!(location.pathname === '/register/supplier')) {
                return navigate('/register/supplier')
            }
        }
        if (!user.organizationData.is_seller) {
            if (!(location.pathname === '/register/supplier')) {
                return navigate('/register/supplier')
            }
        }
    }

    return children ?? <Outlet />
}

export default ProtectedRoutes
