import styles from './Sidebar.module.scss'
import HomeIcon from '../../assets/icons/new/home.svg'
import FileIcon from '../../assets/icons/new/file.svg'
import TrolleyIcon from '../../assets/icons/new/trolley.svg'
import SortIcon from '../../assets/icons/new/sort_asc.svg'
import BriefcaseIcon from '../../assets/icons/new/briefcase.svg'
import ContactIcon from '../../assets/icons/new/contacts.svg'
import DeliverlyIcon from '../../assets/icons/new/delivery.svg'
import LogoImage from '../../assets/images/logo1.png'
import MeticsLogo from '../../assets/images/Metics-blue 1.png'
import SupportIcon from '../../assets/icons/new/support.svg'
import BlueImage from '../../assets/images/blue.png'
import Icon from './Icon'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { SidebarContext } from '../../context/SidebarContext'
import MenuIcon from '../../assets/icons/new/menu.svg'
import RequestIcon from '../../assets/icons/new/request.svg'
import BidsIcon from '../../assets/icons/new/bids.svg'

import ProdcutIcon from '../../assets/icons/new/product.svg'
import DeliveryIcon from '../../assets/icons/new/delivery.svg'
import LogoutIcon from '../../assets/icons/new/logout.svg'
import SettingsIcon from '../../assets/icons/svg/settings.svg'
import AuthContext from '../../context/AuthContext'

import cx from 'classnames'
import Support from '../../pages/Support/Support'

const organizationItems = [
    {
        active: 'dashboard',
        text: 'Dashboard',
        image: HomeIcon,
        link: '/organization/dashboard',
    },
    {
        active: 'rfq',
        image: FileIcon,
        text: 'RFQ',
        link: '/organization/rfq',
    },
    {
        active: 'orders',
        image: TrolleyIcon,
        text: 'Purchase Orders',
        link: '/organization/purchaseorder',
    },
    {
        active: 'supplier',
        image: DeliverlyIcon,
        text: 'Suppliers',
        link: '/organization/supplier',
    },
    {
        active: 'users',
        image: ContactIcon,
        text: 'Users',
        link: '/organization/users',
    },
    // {
    //     active: 'reports',
    //     image: SortIcon,
    //     text: 'Reports',
    //     link: '',
    // },
]

const supplierItems = [
    {
        active: 'dashboard',
        image: HomeIcon,
        text: 'Dashboard',
        link: '/supplier/dashboard',
    },
    // {
    //     active: 'rfq',
    //     image: FileIcon,
    //     text: 'RFQ',
    //     link: '/supplier/rfq',
    // },
    {
        active: 'requests',
        image: RequestIcon,
        text: 'Requests',
        link: '/supplier/requests',
    },
    {
        active: 'bids',
        image: BidsIcon,
        text: 'Bids',
        link: '/supplier/bids',
    },
    {
        active: 'orders',
        image: TrolleyIcon,
        text: 'Purchase Order',
        link: '/supplier/purchaseorder',
    },
    {
        active: 'clients',
        image: DeliveryIcon,
        text: 'Clients',
        link: '/supplier/clients',
    },
    {
        active: 'products',
        image: ProdcutIcon,
        text: 'Product Catalogue',
        link: '/supplier/products',
    },
    {
        active: 'documents',
        image: BriefcaseIcon,
        text: 'Documents',
        link: '/supplier/documents',
    },
    {
        active: 'organization',
        image: BriefcaseIcon,
        text: 'Organization',
        link: '/supplier/organization/temp',
    },
    // {
    //     active: 'reports',
    //     image: SortIcon,
    //     text: 'Reports',
    //     link: '',
    // },
]

const Sidebar = ({ active, type = 'organization' }) => {
    const { user, logout } = useContext(AuthContext)

    const { expanded, setExpanded } = useContext(SidebarContext)
    const [support, setSupport] = useState(false)

    const toggleSidebar = () => setExpanded((prev) => !prev)

    const onClose = () => setSupport(false)

    const navigate = useNavigate()
    useEffect(() => {
        const handleResize = () => setExpanded(!(window.innerWidth <= 768))
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [setExpanded])

    return (
        <div className={styles.Sidebar}>
            <div className={cx(styles.Sidebar__top, !expanded && styles.Sidebar__topNotExpanded)}>
                {expanded && <img className={styles.Sidebar__image} src={MeticsLogo} alt="logo" />}
                <span className={styles.Sidebar__expand} onClick={toggleSidebar}>
                    <img src={MenuIcon} alt="menu icon" />
                </span>
            </div>
            {(type === 'organization' ? organizationItems : []).map((item, index) => (
                <Icon
                    key={index}
                    expanded={expanded}
                    active={active === item.active}
                    image={item.image}
                    text={item.text}
                    onClick={() => navigate(item.link)}
                />
            ))}
            {(type === 'supplier' ? supplierItems : []).map((item, index) => (
                <Icon
                    key={index}
                    expanded={expanded}
                    active={active === item.active}
                    image={item.image}
                    text={item.text}
                    onClick={() => navigate(item.link)}
                />
            ))}
            <div className={styles.Sidebar__bottomDeck}>
                <Icon
                    expanded={expanded}
                    image={SupportIcon}
                    text="Support"
                    onClick={() => {
                        setSupport(true)
                    }}
                />
                <Icon
                    expanded={expanded}
                    image={SettingsIcon}
                    active={active === 'settings'}
                    text="Settings"
                    onClick={() => navigate(`/${type}/settings/profile`)}
                />
                <Icon
                    expanded={expanded}
                    image={LogoutIcon}
                    text="Logout"
                    onClick={() => {
                        logout()
                        navigate('/login')
                    }}
                />
                {support && <Support onClose={onClose} />}
            </div>
        </div>
    )
}

export default Sidebar
