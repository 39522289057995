import styles from './FormRadio.module.scss'
import { v4 as uuidv4 } from 'uuid'

// const FormRadio = ({ radios = [], name, onChange, value }) => {
//     const handleChange = (e) => {
//         onChange({
//             target: {
//                 name,
//                 value: e.target.value === 'true',
//             },
//         })
//     }
//     return (
//         <div className={styles.FormRadio}>
//             {radios.map((radio) => {
//                 const id = uuidv4()
//                 return (
//                     <div>
//                         <input type="radio" name={name} value={radio.value} id={id} onChange={handleChange} />
//                         <label htmlFor={id}>{radio.label}</label>
//                     </div>
//                 )
//             })}
//         </div>
//     )
// }

const FormRadio = ({ value, name, onChange, label, checked }) => {
    const id = uuidv4()
    const handleChange = (e) => {
        const value = e.target.value
        onChange({
            target: {
                name,
                value: value === 'true' ? true : value === 'false' ? false : value,
            },
        })
    }
    return (
        <div className={styles.Radio}>
            <input type="radio" name={name} value={value} id={id} onChange={handleChange} checked={checked} />
            <label className={styles.Radio__Label} htmlFor={id}>
                {label}
            </label>
        </div>
    )
}

export default FormRadio
