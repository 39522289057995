import React, { useContext } from 'react'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import SeaIcon from '../../assets/icons/sea.svg'
import styles from '../PurchaseOrder/PurchaseOrder.module.scss'
import Table from '../../components/Table/Table'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import TableContent from '../../components/Table/TableContent/TableContent'
import Button from '../../components/Button/Button'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import AuthContext from '../../context/AuthContext'
import Badge from '../../components/Badge/Badge'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import SupplierAPI from '../../api/SupplierAPI'
import Loader from '../../components/Loader/Loader'

const SupplierOrder = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const handleRowClick = (id) => {
        navigate(`/supplier/purchaseorder/${id}`)
    }

    const fetchPurchaseOrders = useQuery(['purchaseOrders'], () =>
        SupplierAPI.getSupplierPurchaseOrder({ token: user.token })
    )

    return (
        <SupplierLayout active="orders" title="Purchase Orders">
            <div className={styles.Cards}>
                <div className={styles.Cards__card}>
                    <OrganizationCard
                        icon={SeaIcon}
                        color="#5E81F41A"
                        name="All"
                        value={fetchPurchaseOrders?.data?.data?.length.toString()}
                    />
                </div>

                {/* <div className={styles.Cards__card}>
                    <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Accepted" value="0" />
                </div>
                <div className={styles.Cards__card}>
                    <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Draft" value="0" />
                </div> */}
            </div>
            <br />
            <Table>
                <TableControls>
                    <div className={styles.Table__tableControls}>
                        <div>
                            <Button text="Export CSV" color="#0E2C24" />
                        </div>
                        <div className={styles.Table__row}>
                            <TableControlSearch />
                        </div>
                    </div>
                </TableControls>

                <TableContent hoverable={!(fetchPurchaseOrders.isLoading || fetchPurchaseOrders.isError)}>
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Document Number</th>
                            <th>Total Price</th>
                            <th>Buyer Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {fetchPurchaseOrders.isLoading || fetchPurchaseOrders.isError ? (
                            <tr>
                                <td colSpan="7">
                                    <Loader height="30vh" refresh={fetchPurchaseOrders.isError} />
                                </td>
                            </tr>
                        ) : fetchPurchaseOrders?.data?.data?.length === 0 ? (
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center' }}>
                                    No purchase orders found
                                </td>
                            </tr>
                        ) : (
                            fetchPurchaseOrders?.data?.data?.map((order, index) => (
                                <tr key={order.id} onClick={() => handleRowClick(order.id)}>
                                    <td>{index + 1}</td>
                                    <td>{order.document_num}</td>
                                    <td>{order.total_price}</td>
                                    <td>{order.supplier_organisation_details.name}</td>
                                    <td>{order.supplier_organisation_details.email}</td>
                                    <td>{order.supplier_organisation_details.contact}</td>
                                    <td>
                                        {order.int_status === 0 && (
                                            <Badge color="#f7a64f" backgroundColor="#fdebd8" value="Pending" />
                                        )}
                                        {order.int_status === 1 && (
                                            <Badge color="#1BBB6B" backgroundColor="#EDF8F3" value="Approved" />
                                        )}
                                        {order.int_status === -1 && (
                                            <Badge color="#FC555B" backgroundColor="#FED7D8" value="Declined" />
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </TableContent>
            </Table>
        </SupplierLayout>
    )
}

export default SupplierOrder
