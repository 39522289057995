import React from 'react'
import Button from '../Button/Button'
import Styles from './Pagination.module.scss'

const Pagination = ({ handleChange, count, currentPage }) => {
    const pages = Math.ceil(count / 10)
    return (
        <div className={Styles.Container}>
            <button
                className={Styles.Container__arrowBtn}
                disabled={currentPage <= 1}
                onClick={() => handleChange(currentPage - 2)}
            >
                <span className={Styles.Container__arrow}>&laquo;</span>
            </button>
            <button
                className={Styles.Container__arrowBtn}
                disabled={currentPage <= 1}
                onClick={() => handleChange(currentPage - 1)}
            >
                <span className={Styles.Container__arrow}>&lt;</span>
            </button>
            <button
                className={Styles.Container__arrowBtn}
                disabled={currentPage >= pages}
                onClick={() => handleChange(currentPage + 1)}
            >
                <span className={Styles.Container__arrow}>&gt;</span>
            </button>
            <button
                className={Styles.Container__arrowBtn}
                disabled={currentPage >= pages}
                onClick={() => handleChange(currentPage + 2)}
            >
                <span className={Styles.Container__arrow}>&raquo;</span>
            </button>
        </div>
    )
}

export default Pagination
