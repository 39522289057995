import { useNavigate } from 'react-router'
import OrganizationLayout from '../../../components/OrganizationLayout/OrganizationLayout'
import OrganizationCard from '../../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import styles from './ClientDetails.module.scss'
import { useState } from 'react'
import SeaIcon from '../../../assets/icons/sea.svg'
import Button from '../../../components/Button/Button'

const OrganizationClientDetails = () => {
    const navigate = useNavigate()

    const [tableData, setTableData] = useState([
        {
            Number: 'PO-0012',
            Reference: 'P/O CRM08-12',
            From: 'Development  DEPT',
            Date: '25 Oct 2021',
            'Due Date': '03 Nov 2021',
            Replys: '9',
            'Last Updation': '03 Nov 2021',
            Status: 'Awaiting Payment',
        },
        {
            Number: 'PO-0012',
            Reference: 'P/O CRM08-12',
            From: 'Development  DEPT',
            Date: '25 Oct 2021',
            'Due Date': '03 Nov 2021',
            Replys: '9',
            'Last Updation': '03 Nov 2021',
            Status: 'Awaiting Payment',
        },
        {
            Number: 'PO-0012',
            Reference: 'P/O CRM08-12',
            From: 'Development  DEPT',
            Date: '25 Oct 2021',
            'Due Date': '03 Nov 2021',
            Replys: '9',
            'Last Updation': '03 Nov 2021',
            Status: 'Awaiting Payment',
        },
    ])

    return (
        <OrganizationLayout>
            <div className={styles.SupplierDetails__container}>
                <div className={styles.SupplierDetails__header}>
                    <div>
                        <h1 className={styles.SupplierDetails__header__title}>Supplier</h1>
                        <p className={styles.SupplierDetails__header__company}>
                            Amazon India Private Limited | AMZIN-23
                        </p>
                        <p className={styles.SupplierDetails__header__location}>Noida, India</p>
                        <a href="mailto:business-sales@amazon.in" className={styles.SupplierDetails__header__email}>
                            business-sales@amazon.in
                        </a>
                        <a href="tel:+91 484-282828" className={styles.SupplierDetails__header__phone}>
                            +91 484-282828
                        </a>
                    </div>
                    <div>
                        <div className={styles.SupplierDetails__header__buttons}>
                            <Button text="Update" />
                            <Button text="Remove" />
                        </div>
                        <p className={styles.SupplierDetails__header__pocTitle}>Person of Contact</p>
                        <p className={styles.SupplierDetails__header__pocName}>Neil Berfosa</p>
                        <a href="mailto:b.neil@amazon.in" className={styles.SupplierDetails__header__pocEmail}>
                            b.neil@amazon.in
                        </a>
                    </div>
                </div>
                <h2>Overview</h2>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Total Orders" value="93" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Order Value" value="INR 3.2 Cr" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Completed" value="23" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#FFFFFF" name="Incomplete" value="70" />
                    </div>
                </div>
                <table className={styles.Table}>
                    <thead>
                        <tr>
                            <th>Number</th>
                            <th>Reference</th>
                            <th>From</th>
                            <th>Date</th>
                            <th>Due Date</th>
                            <th>Replys</th>
                            <th>Last Updation</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, id) => {
                            return (
                                <tr key={id}>
                                    {Object.values(row).map((field) => (
                                        <td>{field}</td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </OrganizationLayout>
    )
}

export default OrganizationClientDetails
