import styles from './ProductEnquiry.module.scss'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import MeticsLogo from '../../assets/images/Metics-blue 1.png'
import InputWithError from '../../components/InputWithError/InputWithError'
import { toast } from 'react-toastify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import { MdDeleteOutline } from 'react-icons/md'
import SupplierAPI from '../../api/SupplierAPI'
import { useQuery, useMutation } from '@tanstack/react-query'

const ProductEnquiry = () => {
    const location = useLocation()
    const { id } = useParams()
    const { state } = location
    const initialEnquiries = state?.enquiries || []
    const navigate = useNavigate()

    const [form, setForm] = useState({ orgName: '', email: '' })
    const [formErrors, setFormErrors] = useState({})
    const [selectedProducts, setSelectedProducts] = useState(initialEnquiries)

    // useEffect(() => {
    //     if (location.state && location.state.enquiries) {
    //         setSelectedProducts(location.state.enquiries)
    //     }
    // }, [location.state])

    // useEffect(() => {
    //     console.log('Selected products:', selectedProducts)
    //     localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts))
    // }, [selectedProducts])

    useEffect(() => {
        console.log('Initial Enquiries:', initialEnquiries)
    }, [initialEnquiries])

    const handleQuantityChange = (index, newQuantity) => {
        setSelectedProducts((prevProducts) => {
            const updatedProducts = [...prevProducts]
            updatedProducts[index].quantity = newQuantity
            return updatedProducts
        })
    }

    const handleFormChange = (e) => {
        const { name, value } = e.target
        setForm((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const errors = {}
        if (!form.orgName) errors.orgName = 'Organization name is required'
        if (!form.email) errors.email = 'Email is required'
        if (selectedProducts.length === 0) errors.products = 'At least one product must be selected'
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            try {
                const data = {
                    organisation_name: form.orgName,
                    email: form.email,
                    items: selectedProducts.map((product) => ({
                        product: product.id,
                        quantity: product.quantity,
                    })),
                }

                console.log('Submitting product enquiry:', data)
                console.log('Form data:', form)
                console.log('Selected products:', selectedProducts)

                await SupplierAPI.addProductEnquiry({data})
                toast.success('Enquiry submitted successfully')
            } catch (error) {
                console.error('Error submitting enquiry:', error)
                toast.error('Failed to submit enquiry')
            }
        }
    }

    const handleDelete = (slNo) => {
        setSelectedProducts((prevProducts) => prevProducts.filter((product) => product.slNo !== slNo))
    }

    const ListTableBody = React.memo(({ data }) => (
        <>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((product, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{product.name}</td>
                        <td>
                            <input
                                type="number"
                                value={product.quantity}
                                min="1"
                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                            />
                        </td>
                        <td>
                            <MdDeleteOutline onClick={() => handleDelete(product.slNo)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    ))

    return (
        <div className={styles.Container}>
            <div className={styles.LogoContainer}>
                <img className={styles.Logo} src={MeticsLogo} alt="logo" />
            </div>
            <div className={styles.Header}>
                <h1 className={styles.Header__title}>Product Enquiry</h1>
            </div>
            <Card className={styles.EnquiryForm}>
                <form onSubmit={handleSubmit}>
                    <InputWithError
                        name="orgName"
                        label="Organization Name"
                        placeholder="Enter organization name"
                        type="text"
                        value={form.orgName}
                        onChange={handleFormChange}
                        errorMsg={formErrors.orgName}
                    />
                    <InputWithError
                        name="email"
                        label="Email"
                        placeholder="Enter email"
                        type="email"
                        value={form.email}
                        onChange={handleFormChange}
                        errorMsg={formErrors.email}
                    />

                    <Table>
                        <TableContent>
                            <ListTableBody data={selectedProducts} />
                        </TableContent>
                    </Table>

                    <Button className={styles.Enquiry_submit} type="submit" text="Submit" />
                </form>
            </Card>
        </div>
    )
}

export default ProductEnquiry
