import React, { useState } from 'react'
import cx from 'classnames'
import styles from './Modal.module.scss'
import Card from '../Card/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Modal = ({ title, onClose, children }) => {
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = (event) => {
        if (event.target.classList.contains(styles.overlay)) {
            return
        }

        setIsOpen(false)
        onClose()
    }

    const modalClasses = cx(styles.modal, {
        [styles.open]: isOpen,
    })

    return (
        <div className={modalClasses}>
            <div className={styles.overlay} onClick={closeModal}></div>
            <Card className={styles.content}>
                <div className={styles.header}>
                    {title && <h2 className={styles.title}>{title}</h2>}
                    <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} onClick={closeModal} />
                </div>
                <div className={styles.body}>{children}</div>
            </Card>
        </div>
    )
}

export default Modal
