import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LogoImage from '../../assets/images/logo.png'
import HandshakeImage from '../../assets/images/handshake.png'

import styles from './CreateOrganization.module.scss'
import Header from '../../components/Header/Header'
import { toast } from 'react-toastify'
import cx from 'classnames'

import AuthContext from '../../context/AuthContext'
import InputWithError from '../../components/InputWithError/InputWithError'
import { Oval } from 'react-loader-spinner'
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox'
import FormSelect from '../../components/FormSelect/FormSelect'
import OrganizationAPI from '../../api/OrganizationAPI'
import { useMutation, useQuery } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import AuthAPI from '../../api/AuthAPI'
import Button from '../../components/Button/Button'
import HeaderLogo from '../../components/Header/HeaderLogo'
import Modal from '../../components/Modal/Modal'

const CreateOrganization = () => {
    const [form, setForm] = useState({ additional_attributes: [] })
    const [formErrors, setFormErrors] = useState({})
    const [showBuyerCreatedPopup, setShowBuyerCreatedPopup] = useState(false)

    const { user, login, logout } = useContext(AuthContext)
    const navigate = useNavigate()

    // const countryChoicesQuery = useQuery(['country-choices'], AuthAPI.getCountryChoices, {
    //     select: (data) => {
    //         const list = {}
    //         Object.keys(data?.data).forEach((code) => (list[data?.data[code]] = code))
    //         console.log({ list })
    //         return list
    //     },
    // })

    const countryChoicesQuery = useQuery(['country-choices'], AuthAPI.getCountryChoices, {
        select: (data) => {
            data = data?.data
            const list = {}
            const countryList = {}
            const dialCode = {}
            Object.keys(data).forEach((code) => {
                dialCode[`${data[code].name} ${data[code].dial_code}`] = data[code].dial_code
            })
            Object.keys(data).forEach((code) => {
                countryList[data[code].name] = code
            })
            list['countryList'] = countryList
            list['dialCode'] = dialCode
            list['data'] = data
            console.log(list)
            return list
        },
    })

    const createOrganizationMutation = useMutation(OrganizationAPI.createOrganization, {
        onError: (err) =>
            err.response.status === 400 ? setFormErrors(err.response.data) : toast.error(err.response.data?.message),

        onSuccess: () => {
            toast.success('Buyer created')
            setShowBuyerCreatedPopup(true)

            setTimeout(() => {
                navigate('/login')
            }, 3000)
        },
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: null,
        }))

        setForm((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log('creating org')
        createOrganizationMutation.mutate({
            token: user.token,
            data: form,
        })
    }

    if (countryChoicesQuery.isLoading) {
        return <Loader height="100vh" />
    }

    if (countryChoicesQuery.isError) {
        toast('Failed to fetch country list', {
            type: 'error',
        })
        return <Loader height="100vh" refresh />
    }

    return (
        <div className={styles.CreateOrganization}>
            <div className={styles.Register__headerLogo}>
                <HeaderLogo onClick={() => navigate('/login')} />
            </div>
            <Header title="Create Buyer" showLogout />
            <div className={cx(styles.CreateOrganization__contentContainer, styles.container)}>
                <h1 className={styles.CreateOrganization__title}>
                    <span className={styles.CreateOrganization__title__main}>Add Your Buyer Details</span>
                </h1>
                <form className={styles.CreateOrganization__form} onSubmit={handleSubmit}>
                    <div className={cx(styles['row'], styles['justify-content-between'])}>
                        <div className={cx(styles['col-md-5'])}>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="name"
                                    type="text"
                                    placeholder="Buyer Name"
                                    value={form.name}
                                    errorMsg={formErrors.name}
                                    label="Buyer Name"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="address_line1"
                                    type="text"
                                    placeholder="Address Line 1"
                                    value={form.address_line1}
                                    errorMsg={formErrors.address_line1}
                                    label="Address Line 1"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="address_line2"
                                    type="text"
                                    placeholder="Address Line 2"
                                    value={form.address_line2}
                                    errorMsg={formErrors.address_line2}
                                    label="Address Line 2"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="city"
                                    type="text"
                                    placeholder="City"
                                    value={form.city}
                                    errorMsg={formErrors.city}
                                    label="City"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <FormSelect
                                    name="country"
                                    label="Country"
                                    onChange={handleInputChange}
                                    placeholder="Country"
                                    value={form.country}
                                    options={countryChoicesQuery?.data.countryList}
                                    errorMsg={formErrors.country}
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="state"
                                    type="text"
                                    placeholder="State/Province"
                                    value={form.state}
                                    errorMsg={formErrors.state}
                                    label="State"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="postal_code"
                                    type="text"
                                    placeholder="Zip Code"
                                    value={form.postal_code}
                                    errorMsg={formErrors.postal_code}
                                    label="Zip Code"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <FormSelect
                                    onChange={handleInputChange}
                                    name="default_currency"
                                    type="text"
                                    placeholder="Default Currency"
                                    value={form.default_currency}
                                    options={{
                                        INR: 'INR',
                                        USD: 'USD',
                                    }}
                                    errorMsg={formErrors.default_currency}
                                    label="Default Currency"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="email"
                                    type="email"
                                    placeholder="Buyer Email"
                                    value={form.email}
                                    errorMsg={formErrors.email}
                                    label="Buyer Email"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="contact"
                                    type="text"
                                    placeholder="Buyer Phone"
                                    value={form.contact}
                                    errorMsg={formErrors.contact}
                                    label="Buyer Phone"
                                />
                            </div>
                        </div>
                        <div className={cx(styles['col-md-5'])}>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="total_turnover"
                                    type="text"
                                    placeholder="Total Turnover"
                                    value={form.total_turnover}
                                    errorMsg={formErrors.total_turnover}
                                    label="Total Turnover"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <InputWithError
                                    onChange={handleInputChange}
                                    name="number_of_employees"
                                    type="number"
                                    placeholder="Number of Employees"
                                    value={form.number_of_employees}
                                    errorMsg={formErrors.number_of_employees}
                                    label="Number of Employees"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <FormSelect
                                    onChange={handleInputChange}
                                    name="business_type"
                                    type="text"
                                    placeholder="Business Type"
                                    value={form.business_type}
                                    options={{
                                        manufacturer: 'Manufacturer',
                                        supplier: 'Supplier',
                                        distributor: 'Distributor',
                                        retailer: 'Retailer',
                                        service_provider: 'Service Provider',
                                        other: 'Other',
                                    }}
                                    errorMsg={formErrors.business_type}
                                    label="Business Type"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <FormCheckbox
                                    onChange={handleInputChange}
                                    name="agree_terms"
                                    label="I have read and agree with the Terms and Use"
                                    id="CreateOrganization_agree_with_terms"
                                />
                            </div>
                            <div className={styles.CreateOrganization__inputField}>
                                <FormCheckbox
                                    onChange={handleInputChange}
                                    name="agree"
                                    label="I hereby agree that Metics will make parts of my (company) information 
                                    accessible to other users and the public based on my role within the  platform 
                                    and the applicable profile visibility settings.
                                    Please see the Privacy Statement to learn how we process personal data."
                                    id="CreateOrganization_agree"
                                />
                            </div>
                            <Button
                                type="submit"
                                text="Create an Account"
                                loading={createOrganizationMutation.isLoading}
                            />
                        </div>
                    </div>
                </form>
                {showBuyerCreatedPopup && (
                    <Modal title="Buyer Created Successfully">
                        <div className={styles.CreateOrganization__buyerCreatedPopup}>
                            <p>Thank you for creating a buyer account!</p>
                            <Button text="Proceed to Login" onClick={() => navigate('/login')} />
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default CreateOrganization
