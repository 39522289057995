import React, { useState, useEffect } from 'react'

import styles from './OrganizationUserDetails.module.scss'

import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import Card from '../../components/Card/Card'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Loader from '../../components/Loader/Loader'
import Button from '../../components/Button/Button'
import { IoArrowBackOutline } from 'react-icons/io5'

const OrganizationUserDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { state } = useLocation()
    const detailsQuery = state && state.rowData
    console.log({ detailsQuery })

    return (
        <OrganizationLayout active="users" title="Details">
            {detailsQuery.isError ? (
                <Loader />
            ) : detailsQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.details}>
                        <div className={styles.Card}>
                            <p className={styles.Request__organizationDetails}>
                                <span className={styles.Request__organizationName}>User Profile</span>
                            </p>
                            <div className={styles.Request__organizationContents}>
                                <p className={styles.Request__text}>
                                    <span>Name</span>
                                    <span>{detailsQuery.Name}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Email</span>
                                    <span>{detailsQuery.Email}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Contact</span>
                                    <span>{detailsQuery.Phone}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Address</span>
                                    <span>{detailsQuery.Address}</span>
                                </p>

                                <p className={styles.Request__text}>
                                    <span>City</span>
                                    <span>{detailsQuery.City}</span>
                                </p>
                                {/* <p className={styles.Request__text}>
                            <span>State</span>
                            {detailsQuery.data?.data?.state}
                        </p> */}
                                <p className={styles.Request__text}>
                                    <span>Country</span>
                                    <span>{detailsQuery.Country}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Zip Code</span>
                                    <span>{detailsQuery.Zipcode}</span>
                                </p>
                                {/* <p className={styles.Request__text}>
                            <span>Default currency</span>
                            {detailsQuery.data?.data?.default_currency}
                        </p>
                        <p className={styles.Request__text}>
                            <span>Number of Employees</span>
                            {detailsQuery.data?.data?.number_of_employees}
                        </p>
                        <p className={styles.Request__text}>
                            <span>Total Turnover</span>
                            {detailsQuery.data?.data?.total_turnover}
                        </p> */}
                            </div>
                        </div>
                        <br />
                        <Button text={'Edit'} onClick={() => navigate(`/organization/users/edit/${detailsQuery.ID}`)} />
                    </div>
                </>
            )}
        </OrganizationLayout>
    )
}

export default OrganizationUserDetails
