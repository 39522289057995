import React, { useState } from 'react'
import styles from './Welcome.module.scss'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import { useMutation } from '@tanstack/react-query'
import AuthAPI from '../../api/AuthAPI'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'

const Welcome = () => {
    const { user } = useContext(AuthContext)
    console.log(user)

    const [popup, setPopup] = useState(true)
    const setWelcomeScreenSeen = useMutation(AuthAPI.setWelcomeScreenSeen, {
        onSuccess: () => {},
    })
    const handleClose = () => {
        setPopup(false)
        setWelcomeScreenSeen.mutateAsync({
            token: user.token,
        })
    }

    return (
        <div className={styles.welcome_container}>
            {popup && (
                <Modal onClose={handleClose}>
                    <div className={styles.welcome_content}>
                        <h1 className={styles.welcome_heading}>Welcome to Metics!</h1>
                        <p className={styles.welcome_text}>Connecting Businesses to Endless Possibilities</p>
                        <Button text="Get Started" onClick={handleClose} className={styles.dashboard_button} />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Welcome
