import React, { useState } from 'react'
import styles from './Toggle.module.scss'
import cx from 'classnames'

const Toggle = ({ label, name, disabled = false, errorMsg = '', onToggle }) => {
    const [isChecked, setIsChecked] = useState(false)

    const toggleCheckbox = (e) => {
        if (!disabled) {
            setIsChecked(!isChecked)
            if (onToggle) {
                onToggle(e)
            }
        }
    }

    return (
        <div className={styles.toggle__switch}>
            {label && <label className={styles.toggle__switch__label}>{label}</label>}
            <div className={cx(styles.toggle__switch__wrapper, disabled ? 'disabled' : '')}>
                <input
                    type="checkbox"
                    name={name}
                    checked={isChecked}
                    value={isChecked}
                    onChange={toggleCheckbox}
                    disabled={disabled}
                />
            </div>
            {errorMsg && <pre className={styles.toggle__switch__msg}>{errorMsg}</pre>}
        </div>
    )
}

export default Toggle
