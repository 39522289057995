// stylesheet
import styles from './OrganizationUserManagement.module.scss'

// assets
import SeaIcon from '../../assets/icons/sea.svg'

// context
import AuthContext from '../../context/AuthContext'

// api
import OrganizationAPI from '../../api/OrganizationAPI'

// packages
import { useNavigate } from 'react-router-dom'
import { useState, useContext, useRef } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

// components
import Button from '../../components/Button/Button'
import DropdownCard from '../../components/DropdownCard/DropdownCard'
import FormMultiSelect from '../../components/FormMultiSelect/FormMultiSelect'
import InputWithError from '../../components/InputWithError/InputWithError'
import Loader from '../../components/Loader/Loader'
import Modal from '../../components/Modal/Modal'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'

const OrganizationUserManagement = () => {
    const { user } = useContext(AuthContext)

    const [showDropdown, setShowDropdown] = useState(false)
    const dropdownToggleRef = useRef(null)

    const [searchQuery, setSearchQuery] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [modalForm, setModalForm] = useState({})
    const [modalFormErrors, setModalFormErrors] = useState({})

    const [showAuthorizedSellerModal, setShowAuthorizedSellerModal] = useState(false)
    const [authorizedSellerModalForm, setAuthorizedSellerModalForm] = useState(false)
    const [authorizedSellerModalFormErrors, setAuthorizedSellerModalFormErrors] = useState(false)
    let serialNumber = 1

    const navigate = useNavigate()

    //============
    // React Query
    //============
    const modalSubmitMutation = useMutation(OrganizationAPI.inviteClient, {
        onError: (err) => {
            console.log(err.response.data?.message)
            toast.error(err.response.data?.message)
            if (err.response.status === 400) {
                setModalFormErrors(err.response.data)
            }
            handleModalFormErrorsClear()
        },
        onSuccess: () => {
            toast.success('User has been invited')
            handleModalFormClear()
            handleModalFormErrorsClear()
            handleModalClose()
        },
    })

    const authorizedSellerModalSubmitMutation = useMutation(OrganizationAPI.addAuthorizedSeller, {
        onError: (err) => toast.error(err.response.data?.message),
        onSuccess: () => {
            handleAuthorizedSellerModalFormClear()
            handleAuthorizedSellerModalClose()
            handleDropdownClose()
        },
    })

    const getSellersQuery = useQuery(['sellers'], () => OrganizationAPI.getAllSellers(user.token), {
        select: (data) => {
            const sellers = {}
            data.data?.map((seller) => (sellers[seller.id] = seller.name))
            return sellers
        },
    })

    const getStaffsQuery = useQuery(
        ['organization-staffs'],
        () =>
            OrganizationAPI.getOrganizationStaffs({
                token: user.token,
                id: user.organizationData.id,
            }),
        {
            select: (data) =>
                data?.data.map((staff) => {
                    return {
                        ID: staff.id,
                        Username: staff.username,
                        Name: `${staff.first_name} ${staff.middle_name} ${staff.last_name}`,
                        Email: staff.email,
                        Phone: staff.phone,
                        Address: staff.address,
                        City: staff.city,
                        Country: staff.country,
                        Zipcode: staff.zipcode,
                    }
                }),
        }
    )

    const filteredStaffs = getStaffsQuery.data?.filter((staff) => {
        const searchTerm = searchQuery.toLowerCase()
        return (
            staff.ID.toString().includes(searchTerm) ||
            staff.Username.includes(searchTerm) ||
            staff.Name.includes(searchTerm) ||
            staff.Email.includes(searchTerm) ||
            staff.Phone.includes(searchTerm) ||
            staff.Address.includes(searchTerm) ||
            staff.City.includes(searchTerm) ||
            staff.Country.includes(searchTerm) ||
            staff.Zipcode.includes(searchTerm)
        )
    })

    //==========
    // Handlers
    //==========
    const handleDropdownClose = () => setShowDropdown(false)
    const handleDropdownOpen = () => setShowDropdown(true)
    const handleDropdownToggle = () => setShowDropdown((prev) => !prev)

    const handleModalOpen = () => setShowModal(true)
    const handleModalClose = () => setShowModal(false)
    const handleModalToggle = () => setShowModal((prev) => !prev)
    const handleModalChange = (e) => setModalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    const handleModalFormClear = () => setModalForm({})
    const handleModalFormErrorsClear = () => setModalFormErrors({})
    const handleModalSubmit = (e) => {
        e.preventDefault()
        modalSubmitMutation.mutate({ token: user.token, data: modalForm })
    }

    const handleAuthorizedSellerModalClose = () => setShowAuthorizedSellerModal(false)
    const handleAuthorizedSellerModalToggle = () => setShowAuthorizedSellerModal((prev) => !prev)
    const handleAuthorizedSellerModalChange = (e) =>
        setAuthorizedSellerModalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    const handleAuthorizedSellerModalFormClear = () => setAuthorizedSellerModalForm({})
    const handleAuthorizedSellerModalFormErrorsClear = () => setAuthorizedSellerModalFormErrors({})
    const handleAuthorizedSellerModalSubmit = async (e) => {
        e.preventDefault()
        for (let id of authorizedSellerModalForm.sellers) {
            await authorizedSellerModalSubmitMutation.mutateAsync({
                token: user.token,
                orgId: user.organisation,
                data: { 'organisation-id': id },
            })
        }
        toast.success('Suppliers has been added as authorized seller')
        handleAuthorizedSellerModalFormErrorsClear()
        handleAuthorizedSellerModalFormClear()
        handleAuthorizedSellerModalClose()
        handleDropdownClose()
    }
    const handleSearchChange = (query) => {
        setSearchQuery(query)
    }

    const navigateToDetailPage = (row) => {
        navigate(`/organization/users/details/${row.ID} `, { state: { rowData: row } })
    }

    const handleInviteUser = () => {
        navigate('/organization/users/create')
    }

    //===========
    // Render JSX
    //===========
    return (
        <OrganizationLayout title="User Management" active="users">
            <>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="All"
                            value={getStaffsQuery.data?.length.toString()}
                        />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Accepted" value="0" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Draft" value="0" />
                    </div>
                </div>
                <br />
                <Table>
                    <TableControls>
                        <div className={styles.Table__tableControls}>
                            <div>
                                <Button text="Export CSV" color="#0E2C24" />
                            </div>
                            <div className={styles.Table__row}>
                                <TableControlSearch onSearchChange={handleSearchChange} />
                                <div className={styles.Table__btnWrapper}>
                                    <Button
                                        reference={dropdownToggleRef}
                                        text="Add New User"
                                        onClick={handleDropdownToggle}
                                    />
                                    <DropdownCard
                                        active={showDropdown}
                                        close={setShowDropdown}
                                        parentRef={dropdownToggleRef}
                                    >
                                        <li
                                            className={styles.List}
                                            style={{ fontSize: '13px' }}
                                            onClick={handleModalOpen}
                                        >
                                            Invite User
                                        </li>
                                        <li
                                            className={styles.List}
                                            style={{ fontSize: '13px' }}
                                            onClick={handleInviteUser}
                                        >
                                            Add New User
                                        </li>
                                    </DropdownCard>
                                </div>
                            </div>
                        </div>
                    </TableControls>
                    <TableContent hoverable>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getSellersQuery.isError ||
                            getStaffsQuery.isError ||
                            getSellersQuery.isLoading ||
                            getStaffsQuery.isLoading ? (
                                <td colSpan="5">
                                    <Loader refresh={getSellersQuery.isError || getStaffsQuery.isError} height="30vh" />
                                </td>
                            ) : (
                                filteredStaffs?.map((row) => (
                                    <tr key={row.ID} onClick={() => navigateToDetailPage(row)}>
                                        <td>{serialNumber++}</td>
                                        <td>{row.Username}</td>
                                        <td>{row.Name}</td>
                                        <td>{row.Email}</td>
                                        <td>{row.Phone}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </TableContent>
                </Table>
                {showModal && (
                    <Modal title="Invite User" onClose={handleModalClose}>
                        <form onSubmit={handleModalSubmit}>
                            <InputWithError
                                name="email"
                                type="email"
                                label="Email"
                                placeholder="Enter email"
                                onChange={handleModalChange}
                                value={modalForm.email}
                                errorMsg={modalFormErrors.email}
                            />
                            <br />
                            <Button text="Submit" type="submit" loading={modalSubmitMutation.isLoading} />
                        </form>
                    </Modal>
                )}

                {/* {showAuthorizedSellerModal && (
                        <Modal title="Add Authorized Seller" onClose={handleAuthorizedSellerModalClose}>
                            <form onSubmit={handleAuthorizedSellerModalSubmit}>
                                <div className={styles.SellerModal__selectWrapper}>
                                    <FormMultiSelect
                                        label="Sellers"
                                        data={getSellersQuery.data}
                                        name="sellers"
                                        placeholder="Select sellers"
                                        onChange={handleAuthorizedSellerModalChange}
                                    />
                                </div>
                                <Button
                                    text="Submit"
                                    type="submit"
                                    loading={authorizedSellerModalSubmitMutation.isLoading}
                                />
                            </form>
                        </Modal>
                    )} */}
            </>
        </OrganizationLayout>
    )
}

export default OrganizationUserManagement
