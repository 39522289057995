// styles
import styles from './SupplierProductCatalogueView.module.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// context
import AuthContext from '../../context/AuthContext'

// packages
import { useParams, useNavigate } from 'react-router'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useState } from 'react'
import { useRef } from 'react'
import cx from 'classnames'

// components
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Card from '../../components/Card/Card'
import SupplierAPI from '../../api/SupplierAPI'
import Loader from '../../components/Loader/Loader'
import Button from '../../components/Button/Button'
import { toast } from 'react-toastify'

const SupplierProductCatalogueView = () => {
    const { id } = useParams()
    const { user } = useContext(AuthContext)
    const swiperRef = useRef(null)
    const navigate = useNavigate()
    const [activeImage, setActiveImage] = useState(0)

    const getProductQuery = useQuery(['product', id], () => SupplierAPI.getProductById({ token: user.token, id }))
    // mutation to delete product

    const productData = getProductQuery.data?.data
    console.log(productData)
    const deleteProductMutation = useMutation(
        () => SupplierAPI.deleteProductById({ token: user.token, id: productData.id }),
        {
            onSuccess: () => {
                toast.success('Product deleted successfully')
                navigate('/supplier/products')
            },
            onError: () => {
                toast.error('Something went wrong')
            },
        }
    )
    const handleNavigate = () => {
        const { name, list_price_per_unit, description, images } = productData
        const data = { name, list_price_per_unit, description, images }
        navigate(`/supplier/products/edit/${id}`, { state: { data } })
    }
    const handleDelete = async () => {
        deleteProductMutation.mutate()
    }

    return (
        <SupplierLayout title="Product Catalogue" active="products">
            {getProductQuery.isError ? (
                <Loader refresh />
            ) : getProductQuery.isLoading ? (
                <Loader />
            ) : (
                <div>
                    <div className={styles.Product__contentWrapper}>
                        <div className={styles.Product__imagesWrapper}>
                            <Card className={styles.Images}>
                                <div className={styles.Product__mainImageWrapper}>
                                    <img
                                        alt="product"
                                        src={
                                            process.env.REACT_APP_API_BASE_URL + productData?.images[activeImage]?.image
                                        }
                                        className={styles.Product__mainImage}
                                    />
                                </div>
                                <Swiper
                                    modules={[Navigation]}
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    ref={swiperRef}
                                    navigation
                                    loop
                                    onSlideChange={(swiper) => setActiveImage(swiper.realIndex)}
                                    centeredSlides
                                >
                                    {productData?.images?.map((img, idx) => (
                                        <SwiperSlide key={idx}>
                                            {({ isActive }) => {
                                                return (
                                                    <div
                                                        className={cx(
                                                            styles.Product__imageWrapper,
                                                            isActive && styles.Product__imageWrapper__active
                                                        )}
                                                    >
                                                        <img
                                                            alt="product"
                                                            src={process.env.REACT_APP_API_BASE_URL + img.image}
                                                            className={styles.Product__image}
                                                            swiper
                                                        />
                                                    </div>
                                                )
                                            }}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Card>
                        </div>
                        <Card className={styles.Product__detailsWrapper}>
                            <h2 className={styles.Product__detailsTitle}>{productData?.name}</h2>
                            <p className={styles.Product__details}>Category</p>
                            <p className={styles.Product__detailsValue}>{productData?.product_sub_category}</p>
                            <p className={styles.Product__details}>Price</p>
                            <p className={styles.Product__detailsValue}>${productData?.list_price_per_unit}</p>
                            <p className={styles.Product__details}>Availble</p>
                            <p className={styles.Product__detailsValue}>{productData?.is_available ? 'Yes' : 'No'}</p>
                            <p className={styles.Product__details}>Description</p>
                            <p className={styles.Product__detailsValue}>{productData?.description}</p>
                        </Card>
                    </div>
                    <div className={styles.Btn__wrapper}>
                        <Button text={'Edit'} className={styles.Product__editButton} onClick={handleNavigate} />
                        <Button text={'Delete'} className={styles.Product__deleteButton} onClick={handleDelete} />
                    </div>
                </div>
            )}
        </SupplierLayout>
    )
}

export default SupplierProductCatalogueView
