import styles from './Button.module.scss'
import { Oval } from 'react-loader-spinner'
import cx from 'classnames'

const Button = ({
    text,
    loading = false,
    onClick = () => {},
    disabled = false,
    color = '',
    icon,
    type = 'button',
    className,
    reference,
}) => {
    const style = {}
    if (disabled) style.backgroundColor = 'grey'
    else if (color) style.backgroundColor = color

    return (
        <button
            ref={reference}
            type={type}
            className={cx(styles.Button, className && className)}
            disabled={disabled || loading}
            style={style}
            onClick={onClick}
        >
            {icon && <img src={icon} />}
            <span>{text}</span>
            <Oval
                height={15}
                width={15}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                ariaLabel="oval-loading"
                secondaryColor="#bbbbbb"
                strokeWidth={7}
                strokeWidthSecondary={7}
            />
        </button>
    )
}

export default Button
