import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LogoImage from '../../assets/images/logo.png'
import HandshakeImage from '../../assets/images/handshake.png'

import styles from './RegisterSupplier.module.scss'
import Header from '../../components/Header/Header'
import { toast } from 'react-toastify'
import cx from 'classnames'
import Select from 'react-select'

import AuthContext from '../../context/AuthContext'
import InputWithError from '../../components/InputWithError/InputWithError'
import { Oval } from 'react-loader-spinner'
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox'
import FormSelect from '../../components/FormSelect/FormSelect'
import { useEffect } from 'react'
import ProductAPI from '../../api/ProductAPI'
import OrganizationAPI from '../../api/OrganizationAPI'
import SupplierAPI from '../../api/SupplierAPI'
import Button from '../../components/Button/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import AuthAPI from '../../api/AuthAPI'
import HeaderLogo from '../../components/Header/HeaderLogo'

const RegisterSupplier = () => {
    const [form, setForm] = useState({ additional_attributes: [] })
    const [formErrors, setFormErrors] = useState({})
    const [spinnerActive, setSpinnerActive] = useState(false)

    const { user, login, logout } = useContext(AuthContext)

    const navigate = useNavigate()

    const countryChoicesQuery = useQuery(['country-choices'], AuthAPI.getCountryChoices, {
        select: (data) => {
            data = data?.data
            const list = {}
            const countryList = {}
            const dialCode = {}
            Object.keys(data).forEach((code) => {
                dialCode[`${data[code].name} ${data[code].dial_code}`] = code.dial_code
            })
            Object.keys(data).forEach((code) => {
                countryList[data[code].name] = code
            })
            list['countryList'] = countryList
            list['dialCode'] = dialCode
            list['data'] = data
            console.log(list)
            return list
        },
    })

    const productCatgoriesQuery = useQuery(
        ['product-categories'],
        () =>
            ProductAPI.getProductCategories({
                token: user.token,
            }),
        {
            select: (data) => {
                const categories = {}
                data?.data.forEach((category) => (categories[category.name] = category.slug))
                return categories
            },
        }
    )

    const createOrganizationMutation = useMutation(OrganizationAPI.createOrganization, {
        onError: (err) => {
            if (err.response.status === 400) {
                setFormErrors(err.response.data)
            } else {
                console.error(err)
                toast.error('Some error occured')
            }
        },

        onSuccess: () => {
            toast.success('Supplier created')
        },
    })

    const becomeSupplierMutation = useMutation(SupplierAPI.becomeSupplier, {
        onError: (err) => {
            console.error(err)
            toast.error(err.response.data?.message)
        },
        onSuccess: () => {
            toast.success('Supplier Registered')
            return navigate('/supplier/dashboard')
        },
    })

    const handleInputChange = (e) => {
        setForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        console.log('Submit button clicked')

        try {
            let res
            if (!user.organisation) {
                console.log('no organization\n creating one...')
                res = await createOrganizationMutation.mutateAsync({
                    token: user.token,
                    data: form,
                })
                console.log('creating org done')
            }

            console.log('becoming supplier...')
            await becomeSupplierMutation.mutateAsync({
                token: user.token,
                id: res?.data?.id || user.organizationData.id,
                data: {
                    'product-categories': [form.product_categories],
                },
            })
            console.log('becoming supplier done.')
            navigate('/login')
        } catch (err) {
            console.error(err)
        }
    }

    if (productCatgoriesQuery.isLoading) {
        return <Loader height="100vh" />
    }

    if (productCatgoriesQuery.isError) {
        toast.error('Failed to fetch product categories')
        return <Loader height="100vh" refresh />
    }

    if (countryChoicesQuery.isLoading) {
        return <Loader height="100vh" />
    }

    if (countryChoicesQuery.isError) {
        toast('Failed to fetch country list', {
            type: 'error',
        })
        return <Loader height="100vh" refresh />
    }

    return (
        <div className={styles.RegisterSupplier}>
            <div className={styles.RegisterSupplier__headerLogo}>
                <HeaderLogo onClick={() => navigate('/login')} />
            </div>
            <Header title="Supplier Registration" showLogout />
            <div className={cx(styles.RegisterSupplier__contentContainer, styles.container)}>
                <h1 className={styles.RegisterSupplier__title}>
                    <span className={styles.RegisterSupplier__title__main}>Add Supplier Details</span>
                </h1>
                {!user.organisation ? (
                    <form className={styles.RegisterSupplier__form} onSubmit={handleSubmit}>
                        <div className={cx(styles['row'], styles['justify-content-between'])}>
                            <div className={cx(styles['col-md-5'])}>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="name"
                                        type="text"
                                        placeholder="Supplier Name"
                                        value={form.name}
                                        errorMsg={formErrors.name}
                                        label="Supplier Name"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="address_line1"
                                        type="text"
                                        placeholder="Address Line 1"
                                        value={form.address_line1}
                                        errorMsg={formErrors.address_line1}
                                        label="Address Line 1"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="address_line2"
                                        type="text"
                                        placeholder="Address Line 2"
                                        value={form.address_line2}
                                        errorMsg={formErrors.address_line2}
                                        label="Address Line 2"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        value={form.city}
                                        errorMsg={formErrors.city}
                                        label="City"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormSelect
                                        name="country"
                                        label="Country"
                                        onChange={handleInputChange}
                                        placeholder="Country"
                                        value={form.country}
                                        options={countryChoicesQuery?.data.countryList}
                                        errorMsg={formErrors['country'] && formErrors['country'][0]}
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="state"
                                        type="text"
                                        placeholder="State/Province"
                                        value={form.state}
                                        errorMsg={formErrors.state}
                                        label="State/Province"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="postal_code"
                                        type="text"
                                        placeholder="Zip Code"
                                        value={form.postal_code}
                                        errorMsg={formErrors.postal_code}
                                        label="Zip Code"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormSelect
                                        onChange={handleInputChange}
                                        name="default_currency"
                                        type="text"
                                        placeholder="Default Currency"
                                        value={form.default_currency}
                                        options={{
                                            INR: 'INR',
                                            USD: 'USD',
                                        }}
                                        errorMsg={formErrors.default_currency}
                                        label="Default Currency"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="email"
                                        type="email"
                                        placeholder="Supplier Email"
                                        value={form.email}
                                        errorMsg={formErrors.email}
                                        label="Supplier Email"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="contact"
                                        type="text"
                                        placeholder="Phone"
                                        value={form.contact}
                                        errorMsg={formErrors.contact}
                                        label="Phone"
                                    />
                                </div>
                            </div>
                            <div className={cx(styles['col-md-5'])}>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="total_turnover"
                                        type="text"
                                        placeholder="Total Turnover"
                                        value={form.total_turnover}
                                        errorMsg={formErrors.total_turnover}
                                        label="Total Turnover"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="number_of_employees"
                                        type="number"
                                        placeholder="Number of Employees"
                                        value={form.number_of_employees}
                                        errorMsg={formErrors.number_of_employees}
                                        label="Number of Employees"
                                    />
                                </div>

                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormSelect
                                        options={productCatgoriesQuery.data}
                                        className={styles.RegisterSupplier__inputField}
                                        onChange={handleInputChange}
                                        name="product_categories"
                                        placeholder="Product Categories"
                                        label="Product Categories"
                                        value={form.product_categories}
                                    />
                                </div>

                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormCheckbox
                                        onChange={handleInputChange}
                                        name="agree_with_terms"
                                        label="I have read and agree with the Terms and Use"
                                        id="RegisterSupplier_agree_with_terms"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormCheckbox
                                        onChange={handleInputChange}
                                        name="agree"
                                        label="I hereby agree that Metics will make parts of my (company) information 
                                    accessible to other users and the public based on my role within the  platform 
                                    and the applicable profile visibility settings.
                                    Please see the Privacy Statement to learn how we process personal data."
                                        id="RegisterSupplier_agree"
                                    />
                                </div>
                                <Button
                                    className={styles.RegisterSupplier__formBtn}
                                    text="Submit"
                                    type="submit"
                                    loading={createOrganizationMutation.isLoading || becomeSupplierMutation.isLoading}
                                />
                            </div>
                        </div>
                    </form>
                ) : (
                    <form className={styles.RegisterSupplier__form} onSubmit={handleSubmit}>
                        <div className={cx(styles['row'], styles['justify-content-between'])}>
                            <div className={cx(styles['col-md-5'])}>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <InputWithError
                                        onChange={handleInputChange}
                                        name="name"
                                        type="text"
                                        placeholder="Supplier Name"
                                        value={user.organizationData.name}
                                        label="Supplier Name"
                                        errorMsg={formErrors.name}
                                        disabled={true}
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormSelect
                                        options={productCatgoriesQuery.data}
                                        className={styles.RegisterSupplier__inputField}
                                        value={form.product_categories}
                                        isMulti
                                        onChange={handleInputChange}
                                        name="product_categories"
                                        placeholder="Product Categories"
                                        label="Product Categories"
                                    />
                                </div>

                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormCheckbox
                                        onChange={handleInputChange}
                                        name="agree_with_terms"
                                        label="I have read and agree with the Terms and Use"
                                        id="RegisterSupplier_agree_with_terms"
                                    />
                                </div>
                                <div className={styles.RegisterSupplier__inputField}>
                                    <FormCheckbox
                                        onChange={handleInputChange}
                                        name="agree"
                                        label="I hereby agree that Metics will make parts of my (company) information 
                                accessible to other users and the public based on my role within the  platform 
                                and the applicable profile visibility settings.
                                Please see the Privacy Statement to learn how we process personal data."
                                        id="RegisterSupplier_agree"
                                    />
                                </div>
                                <Button
                                    className={styles.RegisterSupplier__formBtn}
                                    text="Submit"
                                    type="submit"
                                    loading={createOrganizationMutation.isLoading || becomeSupplierMutation.isLoading}
                                />
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default RegisterSupplier
