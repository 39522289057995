// stylesheet
import styles from './OrganizationSupplier.module.scss'

// assets
import SeaIcon from '../../assets/icons/sea.svg'

// context
import AuthContext from '../../context/AuthContext'

// api
import OrganizationAPI from '../../api/OrganizationAPI'

// packages
import { useNavigate } from 'react-router-dom'
import { useState, useContext, useRef } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

// components
import Button from '../../components/Button/Button'
import DropdownCard from '../../components/DropdownCard/DropdownCard'
import FormMultiSelect from '../../components/FormMultiSelect/FormMultiSelect'
import InputWithError from '../../components/InputWithError/InputWithError'
import Loader from '../../components/Loader/Loader'
import Modal from '../../components/Modal/Modal'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import SupplierAPI from '../../api/SupplierAPI'

const OrganizationSupplier = () => {
    const { user } = useContext(AuthContext)

    const [showDropdown, setShowDropdown] = useState(false)
    const dropdownToggleRef = useRef(null)

    const [searchQuery, setSearchQuery] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [modalForm, setModalForm] = useState({})
    const [modalFormErrors, setModalFormErrors] = useState({})

    const [showAuthorizedSellerModal, setShowAuthorizedSellerModal] = useState(false)
    const [authorizedSellerModalForm, setAuthorizedSellerModalForm] = useState(false)
    const [authorizedSellerModalFormErrors, setAuthorizedSellerModalFormErrors] = useState(false)

    const navigate = useNavigate()

    //============
    // React Query
    //============
    const modalSubmitMutation = useMutation(OrganizationAPI.inviteSupplier, {
        onError: (err) => {
            if (err.response.status === 400) {
                return setModalFormErrors(err.response.data)
            }
            toast.error(err.response.data?.message)
            handleModalFormErrorsClear()
        },
        onSuccess: () => {
            toast.success('Supplier has been invited')
            handleModalFormClear()
            handleModalFormErrorsClear()
            handleModalClose()
        },
    })

    const authorizedSellerModalSubmitMutation = useMutation(OrganizationAPI.addAuthorizedSeller, {
        onError: (err) => toast.error(err.response.data?.message),
        onSuccess: () => {
            handleAuthorizedSellerModalFormClear()
            handleAuthorizedSellerModalClose()
            handleDropdownClose()
            getAuthorizedSellersQuery.refetch()
        },
    })

    const getSellersQuery = useQuery(['sellers'], () => OrganizationAPI.getAllSellers(user.token), {
        select: (data) => {
            const sellers = {}
            data.data?.map((seller) => (sellers[seller.id] = seller.name))
            return sellers
        },
    })

    const getAuthorizedSellersQuery = useQuery(
        ['authorized-sellers'],
        () => OrganizationAPI.getAuthorizedSellers({ token: user.token, orgId: user.organisation }),
        {
            select: (data) =>
                data?.data.map((seller) => {
                    // console.log(seller)
                    return {
                        ID: seller.id,
                        Name: seller.name,
                        Email: seller.email,
                        Contact: seller.contact,
                    }
                }),
        }
    )
    const filteredSellers = getAuthorizedSellersQuery.data?.filter((seller) => {
        const searchTerm = searchQuery.toLowerCase()
        return (
            seller.ID.toString().includes(searchTerm) ||
            seller.Name.toLowerCase().includes(searchTerm) ||
            seller.Email.toLowerCase().includes(searchTerm) ||
            seller.Contact.toLowerCase().includes(searchTerm)
        )
    })

    //==========
    // Handlers
    //==========
    const handleDropdownClose = () => setShowDropdown(false)
    const handleDropdownOpen = () => setShowDropdown(true)
    const handleDropdownToggle = () => setShowDropdown((prev) => !prev)

    const handleModalOpen = () => setShowModal(true)
    const handleModalClose = () => setShowModal(false)
    const handleModalToggle = () => setShowModal((prev) => !prev)
    const handleModalChange = (e) => setModalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    const handleModalFormClear = () => setModalForm({})
    const handleModalFormErrorsClear = () => setModalFormErrors({})
    const handleModalSubmit = (e) => {
        e.preventDefault()
        modalSubmitMutation.mutate({ token: user.token, data: modalForm })
    }

    const handleAuthorizedSellerModalOpen = () => setShowAuthorizedSellerModal(true)
    const handleAuthorizedSellerModalClose = () => setShowAuthorizedSellerModal(false)
    const handleAuthorizedSellerModalToggle = () => setShowAuthorizedSellerModal((prev) => !prev)
    const handleAuthorizedSellerModalChange = (e) =>
        setAuthorizedSellerModalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    const handleAuthorizedSellerModalFormClear = () => setAuthorizedSellerModalForm({})
    const handleAuthorizedSellerModalFormErrorsClear = () => setAuthorizedSellerModalFormErrors({})
    const handleAuthorizedSellerModalSubmit = async (e) => {
        e.preventDefault()
        for (let id of authorizedSellerModalForm.sellers) {
            await authorizedSellerModalSubmitMutation.mutateAsync({
                token: user.token,
                orgId: user.organisation,
                data: { 'organisation-id': id },
            })
        }
        toast.success('Suppliers has been added as authorized seller')
        handleAuthorizedSellerModalFormErrorsClear()
        handleAuthorizedSellerModalFormClear()
        handleAuthorizedSellerModalClose()
        handleDropdownClose()
    }

    const handleSearchChange = (query) => {
        setSearchQuery(query)
    }

    const navigateToDetailPage = (id) => {
        navigate(`/organization/supplier/details/${id} `)
    }

    return (
        <OrganizationLayout title="Supplier Management" active="supplier">
            <>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="All"
                            value={getAuthorizedSellersQuery?.data?.length}
                        />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Accepted" value="0" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Draft" value="0" />
                    </div>
                </div>
                <br />
                <Table>
                    <TableControls>
                        <div className={styles.Table__tableControls}>
                            <div>
                                <Button text="Export CSV" color="#0E2C24" />
                            </div>
                            <div className={styles.Table__row}>
                                <TableControlSearch onSearchChange={handleSearchChange} />
                                <div className={styles.Table__btnWrapper}>
                                    <Button
                                        reference={dropdownToggleRef}
                                        text="Add New Supplier"
                                        onClick={handleDropdownToggle}
                                    />
                                    <DropdownCard
                                        active={showDropdown}
                                        close={setShowDropdown}
                                        parentRef={dropdownToggleRef}
                                    >
                                        <li
                                            className={styles.List}
                                            style={{ fontSize: '13px' }}
                                            onClick={handleModalOpen}
                                        >
                                            Invite Supplier
                                        </li>
                                        <li
                                            className={styles.List}
                                            style={{ fontSize: '13px' }}
                                            onClick={handleAuthorizedSellerModalOpen}
                                        >
                                            Add Authorized Sellers
                                        </li>
                                    </DropdownCard>
                                </div>
                            </div>
                        </div>
                    </TableControls>
                    <TableContent
                        hoverable={
                            !(
                                getSellersQuery.isError ||
                                getSellersQuery.isLoading ||
                                getAuthorizedSellersQuery.isError ||
                                getAuthorizedSellersQuery.isLoading
                            )
                        }
                    >
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getSellersQuery.isError ||
                            getSellersQuery.isLoading ||
                            getAuthorizedSellersQuery.isError ||
                            getAuthorizedSellersQuery.isLoading ? (
                                <tr>
                                    <td colSpan="4">
                                        <Loader
                                            refresh={getAuthorizedSellersQuery.isError || getSellersQuery.isError}
                                            height="30vh"
                                        />
                                    </td>
                                </tr>
                            ) : filteredSellers.length === 0 ? (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                        No suppliers found
                                    </td>
                                </tr>
                            ) : (
                                filteredSellers?.map((seller, id) => (
                                    <tr key={id} onClick={() => navigateToDetailPage(seller.ID)}>
                                        <td>{id + 1}</td>
                                        <td>{seller.Name}</td>
                                        <td>{seller.Email}</td>
                                        <td>{seller.Contact}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </TableContent>
                </Table>
                {showModal && (
                    <Modal title="Invite Supplier" onClose={handleModalClose}>
                        <form onSubmit={handleModalSubmit}>
                            <InputWithError
                                name="name"
                                type="text"
                                label="Name"
                                placeholder="Enter name of supplier"
                                onChange={handleModalChange}
                                value={modalForm.name}
                                errorMsg={modalFormErrors.name}
                            />
                            <InputWithError
                                name="email"
                                type="email"
                                label="Email"
                                placeholder="Enter email"
                                onChange={handleModalChange}
                                value={modalForm.email}
                                errorMsg={modalFormErrors.email}
                            />
                            <br />
                            <Button text="Submit" type="submit" loading={modalSubmitMutation.isLoading} />
                        </form>
                    </Modal>
                )}

                {showAuthorizedSellerModal && (
                    <Modal title="Add Authorized Seller" onClose={handleAuthorizedSellerModalClose}>
                        <form onSubmit={handleAuthorizedSellerModalSubmit}>
                            <div className={styles.SellerModal__selectWrapper}>
                                <FormMultiSelect
                                    data={getSellersQuery.data}
                                    name="sellers"
                                    placeholder="Select sellers"
                                    onChange={handleAuthorizedSellerModalChange}
                                />
                            </div>
                            <Button
                                text="Submit"
                                type="submit"
                                loading={authorizedSellerModalSubmitMutation.isLoading}
                            />
                        </form>
                    </Modal>
                )}
            </>
        </OrganizationLayout>
    )
}

export default OrganizationSupplier
