import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'
import Verification from './pages/Verification/Verification'
import OrganizationDashboardHome from './pages/OrganizationDashboardHome/OrganizationDashboardHome'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import { AuthProvider } from './context/AuthContext'
import ForgotPassword from './pages/Login/ForgotPassword'
import OrganizationDashboardRFQ from './pages/OrganizationDashboardRFQ/OrganizationDashboardRFQ'
import { SidebarProvider } from './context/SidebarContext'
import OrganizationUserManagement from './pages/OrganizationUserManagement/OrganizationUserManagement'
import SupplierDashboard from './pages/SupplierDashboard/SupplierDashboard'
import OrganizationSupplierDetails from './pages/OrganizationSupplierDetails/OrganizationSupplierDetails'
import OrganizationSupplier from './pages/OrganizationSupplier/OrganizationSupplier'
import Client from './pages/Client/Client'
import ClientDetails from './pages/Client/ClientDetails/ClientDetails'
import CreateOrganization from './pages/CreateOrganization/CreateOrganization'
import ProtectedRoute from './components/ProtectedRoute'
import SupplierClientMgmt from './pages/SupplierClientMgmt/SupplierClientMgmt'
import SupplierClientDetails from './pages/SupplierClientDetails/SupplierClientDetails'
import SupplierProductCatalogue from './pages/SupplierProductCatalogue/SupplierProductCatalogue'
import SupplierProductCatalogueAdd from './pages/SupplierProductCatalogueAdd/SupplierProductCatalogueAdd'
import RegisterSupplier from './pages/RegisterSupplier/RegisterSupplier'
import SupplierRFQ from './pages/SupplierRFQ/SupplierRFQ'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SupplierProductRequest from './pages/SupplierProductRequest/SupplierProductRequest'
import SupplierProductRequests from './pages/SupplierProductRequests/SupplierProductRequests'
import SupplierProductBids from './pages/SupplierProductBids/SupplierProductBids'
import SupplierProductCatalogueView from './pages/SupplierProductCatalogueView/SupplierProductCatalogueView'
import SupplierProductBidsView from './pages/SupplierProductBidsView/SupplierProductBidsView'
import OrganizationRFQDetail from './pages/OrganizationDashboardRFQ/OrganizarionRFQDetails/OrganizationRFQDetails'
import Profile from './pages/Settings/Profile'
import Org from './pages/Settings/Org'
import Confirm from './pages/VerificationConfirm/VerificationConfirm'
import '@fortawesome/fontawesome-svg-core/styles.css'
import OrganizationBidDetails from './pages/OrganizationDashboardRFQ/OrganizarionRFQDetails/OrganizationBidDetails/OrganizationBidDetails'
import OrganizationBidCompare from './pages/OrganizationDashboardRFQ/OrganizarionRFQDetails/OrganizationBidDetails/OrganizationBidCompare/OrganizationBidCompare'

import SupplierDocuments from './pages/SupplierDocuments/SupplierDocuments'
import OrganizationSupplierDetailsNew from './pages/OrganizationSupplierDetails/OrganizationSupplierDetailsNew'
import SupplierProductCatalogueEdit from './pages/SupplierProductCatalogueEdit/SupplierProductCatalogueEdit'
import OrganizationUserDetails from './pages/OrganizationUserDetails/OrganizationUserDetails'
import OrganizationCreateUser from './pages/OrganizationCreateUser/OrganizationCreateUser'
import OrganizationEditRFQ from './pages/OrganizationCreateRFQ/OrganizationEditRFQ'
import SupSettingsPage from './pages/Settings/SupSettingsPage'
import PurchaseOrder from './pages/PurchaseOrder/PurchaseOrder'
import Sup from './pages/Settings/Sup'
import Products from './pages/Products/Products'
import ProductDetails from './pages/ProductDetails/ProductDetails'
import SupplierOrder from './pages/SupplierOrders/SupplierOrder'
import OrganizationEditUser from './pages/OrganizationCreateUser/OrganizationEditUser'
import PurchaseOrderDetails from './pages/PurchaseOrder/PurchaseOrderDetails'
import SupplierOrdersDetails from './pages/SupplierOrders/SupplierOrdersDetails'
import OrganizationCreateRFQ from './pages/OrganizationCreateRFQ/OrganizationCreateRFQ1'
import SupplierUpdates from './pages/SupplierUpdates/SupplierUpdates'
import OrganizationUpdates from './pages/OrganizationUpdates/OrganizationUpdates'
import SupplierOrg from './pages/SupplierOrganization/SupplierOrg'
import AddTemplate from './pages/SupplierOrganization/AddTemplate'
import Templates from './pages/SupplierOrganization/Templates'
import ProductEnquiry from './pages/ProductEnquiry/ProductEnquiry'
import { CartProvider } from './context/EnquiryProvider'
import FilteredProducts from './pages/Filteredproducts'

const queryClient = new QueryClient()

const App = () => {
    return (
        <>
            <ToastContainer />
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <SidebarProvider>
                        <Router>
                            <Routes>
                                <Route element={<ProtectedRoute />}>
                                    <Route path="/organization/dashboard" element={<OrganizationDashboardHome />} />
                                    <Route path="/organization/updates" element={<OrganizationUpdates />} />
                                    <Route path="/organization/rfq" element={<OrganizationDashboardRFQ />} />
                                    <Route path="/organization/rfq/create_new" element={<OrganizationCreateRFQ />} />
                                    <Route path="/organization/rfq/edit/:id" element={<OrganizationEditRFQ />} />
                                    <Route
                                        path="/organization/rfq/detail/:auctionId"
                                        element={<OrganizationRFQDetail />}
                                    />
                                    <Route
                                        path="/organization/rfq/detail/:auctionId/compare"
                                        element={<OrganizationBidCompare />}
                                    />
                                    <Route
                                        path="/organization/rfq/detail/bids/:bidHeaderId"
                                        element={<OrganizationBidDetails />}
                                    />
                                    <Route path="/organization/users" element={<OrganizationUserManagement />} />
                                    <Route path="/organization/users/create" element={<OrganizationCreateUser />} />
                                    <Route path="/organization/users/edit/:id" element={<OrganizationEditUser />} />
                                    <Route
                                        path="/organization/users/details/:id"
                                        element={<OrganizationUserDetails />}
                                    />
                                    <Route path="/organization/supplier" element={<OrganizationSupplier />} />
                                    <Route path="/organization/purchaseorder" element={<PurchaseOrder />} />
                                    <Route path="/organization/purchaseorder/:id" element={<PurchaseOrderDetails />} />
                                    <Route
                                        path="/organization/supplier/details/:id"
                                        element={<OrganizationSupplierDetailsNew />}
                                    />

                                    <Route path="/organization/client" element={<Client />} />
                                    <Route path="/organization/client/details" element={<ClientDetails />} />
                                    <Route path="/supplier/dashboard" element={<SupplierDashboard />} />
                                    <Route path="/supplier/updates" element={<SupplierUpdates />} />
                                    <Route path="/supplier/rfq" element={<SupplierRFQ />} />
                                    <Route path="/supplier/purchaseorder" element={<SupplierOrder />} />
                                    <Route path="/supplier/purchaseorder/:id" element={<SupplierOrdersDetails />} />

                                    <Route path="/supplier/clients" element={<SupplierClientMgmt />} />
                                    <Route path="/supplier/clients/detail/:id" element={<SupplierClientDetails />} />
                                    <Route path="/supplier/products" element={<SupplierProductCatalogue />} />
                                    <Route path="/supplier/products/add" element={<SupplierProductCatalogueAdd />} />
                                    <Route
                                        path="/supplier/products/edit/:id"
                                        element={<SupplierProductCatalogueEdit />}
                                    />
                                    <Route path="supplier/organization" element={<SupplierOrg />} />
                                    <Route path="supplier/organization/temp" element={<Templates />} />

                                    <Route path="supplier/organization/temp/add" element={<AddTemplate />} />

                                    <Route path="/supplier/products/:id" element={<SupplierProductCatalogueView />} />
                                    <Route path="/supplier/requests" element={<SupplierProductRequests />} />
                                    <Route path="/supplier/requests/:id" element={<SupplierProductRequest />} />
                                    <Route path="/supplier/bids" element={<SupplierProductBids />} />
                                    <Route path="/supplier/bids/:id" element={<SupplierProductBidsView />} />
                                    <Route path="/supplier/documents" element={<SupplierDocuments />} />
                                    <Route path="/register/organization" element={<CreateOrganization />} />
                                    <Route path="/register/supplier" element={<RegisterSupplier />} />
                                    <Route path="/organization/settings/org" element={<Org />} />
                                    <Route path="organization/settings/profile" element={<Profile />} />
                                    <Route path="supplier/settings/profile" element={<SupSettingsPage />} />
                                    <Route path="supplier/settings/sup" element={<Sup />} />
                                </Route>

                                <Route path="/" element={<Login />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/verification" element={<Verification />} />
                                <Route path="/confirm" element={<Confirm />} />

                                <Route path="/products/:id" element={<Products />} />
                                <Route path="/products/view/:id" element={<ProductDetails />} />
                                <Route path="/products/enquiry" element={<ProductEnquiry />} />
                                <Route path="/products/category/:categoryId" element={<FilteredProducts />} />
                            </Routes>
                        </Router>
                    </SidebarProvider>
                </AuthProvider>
            </QueryClientProvider>
        </>
    )
}

export default App
