import React, { useState, useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import SideSub from './SideSub'
import styles from './SupSettingsPage.module.scss'
import InputWithError from '../../components/InputWithError/InputWithError'
import { FaAngleDown } from 'react-icons/fa'
import { useMutation, useQuery } from '@tanstack/react-query'
import OrganizationAPI from '../../api/OrganizationAPI'
import { toast } from 'react-toastify'
import AuthAPI from '../../api/AuthAPI'

function Profile() {
    const { user } = useContext(AuthContext)
    // console.log(user)
    const [collapse, setCollapse] = useState('profile')
    const [isEditing, setIsEditing] = useState(false)

    const [profileData, setProfileData] = useState({
        username: user.username,
        email: user.email,
        phone: user.phone,
    })
    const [password, setPassword] = useState({})
    const [passwordError, setPasswordError] = useState({})

    const [profileDataErrors, setProfileDataErrors] = useState({})

    const updateProfile = useMutation(AuthAPI.userUpdate, {
        onSuccess: () => {
            toast.success('Profile updated successfully')
            setProfileDataErrors({})
        },
        onError: (err) => {
            console.log(err.response)
            setProfileDataErrors(err.response.data)
            toast.error(err.response.data?.message)
        },
    })

    const changePassword = useMutation((data) => OrganizationAPI.changeProfilePassword({ token: user.token, data }), {
        onSuccess: () => {
            toast.success('Password changed successfully')
            setPassword({})
            setPasswordError({})
        },
        onError: (err) => {
            console.log(err.response)
            if (err.response.status === 400) {
                toast.error(err.response.data?.old_password[0])
            }
            toast.error(err.response.data?.message)
        },
    })

    const handleEditClick = (e) => {
        e.preventDefault()
        setIsEditing(true) // Enable editing mode
    }

    const handleCollapse = (value) => {
        if (collapse === value) {
            setCollapse('')
        } else {
            setCollapse(value)
        }
    }

    const handleSubmitProfile = async (e) => {
        e.preventDefault()
        console.log(profileData)
        await updateProfile.mutate({
            token: user.token,
            data: profileData,
        })
        setIsEditing(false)
    }

    const handlePasswordSubmit = async (e) => {
        e.preventDefault()
        setPasswordError({})
        if (password.new_password !== password.confirm_password) {
            setPasswordError({ ...passwordError, confirm_password: 'New passwords do not match' })
            toast.error(passwordError.confirm_password)
            return
        }
        await changePassword.mutate({
            old_password: password.current_password,
            new_password: password.new_password,
        })
    }

    return (
        <OrganizationLayout active="settings" title="Settings">
            <div className={styles.Settings}>
                <div>
                    <SideSub />
                </div>
                <div className={styles.rightContainer}>
                    <h1>Profile Settings</h1>
                    <Card className={styles.Card}>
                        <div onClick={() => handleCollapse('profile')} className={styles.Settings__Header}>
                            <h3 className={styles.Settings__Title}>Profile</h3>
                            <FaAngleDown size={15} className={`${collapse === 'profile' && styles.Settings__Icon}`} />
                        </div>
                        {collapse === 'profile' && (
                            <form className={styles.Settings__Content} onSubmit={handleSubmitProfile}>
                                <InputWithError
                                    label={'Username'}
                                    name={'username'}
                                    placeholder={'Enter username'}
                                    type={'text'}
                                    disabled={!isEditing}
                                    value={profileData.username}
                                    onChange={(e) => setProfileData({ ...profileData, username: e.target.value })}
                                    errorMsg={profileDataErrors['username'] && profileDataErrors['username'][0]}
                                />
                                <InputWithError
                                    label={'Email'}
                                    name={'email'}
                                    placeholder={'Enter email'}
                                    type={'email'}
                                    disabled={!isEditing}
                                    value={profileData.email}
                                    onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                                    errorMsg={profileDataErrors['email'] && profileDataErrors['email'][0]}
                                />
                                <InputWithError
                                    label={'Phone Number'}
                                    name={'phone'}
                                    placeholder={'Enter phone number'}
                                    type={'text'}
                                    disabled={!isEditing}
                                    value={profileData.phone}
                                    onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
                                    errorMsg={profileDataErrors['phone'] && profileDataErrors['phone'][0]}
                                />
                                <div className={styles.Settings__Btn}>
                                    {isEditing ? (
                                        <Button type="submit" text="Save changes" loading={updateProfile.isLoading} />
                                    ) : (
                                        <Button type="button" text="Edit" onClick={handleEditClick} />
                                    )}
                                </div>
                            </form>
                        )}
                    </Card>
                    <Card className={styles.Card}>
                        <div onClick={() => handleCollapse('Change password')} className={styles.Settings__Header}>
                            <h3 className={styles.Settings__Title}>Change password</h3>
                            <FaAngleDown
                                size={15}
                                className={`${collapse === 'Change password' && styles.Settings__Icon}`}
                            />
                        </div>
                        {collapse === 'Change password' && (
                            <form className={styles.Settings__Content} onSubmit={handlePasswordSubmit}>
                                <InputWithError
                                    label={'Current password'}
                                    name={'current_password'}
                                    placeholder={'Enter current password'}
                                    type={'password'}
                                    value={password.current_password}
                                    onChange={(e) => setPassword({ ...password, current_password: e.target.value })}
                                    errorMsg={passwordError.current_password}
                                />
                                <InputWithError
                                    label={'New password'}
                                    name={'new_password'}
                                    placeholder={'Enter new password'}
                                    type={'password'}
                                    value={password.new_password}
                                    onChange={(e) => setPassword({ ...password, new_password: e.target.value })}
                                    errorMsg={passwordError.confirm_password}
                                />
                                <InputWithError
                                    label={'Confirm password'}
                                    name={'confirm_password'}
                                    placeholder={'Enter confirm password'}
                                    type={'password'}
                                    value={password.confirm_password}
                                    onChange={(e) => setPassword({ ...password, confirm_password: e.target.value })}
                                    errorMsg={passwordError.confirm_password}
                                />
                                <div className={styles.Settings__Btn}>
                                    <Button type="submit" text="Save changes" loading={changePassword.isLoading} />
                                </div>
                            </form>
                        )}
                    </Card>
                </div>
            </div>
        </OrganizationLayout>
    )
}

export default Profile
