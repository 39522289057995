import React from 'react'
import LogoImage from '../../assets/images/Metics-blue 1.png'
import styles from './HeaderLogo.module.scss'

function HeaderLogo({ onClick = () => {} }) {
    return (
        <div className={styles.HeaderLogo}>
            <img src={LogoImage} alt="logo" width={160} onClick={onClick} />
        </div>
    )
}

export default HeaderLogo
