import { useEffect, useRef } from 'react'
import cx from 'classnames'
import styles from './DropdownCard.module.scss'

const DropdownCard = ({ active = false, children, close, parentRef }) => {
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                parentRef.current &&
                !parentRef.current.contains(event.target)
            ) {
                close(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [parentRef, close])

    return (
        <ul ref={dropdownRef} className={cx(styles.Card__dropdown, active && styles.Card__dropdown_active)}>
            {children}
        </ul>
    )
}

export default DropdownCard
