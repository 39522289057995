import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import SupplierAPI from '../../src/api/SupplierAPI'
import Loader from '../components/Loader/Loader'
import Table from '../../src/components/Table/Table'
import Button from '../components/Button/Button'
import TableContent from '../components/Table/TableContent/TableContent'
import TableControls from '../components/Table/TableControls/TableControls'
import styles from './FilteredProducts.module.scss'

const FilteredProducts = () => {
    const { categoryId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { supplierId } = location.state || {}
    const [filteredProducts, setFilteredProducts] = useState([])

    const { mutate: fetchFilteredProducts, isLoading } = useMutation(
        ({ supplierId, categoryId }) => SupplierAPI.getFilteredProduct({ id: supplierId, product_category_id: categoryId }),
        {
            onSuccess: (data) => {
                const products = data?.data.products.map((product, index) => ({
                    id: product.id,
                    slNo: index + 1,
                    name: product.name,
                    price: product.list_price_per_unit,
                    email: product.email,
                    product_category: product.product_sub_category,
                    status: '',
                    enquiry: '',
                }))
                setFilteredProducts(products)
            },
        }
    )

    useEffect(() => {
        if (supplierId && categoryId) {
            fetchFilteredProducts({ supplierId, categoryId })
        }
    }, [supplierId, categoryId, fetchFilteredProducts])

    const ListTableBody = React.memo(({ data }) => (
        <>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Product Category</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((row) => (
                    <tr key={row.id} onClick={() => navigate(`/products/view/${row.id}`)}>
                        <td>{row.slNo}</td>
                        {['name', 'price', 'product_category'].map((field) => (
                            <td key={field}>{row[field]}</td>
                        ))}
                        <td>
                            
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    ))

    return (
        <div className={styles.Container}>
            {isLoading ? (
                <Loader />
            ) : (
                <Table>
                    <TableControls>
                        <div className={styles.Products__top}>
                         
                        </div>
                    </TableControls>
                    <TableContent hoverable>
                        <ListTableBody data={filteredProducts} />
                    </TableContent>
                </Table>
            )}
        </div>
    )
}

export default FilteredProducts
