import React from 'react'
import styles from './Sup.module.scss'
import Card from '../../components/Card/Card'
import InputWithError from '../../components/InputWithError/InputWithError'
import Button from '../../components/Button/Button'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import SideSub from './SideSub'
import Filter from '../../components/Filter/Filter'
import ProductAPI from '../../api/ProductAPI'
import Loader from '../../components/Loader/Loader'
import { toast } from 'react-toastify'
import { useContext, useState, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthContext } from '../../context/AuthContext'
import FormMultiSelect from '../../components/FormMultiSelect/FormMultiSelect'
import SupplierAPI from '../../api/SupplierAPI'
import TableContent from '../../components/Table/TableContent/TableContent'
import Table from '../../components/Table/Table'
import { FaTrash } from 'react-icons/fa'
import TableButton from '../../components/Table/TableButton/TableButton'

const Sup = () => {
    const { user } = useContext(AuthContext)

    const [supplierDetails, setSupplierDetails] = useState({})
    const [profileDataErrors, setProfileDataErrors] = useState({})
    const [filter, setFilter] = useState('Categories')
    const [selectedCategories, setSelectedCategories] = useState([])

    const productCategoriesQuery = useQuery(['product-categories'], () =>
        ProductAPI.getProductCategories({
            token: user.token,
        })
    )

    const getSupplierCategories = useQuery(['supplier-categories'], () =>
        SupplierAPI.getSupplierCategories({
            token: user.token,
        })
    )
    const categories = getSupplierCategories?.data?.data?.selling_categories
    const updateCategoriesMutation = useMutation(SupplierAPI.updateSupplier, {
        onSuccess: () => {
            toast.success('Categories updated successfully')
            getSupplierCategories.refetch()
        },
        onError: (error) => {
            setProfileDataErrors(error.response.data)
            toast.error('Error updating categories')
        },
    })

    const deleteCategoryMutation = useMutation(SupplierAPI.deleteSupplier, {
        onSuccess: () => {
            toast.success('Deletion successful')
            getSupplierCategories.refetch()
        },
        onError: () => {
            toast.error('Error deleting category')
        },
    })

    const productCategories = useMemo(() => {
        if (!categories) {
            return {}
        }

        // Filter out categories that already exist in 'categories'
        const filteredCategories = productCategoriesQuery?.data?.data?.filter(
            (item) => !categories.find((cat) => cat.name === item.name)
        )

        // Transform the array into an object
        return filteredCategories?.reduce((acc, category) => {
            acc[category.slug] = category.name // Use category.name as both the key and the value
            return acc
        }, {})
    }, [productCategoriesQuery.data, categories])

    const handleCategoriesChange = (selectedIndices) => setSelectedCategories(selectedIndices)
    const handleFilterChange = (value) => setFilter(value)

    const handleCategoriesSubmit = async (e) => {
        e.preventDefault()

        await updateCategoriesMutation.mutate({
            token: user.token,
            id: user.organizationData.id,
            data: { product_categories: selectedCategories },
        })
        setSelectedCategories([])
    }

    const handleSubmitSupplierDetails = (e) => {
        e.preventDefault()
        console.log(supplierDetails)
        setProfileDataErrors({})
    }
    const handleDelete = async (slug) => {
        await deleteCategoryMutation.mutate({
            token: user.token,
            id: user.organizationData.id,
            data: { product_categories: [slug] },
        })
    }
    let content
    switch (filter) {
        case 'Categories':
            content = (
                <div className={styles.Tab__Content}>
                    <Card>
                        <form onSubmit={handleCategoriesSubmit}>
                            <div className={styles.Categories}>
                                <div className={styles.multi}>
                                    {productCategoriesQuery.data && (
                                        <FormMultiSelect
                                            data={productCategories}
                                            label="Add Categories"
                                            name="categories"
                                            placeholder="Select Categories"
                                            type="text"
                                            onChange={(e) => handleCategoriesChange(e.target.value)}
                                            value={selectedCategories} // Pass the selected options
                                        />
                                    )}
                                </div>
                                <div className={styles.Btn}>
                                    <Button text="Update" type="submit" loading={updateCategoriesMutation.isLoading} />
                                </div>
                            </div>
                        </form>
                        <div className={styles.Categories__Registered}>
                            <h3 className={styles.Categories__Title}>Registered Under</h3>
                            <Table>
                                <TableContent>
                                    <thead>
                                        <tr>
                                            <th>SL No</th>
                                            <th>Category Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.Body}>
                                        {categories?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <TableButton>
                                                    <FaTrash
                                                        onClick={() => {
                                                            handleDelete(item.slug)
                                                        }}
                                                    />
                                                </TableButton>
                                            </tr>
                                        ))}
                                    </tbody>
                                </TableContent>
                            </Table>
                        </div>
                    </Card>
                </div>
            )
            break
        case 'Supplier':
            content = (
                <div className={styles.Tab__Content}>
                    <Card>
                        <form className={styles.Settings__Content} onSubmit={handleSubmitSupplierDetails}>
                            <InputWithError
                                label={'Name'}
                                name={'name'}
                                placeholder={'Enter name'}
                                type={'text'}
                                value={supplierDetails.name}
                                onChange={(e) => setSupplierDetails({ ...supplierDetails, name: e.target.value })}
                                errorMsg={profileDataErrors['name'] && profileDataErrors['name'][0]}
                            />
                            <InputWithError
                                label={'Email'}
                                name={'email'}
                                placeholder={'Enter email'}
                                type={'email'}
                                value={supplierDetails.email}
                                onChange={(e) => setSupplierDetails({ ...supplierDetails, email: e.target.value })}
                                errorMsg={profileDataErrors['email'] && profileDataErrors['email'][0]}
                            />
                            <InputWithError
                                label={'Phone Number'}
                                name={'phone_number'}
                                placeholder={'Enter phone number'}
                                type={'text'}
                                value={supplierDetails.phone_number}
                                onChange={(e) =>
                                    setSupplierDetails({ ...supplierDetails, phone_number: e.target.value })
                                }
                                errorMsg={profileDataErrors['phone_number'] && profileDataErrors['phone_number'][0]}
                            />
                            <div className={styles.Btn}>
                                <Button type="submit" text="Save changes" />
                            </div>
                        </form>
                    </Card>
                </div>
            )
            break
        default:
            break
    }

    return (
        <SupplierLayout active="settings" title="Settings">
            <div className={styles.Settings}>
                <div>
                    <SideSub isSupplier={true} />
                </div>
                <div className={styles.rightContainer}>
                    <Filter
                        className={styles.Filter}
                        filter={filter}
                        options={['Categories', 'Supplier']}
                        onChange={handleFilterChange}
                    />
                    {productCategoriesQuery.isLoading && <Loader />}
                    {productCategoriesQuery.isError && <Loader refresh />}
                    <div>{content}</div>
                </div>
            </div>
        </SupplierLayout>
    )
}

export default Sup
