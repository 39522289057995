import { useState } from 'react'
import Button from '../../../components/Button/Button'
import FormMultiSelect from '../../../components/FormMultiSelect/FormMultiSelect'
import FormSelect from '../../../components/FormSelect/FormSelect'
import Modal from '../../../components/Modal/Modal'
import styles from './FocalPointModal.module.scss'
import cx from 'classnames'

const FocalPointModal = ({ onClose, data, loader, handleSubmit = () => {} }) => {
    const [form, setForm] = useState({})
    const handleChange = (e) => setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))

    const handleFormSubmit = (e) => {
        e.preventDefault()

        const staffs = []
        form.staffs?.forEach((email) => {
            const staff = data.find((staff) => staff.email === email)
            staff.role = form.role
            staffs.push(staff)
        })
        console.log(staffs)
        handleSubmit(staffs)
        handleFormClear()
    }

    const handleFormClear = () => setForm({})

    const filterData = (data) => {
        console.log(data)
        return data.reduce((prev, curr) => {
            prev[curr.email] = curr.first_name
            return prev
        }, {})
    }

    return (
        <Modal title="Add Focal Point" onClose={onClose}>
            <form onSubmit={handleFormSubmit} className={styles.focalPointContainer}>
                <div className={cx(styles['row'], styles['mb-4'])}>
                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-6'])}>
                        <FormMultiSelect
                            data={filterData(data)}
                            name="staffs"
                            onChange={handleChange}
                            label="Staffs"
                            placeholder="Select Staffs"
                            isLoading={loader}
                        />
                    </div>
                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-6'])}>
                        <FormSelect
                            name="role"
                            label="User Role"
                            placeholder="User Role"
                            options={{
                                Correspondent: 'correspondent',
                                Editor: 'editor',
                            }}
                            value={form.role}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className={styles.BtnContainer}>
                    <Button text="Save" type="submit" className={styles.CreateRFQ__fullWidthButton} />
                    <Button text="Cancel" className={styles.CancelBtn} onClick={onClose} />
                </div>
            </form>
        </Modal>
    )
}

export default FocalPointModal
