import styles from './Icon.module.scss'
import cx from 'classnames'

const Icon = ({ image, text, expanded, active, onClick }) => {
    const isLogout = text === 'Logout'
    return (
        <button
            className={`${styles.Icon} ${expanded && styles.Icon__expanded} ${active && styles.Icon__active} `}
            onClick={onClick}
        >
            <span className={cx(styles.Icon__imageWrapper, !expanded && styles.Icon__imageWrapperNotExpanded)}>
                <img src={image} className={styles.Icon__image} />
            </span>
            <p className={`${styles.Icon__text} ${isLogout && styles.Icon__logout}`}>{text}</p>
        </button>
    )
}

export default Icon
