import { useState, useEffect } from 'react'
import Button from '../../../components/Button/Button'
import InputWithError from '../../../components/InputWithError/InputWithError'
import Modal from '../../../components/Modal/Modal'
import AttachmentTable from '../AttachmentTable/AttachmentTable'
import styles from './AddLineModal.module.scss'
import cx from 'classnames'
import LineAttachmentModal from '../LineAttachmentModal/LineAttachmentModal'
import { v4 as uuidv4 } from 'uuid'
import FormSelect from '../../../components/FormSelect/FormSelect'

const EditLineModal = ({
    onClose,
    handleSubmit = () => {},
    data,
    measurements,
    isLoading,
    handleAttachmentTableRemove,
}) => {
    const [attachmentModalActive, setAttachmentModalActive] = useState(false)
    const [form, setForm] = useState({
        ...data,
        additional_attributes: [],
        attachments: [...data.attachments],
    })
    const [errors, setErrors] = useState({
        product_name: '',
        uom_code: '',
        quantity: '',
    })
    // Local state to store attachments that are added locally
    const [localAttachments, setLocalAttachments] = useState([])

    useEffect(() => {
        setForm((prevForm) => ({
            ...prevForm,
            ...data,
        }))
        console.log(form)
    }, [data, handleAttachmentTableRemove])

    const handleAttachmentModalSubmit = (data) => {
        setLocalAttachments((prevAttachments) => [...prevAttachments, { id: uuidv4(), ...data }])
        // setForm((prevForm) => ({
        //     ...prevForm,
        //     attachments: [...prevForm.attachments, { id: uuidv4(), ...data }],
        // }))
        setAttachmentModalActive(false)
    }

    const validateForm = () => {
        let isValid = true
        let newErrors = { ...errors }
        if (!form.product_name) {
            newErrors.product_name = 'Name is mandatory'
            isValid = false
        } else {
            newErrors.product_name = ''
        }

        if (!form.quantity) {
            newErrors.quantity = 'Quantity is mandatory'
            isValid = false
        } else if (isNaN(form.quantity)) {
            newErrors.quantity = 'Quantity must be a number'
            isValid = false
        } else {
            newErrors.quantity = ''
        }
        if (isNaN(form.target_price)) {
            newErrors.target_price = 'Price must be a number'
            isValid = false
        } else {
            newErrors.target_price = ''
        }

        if (!form.uom_code) {
            newErrors.uom_code = 'Unit of Measurement is mandatory'
            isValid = false
        } else {
            newErrors.uom_code = ''
        }

        setErrors(newErrors)
        return isValid
    }

    const handleChange = (e) => {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            const allAttachments = [...form.attachments, ...localAttachments]
            handleSubmit({ ...form, attachments: allAttachments })
        }
    }

    return (
        <>
            <Modal title="Edit Item" onClose={onClose}>
                <form onSubmit={handleFormSubmit}>
                    <div className={cx(styles.CreateRFQ__FormWrapper, styles['row'])}>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="product_name"
                                label="Name"
                                placeholder="Name"
                                type="text"
                                value={form.product_name}
                                errorMsg={errors.product_name}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="product_id"
                                label="Product Id"
                                placeholder="Product Id (if any)"
                                type="text"
                                value={form.product_id}
                                errorMsg={errors.product_id}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="target_price"
                                label="Price Per Item"
                                placeholder="Price Per Item"
                                type="number"
                                value={form.target_price}
                                errorMsg={errors.target_price}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="quantity"
                                label="Quantity"
                                placeholder="Quantity"
                                type="number"
                                value={form.quantity}
                                errorMsg={errors.quantity}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="brand"
                                label="Brand"
                                placeholder="Brand"
                                type="text"
                                value={form.brand}
                                errorMsg={errors.brand}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <FormSelect
                                onChange={handleChange}
                                name="uom_code"
                                label="Unit Of Measurement"
                                placeholder="UO0M"
                                type="text"
                                options={measurements}
                                value={form.uom_code}
                                errorMsg={errors.uom_code}
                            />
                        </div>

                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-12'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="product_description"
                                label="Comment"
                                placeholder="comment"
                                type="text"
                                value={form.product_description}
                                errorMsg={errors.product_description}
                            />
                        </div>

                        <div className={cx(styles['col-12'], styles.CreateRFQ__attachmentsWrapper)}>
                            <h2 className={styles.CreateRFQ__tertiaryTitle}>Attachments</h2>
                            <p className={styles.CreateRFQ__note}>
                                <strong>NOTE:</strong> Attachments should be in PDF format and must not exceed 10 MB in
                                size.
                            </p>
                            <AttachmentTable
                                data={[...form.attachments, ...localAttachments]}
                                handleDelete={handleAttachmentTableRemove}
                            />
                            <div className={styles.CreateRFQ__buttonWrapper}>
                                <br />
                                <div className={styles.CreateRFQ__buttonGroup}>
                                    <Button
                                        text="Upload Attachment"
                                        color="rgb(14, 44, 36)"
                                        onClick={() => setAttachmentModalActive(true)}
                                    />
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>

                    <div className={styles.modal_actionButton}>
                        <Button
                            text="Update"
                            type="submit"
                            className={styles.CreateRFQ__fullWidthButton}
                            loading={isLoading}
                        />
                        <Button text="Cancel" className={styles.CancelBtn} onClick={onClose} />
                    </div>
                </form>
            </Modal>

            {attachmentModalActive && (
                <LineAttachmentModal
                    onClose={() => setAttachmentModalActive(false)}
                    handleSubmit={handleAttachmentModalSubmit}
                    errors={{}}
                />
            )}
        </>
    )
}

export default EditLineModal
