import React, { useState, useRef } from 'react'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierDocuments.module.scss'
import SearchIcon from '../../assets/icons/search.png'
import Button from '../../components/Button/Button'
import cx from 'classnames'
import Table from '../../components/Table/Table'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableButton from '../../components/Table/TableButton/TableButton'
import { useQuery, useMutation } from '@tanstack/react-query'
import { FaTrash, FaEye } from 'react-icons/fa'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import SupplierAPI from '../../api/SupplierAPI'
import { toast } from 'react-toastify'
import moment from 'moment'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import Loader from '../../components/Loader/Loader'

const SupplierDocuments = () => {
    const [searchText, setSearchText] = useState('')
    const [form, setForm] = useState({ files: [] })
    const fileInputRef = useRef(null)
    const [fileError, setFileError] = useState('')
    const { user } = useContext(AuthContext)

    const getDocuments = useQuery(
        ['documents'],
        () => SupplierAPI.getDocumentList({ token: user.token, id: user.organisation }),
        {
            select: (data) => {
                return data?.data.map((file) => ({
                    file: file.file,
                    created_at: moment(file.created_at).format('DD-MM-YYYY'),
                    id: file.id,
                    type: file.file.split('.').pop(),
                    name: file.file.split('/').pop(),
                }))
            },
        }
    )
    const submitMutation = useMutation(SupplierAPI.uploadDocument, {
        onSuccess: () => {
            toast.success('Added Successfully')
            getDocuments.refetch()
        },
        onError: (err) => {
            toast.error(err.response.data?.message)
        },
    })

    const deleteMutation = useMutation(SupplierAPI.deleteDocument, {
        onSuccess: () => {
            toast.success('Deleted Successfully')
            getDocuments.refetch()
        },
        onError: (err) => {
            toast.error(err.response.data?.message)
        },
    })

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleFileChange = (e) => {
        const newFiles = e.target.files
        const formData = new FormData()

        Array.from(newFiles).forEach((file) => {
            formData.append('file', file)
        })

        // Perform the mutation with the form data
        submitMutation.mutate({ token: user.token, id: user.organisation, data: formData })
    }

    const handleFileDrop = (e) => {
        e.preventDefault()
        const newFiles = e.dataTransfer.files
        const formData = new FormData()

        Array.from(newFiles).forEach((file) => {
            formData.append('file', file)
        })

        // Perform the mutation with the form data
        submitMutation.mutate({ token: user.token, id: user.organisation, data: formData })
    }

    const openFileInput = () => {
        fileInputRef.current.click()
    }
    const handleDownload = (file) => {
        const baseURL = process.env.REACT_APP_API_BASE_URL
        const fileUrl = baseURL + file
        window.open(fileUrl, '_blank')
    }
    const handleDeleteFile = (id) => {
        deleteMutation.mutate({ token: user.token, id: user.organisation, docId: id })
    }

    return (
        <SupplierLayout active="documents" title="Document Details">
            <Table>
                <TableControls>
                    <div className={cx(styles.row, styles.Doc__container)}>
                        <div className={styles.Filters}>
                            <TableControlSearch />
                        </div>
                        <div
                            className={styles['col-12']}
                            onDrop={handleFileDrop}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <div className={styles.Doc__upload}>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-12'])}>
                                    <div className={styles.customFileInput}>
                                        <label className={styles.customFileInputLabel} onClick={openFileInput}>
                                            Drop your documents here or click to upload
                                        </label>
                                        <input
                                            ref={fileInputRef}
                                            id="fileInput"
                                            name="files"
                                            type="file"
                                            onChange={handleFileChange}
                                            className={styles.customFileInputField}
                                            multiple
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TableControls>

                <TableContent hoverable={!(getDocuments.isLoading || getDocuments.isError)}>
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getDocuments.isLoading || getDocuments.isError ? (
                            <tr>
                                <td colSpan="7">
                                    <Loader height={'30vh'} refresh={getDocuments.isError} />
                                </td>
                            </tr>
                        ) : getDocuments.data.length === 0 ? (
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center' }}>
                                    No data found
                                </td>
                            </tr>
                        ) : (
                            getDocuments?.data?.map((file, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{file.name}</td>
                                    <td>{file.type}</td>
                                    <td>{file.created_at}</td>
                                    <td>Approved</td>
                                    <TableButton>
                                        <FaEye
                                            className={styles.CreateRFQ__editIcon}
                                            onClick={() => handleDownload(file.file)}
                                        />
                                    </TableButton>
                                    <TableButton>
                                        <FaTrash
                                            className={styles.CreateRFQ__editIcon}
                                            onClick={() => handleDeleteFile(file.id)}
                                        />
                                    </TableButton>
                                </tr>
                            ))
                        )}
                    </tbody>
                </TableContent>
            </Table>
        </SupplierLayout>
    )
}

export default SupplierDocuments
