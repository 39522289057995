import { useEffect } from 'react'
import { createContext, useState } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({
        loggedIn: false,
    })

    const login = (user) => {
        setUser({
            ...user,
            loggedIn: true,
        })
        localStorage.setItem('token', user.token)
    }

    const logout = () => {
        setUser({
            loggedIn: false,
        })
        localStorage.removeItem('token')
    }
    return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>
}

export default AuthContext
