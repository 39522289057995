import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './SideSub.css'
import profile from './assets/user-02.png'
import org from './assets/light.svg'

const SideSub = ({ isSupplier }) => {
    const [activeItem, setActiveItem] = useState('profile')

    useEffect(() => {
        const path = window.location.pathname
        const pathSplit = path.split('/')
        const activeName = pathSplit[pathSplit.length - 1]
        if (activeName === 'profile') setActiveItem('profile')
        if (activeName === 'org') setActiveItem('org')
        if (activeName === 'sup') setActiveItem('org')
    })

    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <ul className="sidebarList">
                        <NavLink to={`/${isSupplier ? `supplier` : `organization`}/settings/profile`} className="link">
                            <li className={`sidebarListitem ${activeItem === 'profile' ? 'selected' : ''}`}>
                                <img src={profile} alt="profile" />

                                <h4>Profile</h4>
                            </li>
                        </NavLink>

                        <NavLink
                            to={isSupplier ? '/supplier/settings/sup' : '/organization/settings/org'}
                            className="link"
                        >
                            <li className={`sidebarListitem ${activeItem === 'org' ? 'selected' : ''}`}>
                                <img src={org} alt="org" />

                                <h4>{isSupplier ? 'Supplier' : 'Buyer'}</h4>
                            </li>
                        </NavLink>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideSub
