import styles from './FormSelect.module.scss'
import Select from 'react-select'
import cx from 'classnames'

const FormSelect = ({
    name,
    label,
    placeholder,
    options,
    disabled,
    onChange = () => {},
    errorMsg = '',
    value,
    isLoading,
    filter = false,
}) => {
    const handleChange = (newValue) => {
        const syntheticEvent = {
            target: {
                name: name,
                value: newValue ? newValue.value : '',
            },
        }
        onChange(syntheticEvent)
    }
    const data = Object.keys(options).map((key) => {
        return {
            value: options[key],
            label: key,
        }
    })
    const selectedOption = value ? { value: value, label: options[value] || value } : null

    return (
        <div className={styles.FormSelect}>
            {label && <label>{label}</label>}
            <Select
                className={cx(styles.FormSelect__inputField, filter && styles.FormSelect__inputField__filter)}
                value={selectedOption}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: !filter ? '2px solid #e2e8f0' : '1px solid #dcdcdc',
                        '&:hover': {
                            border: !filter ? '2px solid #e2e8f0' : '1px solid #dcdcdc',
                        },
                        boxShadow: 'none',
                        padding: '0.4rem 0',
                        outline: 'none',
                        cursor: 'pointer',
                        alignContent: 'center',
                        borderRadius: !filter ? '8px' : '10rem',
                        minWidth: !filter ? 'auto' : '16rem',
                        maxWidth: !filter ? 'auto' : '16rem',
                        height: 'inherit',
                        padding: filter ? '0' : '0.4rem 0',
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        cursor: 'pointer',
                        padding: !filter ? '0.4rem 1rem' : '0.3rem 1rem',
                        fontSize: !filter ? '1.4rem' : '1.3rem',
                        color: 'black',
                        backgroundColor: state.isFocused ? 'rgba(112, 188, 255, 0.162)' : 'white',
                        overflowX: 'hidden',
                    }),
                    menuList: (base) => ({ ...base, padding: 0, borderRadius: '4px' }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure it's on top of other elements
                }}
                menuPortalTarget={document.body} // Renders dropdown in a portal appended to body
                menuPosition={'fixed'}
                isDisabled={disabled}
                isLoading={isLoading}
                isClearable
                isSearchable
                name={name}
                options={data}
                onChange={handleChange}
                placeholder={placeholder}
            />
            <span className={styles.FormSelect__msg}>{errorMsg}</span>
        </div>
    )
}

export default FormSelect
