import React, { useState, useContext } from 'react'
import Card from '../../../components/Card/Card'
import styles from './OrganizationRFQDetails.module.scss'
import moment from 'moment/moment'
import OrganizationLayout from '../../../components/OrganizationLayout/OrganizationLayout'
import OrganizationAPI from '../../../api/OrganizationAPI'
import Loader from '../../../components/Loader/Loader'
import { FaEye } from 'react-icons/fa'
import Table from '../../../components/Table/Table'
import TableContent from '../../../components/Table/TableContent/TableContent'
import Button from '../../../components/Button/Button'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import AuthContext from '../../../context/AuthContext'
import Modal from '../../../components/Modal/Modal'
import { toast } from 'react-toastify'
import InputWithError from '../../../components/InputWithError/InputWithError'
import TableButton from '../../../components/Table/TableButton/TableButton'

// Utility function to convert incoterms to Pascal Case
const toPascalCase = (string) => {
    return string
        .replace(/(^\w|-\w)/g, clearAndUpper)
        .replace(/([A-Z])/g, ' $1')
        .trim()
}

const clearAndUpper = (text) => {
    return text.replace(/-/, '').toUpperCase()
}

const OrganizationRFQDetail = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [bidHeaderId, setBidHeaderId] = useState(null)
    const [auctionData, setAuctionData] = useState(null)
    const [comment, setComment] = useState('')
    const { auctionId } = useParams()
    const { user } = useContext(AuthContext)

    const navigate = useNavigate()

    const handleAllBidsClick = (auctionId) => {
        navigate(`/organization/rfq/detail/bids/${auctionId}`)
    }

    const auctionQuery = useQuery(['my-auctions'], () =>
        OrganizationAPI.getAuctionById({ token: user.token, id: auctionId })
    )
    const AuctionLines = useQuery(['auction-lines'], () =>
        OrganizationAPI.getAuctionLines({ token: user.token, id: auctionId })
    )

    const bidHeadersQuery = useQuery(['bid-headers', auctionId], () =>
        OrganizationAPI.getBidHeadersByAuctionId({ token: user.token, id: auctionId })
    )

    console.log('Bid Headers Data:', bidHeadersQuery.data)

    const bidData = bidHeadersQuery?.data?.data

    const bidLinesQuery = useQuery(
        ['bid-lines'],
        () => OrganizationAPI.getBidLines({ token: user.token, id: bidHeaderId }),
        {
            enabled: !!bidHeaderId,
        }
    )

    const auctionHeaderComments = useQuery(
        ['auction-comments'],
        () => OrganizationAPI.getAuctionComments({ token: user.token, id: auctionId }),
        {
            enabled: !!auctionId,
            select: (data) => {
                return data.data.map((comment) => ({
                    created_at: moment(comment.created_at).format('MMMM DD, YYYY'),
                    create_by: comment.author,
                    message: comment.message,
                }))
            },
        }
    )

    const addAuctionHeaderComment = useMutation(
        (data) => OrganizationAPI.addAuctionComment({ token: user.token, id: auctionId, data }),
        {
            onSuccess: () => {
                toast.success('Comment added successfully')
                auctionHeaderComments.refetch()
            },
            onError: () => {
                toast.error('Error adding comment')
            },
        }
    )

    const handlePost = (e) => {
        e.preventDefault()
        addAuctionHeaderComment.mutate({ message: comment })
        setComment('')
    }

    const handleCompareClick = () => {
        navigate(`/organization/rfq/detail/${auctionId}/compare`)
    }

    const showPopup = () => {
        setIsPopupVisible(true)
    }

    const hidePopup = () => {
        setIsPopupVisible(false)
    }

    const handleBidClick = (bidHeaderId) => {
        setBidHeaderId(bidHeaderId)
    }

    const formattedDate = moment(auctionQuery.data?.data?.created_at).format('MMMM Do [at] h:mm A')

    const viewFile = (fileName) => {
        const baseURL = process.env.REACT_APP_API_BASE_URL
        const fileUrl = baseURL + fileName
        window.open(fileUrl, '_blank')
    }

    return (
        <OrganizationLayout active="rfq" title="Details">
            {auctionQuery.isError ? (
                <Loader />
            ) : auctionQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.Request__container}>
                        <Card>
                            <div className={styles.Request__titleContainer}>
                                <p className={styles.Request__rfqId}>RFQ#320023</p>
                                <p className={styles.Request__rfqDate}>{formattedDate}</p>
                            </div>
                            <Card>
                                <p className={styles.Request__descriptionTitle}>Requested By</p>
                                <p className={styles.Request__organizationName}>
                                    {auctionQuery.data?.data?.organization_name}
                                </p>
                                <hr />
                                <p className={styles.Request__text}>
                                    <span>RFQ Title</span>
                                    <span>{auctionQuery.data?.data?.title}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Reference Number</span>
                                    <span>{auctionQuery.data?.data?.requisition_number}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Contract Type</span>
                                    <span>{auctionQuery.data?.data?.is_open ? 'Open' : 'Closed'}</span>
                                </p>
                                {auctionQuery.data?.data?.bln_incoterms && (
                                    <p className={styles.Request__text}>
                                        <span>Incoterms</span>
                                        <span>{toPascalCase(auctionQuery.data?.data?.incoterms)}</span>
                                    </p>
                                )}
                            </Card>
                            <div className={styles.Request__buttonWrapper}>
                                <Button text="See More" onClick={showPopup} />
                            </div>
                        </Card>
                        <Card>
                            <p className={styles.Request__rfqId}>Comments</p>
                            <div className={styles.timeline}>
                                <div className={styles.timeline__Content}>
                                    {auctionHeaderComments?.data?.length === 0 && (
                                        <div className={styles.empty_records}>No comments yet.</div>
                                    )}
                                    {auctionHeaderComments?.data?.map((item, index) => (
                                        <div className={styles.tlContent} key={index}>
                                            <div className={styles.tlHeader}>
                                                <span className={styles.tlMarker}></span>
                                                <div className={styles.tlAuthor}>
                                                    {item.create_by}
                                                    <time className={styles.tlTime} dateTime={item.created_at}>
                                                        {item.created_at}
                                                    </time>
                                                </div>
                                            </div>
                                            <div className={styles.tlBody}>
                                                <p>{item.message}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <p className={styles.Note}>
                                    <strong>NOTE: </strong>Avoid disclosing private information here.
                                </p>
                                <form className={styles.commentForm} onSubmit={handlePost}>
                                    <div className={styles.comment}>
                                        <InputWithError
                                            name={comment}
                                            placeholder="Comment here"
                                            type="text"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                    <Button
                                        className={styles.commentBtn}
                                        text="Post"
                                        type="submit"
                                        loading={addAuctionHeaderComment.isLoading}
                                    />
                                </form>
                            </div>
                        </Card>
                    </div>
                    {isPopupVisible && (
                        <div className={styles.Request__Popup}>
                            <div className={styles.Request__Popup__content} onClick={(e) => e.stopPropagation()}>
                                <Card>
                                    <div className={styles.Request__titleContainer}>
                                        <p className={styles.Request__rfqId}>RFQ#320023</p>
                                        <p className={styles.Request__rfqDate}>{formattedDate}</p>
                                    </div>
                                    <Card>
                                        <div className={styles.Request__Popup__items}>
                                            <p className={styles.Request__descriptionTitle}>Requested By</p>
                                            <p className={styles.Request__organizationDetails}>
                                                <span className={styles.Request__organizationName}>
                                                    {auctionQuery.data?.data?.organization_name}
                                                </span>
                                            </p>
                                            <hr />
                                            <p className={styles.Request__text}>
                                                <span>RFQ Title</span>
                                                <span>{auctionQuery.data?.data?.title}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Reference Number</span>
                                                <span>{auctionQuery.data?.data?.requisition_number}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Product Category</span>
                                                <span>{auctionQuery.data?.data?.product_category}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Product Sub Category</span>
                                                <span>{auctionQuery.data?.data?.product_sub_category}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Contract Type</span>
                                                <span>{auctionQuery.data?.data?.is_open ? 'Open' : 'Closed'}</span>
                                            </p>
                                            {auctionQuery.data?.data?.bln_incoterms && (
                                                <p className={styles.Request__text}>
                                                    <span>Incoterms</span>
                                                    <span>{toPascalCase(auctionQuery.data?.data?.incoterms)}</span>
                                                </p>
                                            )}
                                            {auctionQuery.data?.data?.attachments &&
                                                auctionQuery.data.data.attachments.length > 0 && (
                                                    <Card>
                                                        <div className={styles.Request__bidResponsesTitle}>
                                                            <span className={styles.Request__title}>Attachments</span>
                                                        </div>
                                                        <Table>
                                                            <TableContent>
                                                                <thead>
                                                                    <tr>
                                                                        <th>SL No</th>
                                                                        <th>Name</th>
                                                                        <th>Type</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {auctionQuery.data?.data?.attachments.map(
                                                                        (attachment, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{attachment.name}</td>
                                                                                <td>
                                                                                    {attachment.file.split('.').at(-1)}
                                                                                </td>
                                                                                <td>
                                                                                    <FaEye
                                                                                        onClick={() =>
                                                                                            viewFile(attachment.file)
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </TableContent>
                                                        </Table>
                                                    </Card>
                                                )}
                                            <br />
                                            {auctionQuery.data?.data?.focal_points &&
                                                auctionQuery.data.data.focal_points.length > 0 && (
                                                    <Card>
                                                        <div className={styles.Request__bidResponsesTitle}>
                                                            <span className={styles.Request__title}>Focal Points</span>
                                                        </div>
                                                        <Table>
                                                            <TableContent>
                                                                <thead>
                                                                    <tr>
                                                                        <th>SL No</th>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Role</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {auctionQuery.data.data.focal_points.map(
                                                                        (focalPoint, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>
                                                                                    {focalPoint.user.first_name}{' '}
                                                                                    {focalPoint.user.middle_name}{' '}
                                                                                    {focalPoint.user.last_name}
                                                                                </td>
                                                                                <td>{focalPoint.user.email}</td>
                                                                                <td>{focalPoint.role}</td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </TableContent>
                                                        </Table>
                                                    </Card>
                                                )}
                                            <br />
                                            <Card>
                                                <div className={styles.Request__bidResponsesTitle}>
                                                    <span className={styles.Request__title}>Lines</span>
                                                </div>
                                                {AuctionLines.isLoading ? (
                                                    <Loader />
                                                ) : AuctionLines.isError ? (
                                                    <p>Error loading auction lines.</p>
                                                ) : AuctionLines.data.data && AuctionLines.data.data.length > 0 ? (
                                                    <Table>
                                                        <TableContent>
                                                            <thead>
                                                                <tr>
                                                                    <th>SL No</th>
                                                                    <th>Product Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Brand</th>
                                                                    <th>Target Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {AuctionLines.data.data.map((line, index) => (
                                                                    <tr key={line.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{line.product_name}</td>
                                                                        <td>{line.quantity}</td>
                                                                        <td>{line.brand}</td>
                                                                        <td>{line.target_price}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </TableContent>
                                                    </Table>
                                                ) : (
                                                    <div>
                                                        <p>No Auction Line Responses yet.</p>
                                                    </div>
                                                )}
                                            </Card>
                                        </div>
                                        <Button className={styles.closeButton} text="Close" onClick={hidePopup} />
                                    </Card>
                                </Card>
                            </div>
                        </div>
                    )}
                    <Card>
                        <div className={styles.Request__bidResponsesTitle}>
                            <span className={styles.Request__title}>All Bids</span>
                            {(auctionQuery.data?.data?.can_compare ||
                                new Date(auctionQuery.data?.data?.need_by_date) <= new Date()) && (
                                <Button
                                    text="Compare"
                                    color="#a881af"
                                    onClick={handleCompareClick}
                                    className={styles.Request__Button}
                                />
                            )}
                        </div>
                        <hr />
                        {bidHeadersQuery.isLoading ? (
                            <Loader height="20vh" />
                        ) : bidHeadersQuery.isError ? (
                            <Loader refresh height="20vh" />
                        ) : bidHeadersQuery.data.data && bidHeadersQuery.data.data.length > 0 ? (
                            <div className={styles.Request__bidList}>
                                {bidData.map((data) => (
                                    <Card
                                        key={data.id}
                                        onClick={() => handleAllBidsClick(data.id)}
                                        className={styles.Request__bidCard}
                                    >
                                        <div className={styles.Request__bidCardContent}>
                                            <p className={styles.Request__bidNoteHead}>
                                                Quoted By: {data.organisation.name}
                                            </p>
                                            <p className={styles.Request__bidExpiration}>
                                                Expiration Date: {moment(data.bid_expiration_date).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.empty_records}>No Bid Line Responses yet.</div>
                        )}
                    </Card>
                    {/* <Card>
                        <div className={styles.Request__bidResponsesTitle}>
                            <span className={styles.Request__title}>Templates</span>
                        </div>
                        <hr />
                        {bidHeadersQuery.isLoading ? (
                            <Loader height="20vh" />
                        ) : bidHeadersQuery.isError ? (
                            <Loader refresh height="20vh" />
                        ) : bidHeadersQuery.data.data && bidHeadersQuery.data.data.length > 0 ? (
                            <div>
                                {bidData?.map((data, index) => (
                                    <div key={index} className={styles.Request__templateCard}>
                                        {data.templates ? (
                                            <>
                                                <p className={styles.Request__descriptionTitle}>
                                                    {data.templates.name}
                                                </p>
                                                <p className={styles.Request__text} style={{ textAlign: 'left' }}>
                                                    {data.templates.display_description}
                                                </p>
                                            </>
                                        ) : (
                                            <p className={styles.Request__text}>No templates available.</p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.empty_records}>No selected Templates.</div>
                        )}
                    </Card> */}
                </>
            )}
        </OrganizationLayout>
    )
}

export default OrganizationRFQDetail
