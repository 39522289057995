import React, { useState, useEffect } from 'react'
import styles from './Counter.module.scss'

const Counter = ({ onBlur, onChange, name, placeholder, label, value = 0, disabled = false, limit }) => {
    const [quantity, setQuantity] = useState(value)

    useEffect(() => {
        // Update local state when the value prop changes
        setQuantity(value)
    }, [value])

    const handleQuantityChange = (newQuantity) => {
        const adjustedQuantity = Math.max(0, Math.min(newQuantity, limit))

        setQuantity(adjustedQuantity)

        // Create a synthetic event object to pass to the onChange handler
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: adjustedQuantity,
                    type: 'number',
                },
            })
        }
    }

    const handleButtonClick = (increment) => (e) => {
        e.preventDefault()
        e.stopPropagation() // Stop the event from bubbling up

        const newQuantity = increment ? quantity + 1 : quantity - 1
        handleQuantityChange(newQuantity)
    }

    return (
        <div className={styles.InputWithError}>
            {label && <label className={styles.InputWithError__label}>{label}</label>}
            <div className={styles.InputWithError__inputWrapper}>
                <button className={styles.Btn} onClick={handleButtonClick(false)} disabled={disabled || quantity <= 0}>
                    -
                </button>
                <input
                    className={styles.InputWithError__input}
                    onBlur={onBlur}
                    name={name}
                    type="number"
                    placeholder={placeholder}
                    value={quantity}
                    disabled={disabled}
                    onChange={(e) => handleQuantityChange(Number(e.target.value))}
                />
                <button
                    className={styles.Btn}
                    onClick={handleButtonClick(true)}
                    disabled={disabled || quantity >= limit}
                >
                    +
                </button>
            </div>
        </div>
    )
}

export default Counter
