import React, { useState } from 'react'
import styles from './TableControlSearch.module.scss'
import SearchIcon from '../../../assets/icons/search.png'

const TableControlSearch = ({ onSearchChange }) => {
    const [searchInput, setSearchInput] = useState('')

    const handleSearchChange = (event) => {
        // console.log("Event::", event.target.value);
        const inputValue = event.target.value
        setSearchInput(inputValue)
        onSearchChange(inputValue)
    }

    return (
        <div className={styles.TableControlSearch}>
            <div className={styles.TableControlSearch__searchBoxWrapper}>
                <label htmlFor="TableControlSearch__search" className={styles.TableControlSearch__searchBoxIcon}>
                    <img src={SearchIcon} alt="Search" />
                </label>
                <input
                    className={styles.TableControlSearch__searchBox}
                    id="TableControlSearch__search"
                    type="text"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    value={searchInput}
                />
            </div>
        </div>
    )
}

export default TableControlSearch
