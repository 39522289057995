import React from 'react'
import Modal from './Modal'

const InviteSentPopup = ({ orgName, onClose }) => {
    return (
        <Modal title="Invite Sent">
            <div style={{ textAlign: 'center' }}>
                {/* <p>Invite Sent to {orgName}</p> */}
                <button
                    style={{
                        backgroundColor: 'rgb(0,185,118)',
                        color: 'white',
                        width: '150px',
                        height: '38px',
                        borderRadius: '18px',
                        border: 'none',
                        fontSize: '16px',
                    }}
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </Modal>
    )
}

export default InviteSentPopup
