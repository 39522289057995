import axios from 'axios'

class ProductAPI {
    static baseURL = process.env.REACT_APP_API_BASE_URL

    static async getProductCategories({ token }) {
        return await axios.get(`${this.baseURL}/product/product-category/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getProductSubCategories({ token, category }) {
        return await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/product/product-category/${category}/product-subcategory/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }
    static async getMeasurements({ token }) {
        return await axios.get(`${this.baseURL}/config/get-measurement-data/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
    static async getProducts({ id }) {
        return await axios.get(`${this.baseURL}/product/product-catalogue/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }
    static async getProduct({ id }) {
        return await axios.get(`${this.baseURL}/product/product-catalogue-detail/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }
}

export default ProductAPI
