import React from 'react'
import styles from './Badge.module.scss'
import cx from 'classnames'

const Badge = ({ value, backgroundColor, color, className }) => {
    const badgeStyle = {
        color,
        backgroundColor,
    }
    return (
        <span className={cx(styles.Badge, className)} style={badgeStyle}>
            {value}
        </span>
    )
}

export default Badge
