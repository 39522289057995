import React, { useState, useContext, useEffect } from 'react'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import InputWithError from '../../components/InputWithError/InputWithError'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierProductRequest.module.scss'
import PlusCircleIcon from '../../assets/icons/new/plus_circle.svg'
import Table from '../../components/Table/Table'
import { useParams, useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import TableContent from '../../components/Table/TableContent/TableContent'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@tanstack/react-query'
import SupplierAPI from '../../api/SupplierAPI'
import Loader from '../../components/Loader/Loader'
import OrganizationAPI from '../../api/OrganizationAPI'
import moment from 'moment/moment'
import TableButton from '../../components/Table/TableButton/TableButton'
import Modal from '../../components/Modal/Modal'
import SuccessModal from '../OrganizationCreateRFQ/SuccessModal/SuccessModal'
import Counter from '../../components/Counter/Counter'
import TableControls from '../../components/Table/TableControls/TableControls'
import { FaEdit } from 'react-icons/fa'

// Utility function to convert incoterms to Pascal Case with spaces
const toPascalCaseWithSpaces = (string) => {
    return string
        .replace(/(^\w|-\w)/g, clearAndUpper)
        .replace(/([A-Z])/g, ' $1')
        .trim()
}

const clearAndUpper = (text) => {
    return text.replace(/-/, '').toUpperCase()
}

const SupplierProductRequest = () => {
    const params = useParams()
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [form, setForm] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [comment, setComment] = useState('')
    const [isBidConfirmationOpen, setIsBidConfirmationOpen] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [bidLineQuoteModalId, setBidLineQuoteModalId] = useState(null)
    const [bidLineQuoteLimit, setBidLIneQuoteLimit] = useState(0)
    const [bidLineQuoteForm, setBidLineQuoteForm] = useState({})
    const [bidLineForm, setBidLineForm] = useState([])
    const [attachments, setAttachments] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState('')
    const [isTemplateListVisible, setIsTemplateListVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [selectedTemplates, setSelectedTemplates] = useState([])

    const [selectedTemplateName, setSelectedTemplateName] = useState('')
    const [selectedTemplateDescription, setSelectedTemplateDescription] = useState('')
    const [originalTemplateDescription, setOriginalTemplateDescription] = useState('')

    useEffect(() => {
        const success = () => {
            if (showSuccessModal) {
                setTimeout(() => {
                    setShowSuccessModal(false)
                    navigate('/supplier/bids')
                }, 3000)
            }
        }
        success()
    }, [showSuccessModal, navigate])

    const getAuctionByIdQuery = useQuery(['auctions-by-id'], () =>
        OrganizationAPI.getAuctionById({ token: user.token, id: params.id })
    )

    const getTemplatesQuery = useQuery(['templates'], () => getTemplateList(user.token))

    const getTemplateList = async (token) => {
        try {
            const response = await SupplierAPI.getTemplate(token)
            return response.data
        } catch (error) {
            console.error('Error fetching templates:', error)
            throw error
        }
    }

    const getAuctionLinesQuery = useQuery(
        ['auction-lines'],
        () =>
            OrganizationAPI.getAuctionLines({
                id: params.id,
                token: user.token,
            }),
        {
            select: (data) => {
                const tableData = data?.data.map((auction) => {
                    return {
                        Name: auction.product_name,
                        Quantity: `${auction.quantity} ${auction.uom_code}`,
                        Brand: auction.brand,
                        'Target Price': auction.target_price,
                        'Created At': moment(auction.created_at).format('DD/MM/YYYY'),
                    }
                })
                return {
                    data: data?.data,
                    tableData,
                }
            },
        }
    )

    const organisationId = getAuctionByIdQuery.data?.data.organisation

    const getOrganizationQuery = useQuery(
        ['organization'],
        () =>
            OrganizationAPI.getOrganizationById({
                id: organisationId,
                token: user.token,
            }),
        { enabled: !!organisationId }
    )
    const auctionHeaderComments = useQuery(
        ['auction-comments'],
        () => OrganizationAPI.getAuctionComments({ token: user.token, id: params?.id }),
        {
            enabled: !!params?.id,
            select: (data) => {
                return data.data.map((comment) => ({
                    created_at: moment(comment.created_at).format('MMMM DD, YYYY'),
                    create_by: comment.author,
                    message: comment.message,
                }))
            },
        }
    )
    const addAuctionHeaderComment = useMutation(
        (data) => OrganizationAPI.addAuctionComment({ token: user.token, id: params?.id, data }),
        {
            onSuccess: () => {
                toast.success('Comment added successfully')
                auctionHeaderComments.refetch()
            },
            onError: () => {
                toast.error('Error adding comment')
            },
        }
    )
    const handlePost = (e) => {
        e.preventDefault()
        addAuctionHeaderComment.mutate({ message: comment })
        setComment('')
    }
    const createBidHeaderMutation = useMutation(SupplierAPI.createBidHeader, {
        onSuccess: () => toast.success('Bid Header Created'),
        onError: (err) =>
            err.response.status === 400 ? setFormErrors(err.response.data) : toast.error(err.response.data?.message),
    })

    const handleCreateBid = async () => {
        console.log('Selected Template:', selectedTemplate)

        // const bidLineData = bidLineForm.map((bidLine) => {
        //     return {
        //         ...bidLine,
        //         auction_line: bidLine.id,
        //         selectedTemplate: selectedTemplate ? {
        //             id: selectedTemplate.id,
        //             name: selectedTemplate.name,
        //             display_description: selectedTemplate.display_description,
        //         } : null,
        //     };
        // });

        // console.log(bidLineData,'final data to API')
        // const formData = new FormData();
        // formData.append('note_to_supplier', form.note_to_supplier);
        // formData.append('lst_bid_line', JSON.stringify(bidLineData));

        const bidLineData = bidLineForm.map((bidLine) => {
            return {
                ...bidLine,
                auction_line: bidLine.id,
            }
        })

        const templateData = selectedTemplate
            ? {
                  id: selectedTemplate.id,
                  name: selectedTemplate.name,
                  display_description: selectedTemplate.display_description,
              }
            : {}

        console.log(bidLineData, 'final data to API')
        const formData = new FormData()
        formData.append('note_to_supplier', form.note_to_supplier)
        formData.append('lst_bid_line', JSON.stringify(bidLineData))
        formData.append('template_data', JSON.stringify(templateData))

        attachments.forEach((file) => {
            formData.append('attachments', file)
        })

        // console.log('Bid Data:', {
        //     note_to_supplier: form.note_to_supplier,
        //     lst_bid_line: bidLineData,8
        //     attachments,
        // });

        try {
            const res = await createBidHeaderMutation.mutateAsync({
                token: user.token,
                data: formData,
                id: params.id,
            })
            // console.log({ id: res.data.id });
            setShowSuccessModal(true)
            setBidLineQuoteForm({})
        } catch (error) {
            console.error('Failed to create bid:', error)
        }
    }

    // const handleCreateBid = async () => {
    //     console.log('Selected Templates:', selectedTemplates)

    //     const bidLineData = bidLineForm.map((bidLine) => {
    //         return {
    //             ...bidLine,
    //             auction_line: bidLine.id,
    //             selectedTemplates: selectedTemplates.map((template_id) => {
    //                 const template = getTemplatesQuery.data.find((t) => t.id === template_id)
    //                 return {
    //                     id: template.id,
    //                     name: template.name,
    //                     description: template.display_description,
    //                 }
    //             }),
    //         }
    //     })

    //     const formData = new FormData()
    //     formData.append('note_to_supplier', form.note_to_supplier)
    //     formData.append('lst_bid_line', JSON.stringify(bidLineData))

    //     attachments.forEach((file) => {
    //         formData.append('attachments', file)
    //     })

    //     console.log('Bid Data:', {
    //         note_to_supplier: form.note_to_supplier,
    //         lst_bid_line: bidLineData,
    //         attachments,
    //     })

    //     try {
    //         const res = await createBidHeaderMutation.mutateAsync({
    //             token: user.token,
    //             data: formData,
    //             id: params.id,
    //         })
    //         console.log({ id: res.data.id })
    //         setShowSuccessModal(true)
    //         setBidLineQuoteForm({})
    //     } catch (error) {
    //         console.error('Failed to create bid:', error)
    //     }
    // }

    const handleChange = (e) => {
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleBidLineQuoteSubmit = (e) => {
        e.preventDefault()
        let auctionLine = getAuctionLinesQuery.data.data.find((line) => line.id === bidLineQuoteModalId)
        auctionLine = { ...auctionLine, ...bidLineQuoteForm }
        setBidLineForm((prev) => [...prev, auctionLine])
        setBidLineQuoteModalId(null)
        setBidLIneQuoteLimit(0)
        setBidLineQuoteForm({})
    }

    const handleBidLineInputChange = (e) => {
        setBidLineQuoteForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const auctionData = getAuctionByIdQuery.data?.data
    // console.log({ auctionData })

    const handleFileChange = (event) => {
        setAttachments([...attachments, ...event.target.files])
    }

    // const handlePlaceBidClick = () => {
    //     const templatesAvailable = getTemplatesQuery.data?.length > 0
    //     const templatesSelected = selectedTemplate.length > 0
    //     if (templatesAvailable && !templatesSelected) {
    //         toast.error('Please select a template before placing a bid.')
    //         return
    //     }
    //     setIsBidConfirmationOpen(true)
    // }

    const handleTemplateRowClick = (template) => {
        setSelectedTemplateName(template.name)
        setSelectedTemplateDescription(template.description)
        setSelectedTemplate(template)
    }

    const handleTemplateDescriptionChange = (e) => {
        setSelectedTemplateDescription(e.target.value)
    }

    const { data: templates, isError, refetch } = useQuery(['templates'], () => getTemplateList(user.token))

    const handleSaveTemplateDescription = async () => {
        setIsLoading(true)
        try {
            const updatedTemplate = {
                display_description: selectedTemplateDescription,
            }
            await SupplierAPI.editTemplate(user.token, selectedTemplate.id, updatedTemplate)
            toast.success('Template updated')
            setSelectedTemplate('')
            setSelectedTemplateName('')
            setSelectedTemplateDescription('')
            setOriginalTemplateDescription('')

            refetch()
        } catch (err) {
            console.error('Error updating template:', err)
            setError('Error saving template. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    const handleCloseTemplateDescription = () => {
        setSelectedTemplateName('')
        setSelectedTemplateDescription('')
        setOriginalTemplateDescription('')
        setError('')
    }

    const handleCheckboxChange = (template) => {
        if (selectedTemplate && selectedTemplate.id === template.id) {
            setSelectedTemplate(null) // Deselect if it's already selected
        } else {
            setSelectedTemplate(template) // Select the new template
        }
    }

    // const handleCheckboxChange = (template_id) => {
    //     const isSelected = selectedTemplates.includes(template_id)

    //     if (isSelected) {
    //         setSelectedTemplates(selectedTemplates.filter((id) => id !== template_id))
    //     } else {
    //         setSelectedTemplates([...selectedTemplates, template_id])
    //     }
    // }

    return (
        <SupplierLayout active="requests" title="Requests">
            {getAuctionByIdQuery.isError || getAuctionLinesQuery.isError || getOrganizationQuery.isError ? (
                <Loader refresh />
            ) : getAuctionByIdQuery.isLoading || getAuctionLinesQuery.isLoading || getOrganizationQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    {isBidConfirmationOpen && (
                        <div className={styles.BidConfirmationDialog}>
                            <div className={styles.BidConfirmationDialogContent}>
                                <p>Are you sure you want to place a bid?</p>
                                <div className={styles.BidConfirmationButtons}>
                                    <Button
                                        text="Cancel"
                                        onClick={() => setIsBidConfirmationOpen(false)} // Close the bid confirmation dialog
                                    />
                                    <Button
                                        text="Confirm Bid"
                                        onClick={() => {
                                            setIsBidConfirmationOpen(false) // Close the bid confirmation dialog
                                            handleCreateBid() // Actually perform the bid action
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={styles.Request__header}>
                        <h1>Requests</h1>
                        <div className={styles.Request__buttons}>
                            {/* <div className={styles.Request__buttonWrapper}> */}
                            {/* <Select
                        value={selectedTemplate}
                        onChange={(selectedOption) => setSelectedTemplate(selectedOption)}
                        options={getTemplatesQuery.data?.map((template) => ({
                            value: template.id,
                            label: template.name,
                        }))}
                        placeholder="Select Template"
                        className={styles.Request__select}
                        isMulti
                    /> */}
                            {/* </div> */}

                            <div className={styles.Request__buttonWrapper}>
                                <Button
                                    text="Bid"
                                    icon={PlusCircleIcon}
                                    onClick={() => setIsBidConfirmationOpen(true)}
                                    // loading={createBidHeaderMutation.isLoading}
                                    // onClick={handlePlaceBidClick}
                                    // disabled={getTemplatesQuery.data?.length > 0 && selectedTemplate.length === 0}
                                />
                            </div>

                            <div className={styles.Request__buttonWrapper}>
                                <Button text="Ignore" color="rgba(255, 31, 0, 0.76)" icon={PlusCircleIcon} />
                            </div>
                        </div>
                    </div>
                    <Card>
                        <p className={styles.Request__rfqId}>RFQ#320023</p>
                        <Card>
                            <p className={styles.Request__descriptionTitle}>Requested By</p>
                            <p className={styles.Request__organizationDetails}>
                                <span className={styles.Request__organizationImageWrapper}>
                                    <img className={styles.Request__organizationImage} />
                                </span>
                                <span className={styles.Request__organizationName}>
                                    {getOrganizationQuery.data?.data.name}
                                </span>
                            </p>
                            <hr />
                            <p className={styles.Request__text}>
                                <span>RFQ Title</span>
                                <span>{auctionData.title}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Reference Number</span>
                                <span>{auctionData.requisition_number}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Contract Type</span>
                                <span>{auctionData.is_open ? 'OPEN' : 'CLOSED'}</span>
                            </p>
                            {auctionData.bln_incoterms && (
                                <p className={styles.Request__text}>
                                    <span>Incoterms</span>
                                    <span>{toPascalCaseWithSpaces(auctionData.incoterms)}</span>
                                </p>
                            )}
                        </Card>
                    </Card>
                    <Card>
                        <h4>Attachments</h4>
                        {auctionData?.attachments && auctionData.attachments.length > 0 ? (
                            <Table>
                                <TableControls>
                                    {/* Additional controls like search, filters can be added here */}
                                </TableControls>
                                <TableContent>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>File Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {auctionData?.attachments.map((attachment, index) => (
                                            <tr key={index}>
                                                <td>{attachment.name}</td>
                                                <td>{attachment.description}</td>
                                                <td>
                                                    <a href={attachment.file} target="_blank" rel="noopener noreferrer">
                                                        View File
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </TableContent>
                            </Table>
                        ) : (
                            <p>No attachments available.</p>
                        )}
                    </Card>

                    <Card title="Payment Templates">
                        <h4
                            onClick={() => setIsTemplateListVisible(!isTemplateListVisible)}
                            style={{ cursor: 'pointer' }}
                        >
                            Payment Templates
                        </h4>

                        <TableContent hoverable>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>SL No</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {getTemplatesQuery.isLoading || getTemplatesQuery.isError ? (
                                    <tr>
                                        <td colSpan="4">Loading...</td>
                                    </tr>
                                ) : getTemplatesQuery.data.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>
                                            No templates found
                                        </td>
                                    </tr>
                                ) : (
                                    getTemplatesQuery.data.map((template, index) => (
                                        <tr key={template.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedTemplate && selectedTemplate.id === template.id}
                                                    onChange={() => handleCheckboxChange(template)}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>{template.name}</td>
                                            <td>{template.display_description}</td>
                                            <td>
                                                <TableButton>
                                                    <FaEdit
                                                        className={styles.CreateRFQ__editIcon}
                                                        onClick={() => handleTemplateRowClick(template)}
                                                    />
                                                </TableButton>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </TableContent>
                    </Card>

                    {selectedTemplateName && (
                        <Card className={styles.TemplateDetails}>
                            <div classname={styles.TemplateHead}>
                                <h3>{selectedTemplateName}</h3>
                                {/* <button
                                    onClick={handleCloseTemplateDescription}
                                    className={styles.CloseButton}
                                >
                                    &times;
                                </button> */}
                            </div>

                            <FormTextarea
                                value={selectedTemplateDescription}
                                onChange={handleTemplateDescriptionChange}
                                name="templateDescription"
                            />
                            <div className={styles.TemplateActions}>
                                <Button text="Save" onClick={handleSaveTemplateDescription} type="primary" />
                                <Button text="close" onClick={handleCloseTemplateDescription} type="secondary" />
                            </div>
                            {error && <p className={styles.error}>{error}</p>}
                        </Card>
                    )}
                    <div className={styles.Request__content}>
                        <Card>
                            <FormTextarea
                                label="Note to supplier"
                                placeholder="Note here"
                                onChange={handleChange}
                                name="note_to_supplier"
                                minRows={3}
                                errorMsg={formErrors.note_to_supplier}
                                value={form.note_to_supplier}
                            />
                        </Card>
                        <Card>
                            <p className={styles.Request__title}>Comments</p>
                            <div className={styles.timeline}>
                                <div className={styles.timeline__Content}>
                                    {auctionHeaderComments?.data?.map((item, index) => (
                                        <div className={styles.tlContent} key={index}>
                                            <div className={styles.tlHeader}>
                                                <span className={styles.tlMarker}></span>
                                                <div className={styles.tlAuthor}>
                                                    {item.create_by}
                                                    <time className={styles.tlTime} dateTime={item.created_at}>
                                                        {item.created_at}
                                                    </time>
                                                </div>
                                            </div>
                                            <div className={styles.tlBody}>
                                                <p>{item.message}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <p className={styles.Note}>
                                    <b>Note</b>: Avoid disclosing private information here.
                                </p>
                                <form className={styles.commentForm} onSubmit={handlePost}>
                                    <div className={styles.comment}>
                                        <InputWithError
                                            name={comment}
                                            placeholder={'Comment here'}
                                            type={'text'}
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                    <Button
                                        className={styles.commentBtn}
                                        text="Post"
                                        type="submit"
                                        loading={addAuctionHeaderComment.isLoading}
                                    />
                                </form>
                            </div>
                        </Card>
                    </div>

                    <Table>
                        <TableContent>
                            <thead>
                                <tr>
                                    <th>SL No</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Brand</th>
                                    <th>Target Price</th>
                                    <th>Created At</th>
                                    <th>Promised Price</th>
                                    <th>Promised Date</th>
                                    {auctionData.partial_quantity_bidding && <th>Quantity</th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {getAuctionLinesQuery.data.tableData.map((row, id) => {
                                    const auctionLine = getAuctionLinesQuery.data.data[id]
                                    const bidLine = bidLineForm.find((line) => line.id === auctionLine.id)
                                    // console.log(auctionLine)
                                    return (
                                        <tr key={id}>
                                            <td>{id + 1}</td>
                                            {Object.values(row).map((field, index) => (
                                                <td key={index}>{field}</td>
                                            ))}
                                            <td>{bidLine ? bidLine.price : ''}</td>
                                            <td>{bidLine ? bidLine.promised_date : ''}</td>
                                            {auctionData.partial_quantity_bidding && (
                                                <td>{bidLine ? bidLine.bid_quantity : ''}</td>
                                            )}
                                            <TableButton>
                                                <Button
                                                    text="Quote"
                                                    onClick={() => {
                                                        setBidLineQuoteModalId(auctionLine.id)
                                                        setBidLIneQuoteLimit(auctionLine.quantity)
                                                    }}
                                                />
                                            </TableButton>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </TableContent>
                    </Table>
                    {showSuccessModal && <SuccessModal type="success" msg="Bid Created" />}
                </>
            )}
            {bidLineQuoteModalId && (
                <Modal
                    title="Quote"
                    onClose={() => {
                        setBidLineQuoteModalId(null)
                        setBidLIneQuoteLimit(0)
                    }}
                >
                    <form onSubmit={handleBidLineQuoteSubmit}>
                        <InputWithError
                            label="Price"
                            placeholder="Price"
                            name="price"
                            type="number"
                            step="0.01"
                            onChange={handleBidLineInputChange}
                            value={bidLineQuoteForm.price}
                        />
                        <InputWithError
                            label="Promised Date"
                            placeholder="Date"
                            name="promised_date"
                            type="date"
                            onChange={handleBidLineInputChange}
                            value={bidLineQuoteForm.promised_date}
                        />
                        {auctionData.partial_quantity_bidding && (
                            <Counter
                                label="Quantity"
                                placeholder="Quantity"
                                name="bid_quantity"
                                limit={bidLineQuoteLimit}
                                onChange={handleBidLineInputChange}
                                value={bidLineQuoteForm.bid_quantity}
                            />
                        )}
                        <br />
                        <Button text="Add" type="submit" />
                    </form>
                </Modal>
            )}
        </SupplierLayout>
    )
}

export default SupplierProductRequest
