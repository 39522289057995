import React from 'react'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Card from '../../components/Card/Card'
import { useState, useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import ProductAPI from '../../api/ProductAPI'
import Button from '../../components/Button/Button'
import SideSub from './SideSub'
import styles from './Org.module.scss'
import InputWithError from '../../components/InputWithError/InputWithError'
import Filter from '../../components/Filter/Filter'
import { toast } from 'react-toastify'
import OrganizationAPI from '../../api/OrganizationAPI'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import AuthAPI from '../../api/AuthAPI'
import Loader from '../../components/Loader/Loader'
import FormSelect from '../../components/FormSelect/FormSelect'

function Org() {
    const [selectedTab, setSelectedTab] = useState('Buyer')
    const [isEditable, setIsEditable] = useState(false)
    const { user } = useContext(AuthContext)
    console.log(user)
    const [form, setForm] = useState({
        name: user.organizationData.name,
        address_line1: user.organizationData.address_line1,
        address_line2: user.organizationData.address_line2,
        city: user.organizationData.city,
        country: user.organizationData.country,
        state: user.organizationData.state,
        postal_code: user.organizationData.postal_code,
        default_currency: user.organizationData.default_currency,
        contact: user.organizationData.contact,
        email: user.organizationData.email,
        additional_attributes: [],
    })

    const updateOrg = useMutation(OrganizationAPI.updateOrganizationById, {
        onError: (err) => {
            console.log(err.response.data?.message)
            toast.error(err.response.data?.message)
        },
        onSuccess: () => {
            toast.success('User has been updated successfully')
        },
    })

    const countryChoicesQuery = useQuery(['country-choices'], AuthAPI.getCountryChoices, {
        select: (data) => {
            data = data?.data
            const list = {}
            const countryList = {}
            const dialCode = {}
            Object.keys(data).forEach((code) => {
                dialCode[`${data[code].name} ${data[code].dial_code}`] = code.dial_code
            })
            Object.keys(data).forEach((code) => {
                countryList[data[code].name] = code
            })
            list['countryList'] = countryList
            list['dialCode'] = dialCode
            list['data'] = data
            console.log(list)
            return list
        },
    })
    const [poc, setPoc] = useState({})

    const handleOrganizationSubmit = (e) => {
        e.preventDefault()
        console.log(form)
        updateOrg.mutate({ token: user.token, id: user.organizationData.id, data: form })
        setIsEditable(false)
    }

    const handlePOCSubmit = (e) => {
        e.preventDefault()
        console.log(poc)
    }

    const handleChange = (value) => {
        setSelectedTab(value)
    }

    const handleEditClick = (e) => {
        e.preventDefault()
        setIsEditable(true)
    }
    let content

    switch (selectedTab) {
        case 'Buyer':
            content = (
                <div className={styles.Tab__Content}>
                    <Card>
                        {countryChoicesQuery.isError ? (
                            <Loader refresh height="30vh" />
                        ) : countryChoicesQuery.isLoading ? (
                            <Loader height="30vh" />
                        ) : (
                            <form onSubmit={handleOrganizationSubmit}>
                                <div className={styles.Tab__Sectwo}>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="Name"
                                            name="Name"
                                            placeholder="Enter Buyer name"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.name}
                                            onChange={(e) => setForm({ ...form, name: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="Email"
                                            name="Email"
                                            placeholder="Enter Email"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.email}
                                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.Tab__Sectwo}>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <FormTextarea
                                            label="Address Line 1"
                                            name="Address Line 1"
                                            placeholder="Enter Address Line 1"
                                            type="text"
                                            disabled={!isEditable}
                                            minRows={2}
                                            value={form.address_line1}
                                            onChange={(e) => setForm({ ...form, address_line1: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <FormTextarea
                                            label="Address Line 2"
                                            name="Address Line 2"
                                            placeholder="Enter Address Line 2"
                                            type="text"
                                            disabled={!isEditable}
                                            minRows={2}
                                            value={form.address_line2}
                                            onChange={(e) => setForm({ ...form, address_line2: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.Tab__Sectwo}>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="City"
                                            name="City"
                                            placeholder="Enter City"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.city}
                                            onChange={(e) => setForm({ ...form, city: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <FormSelect
                                            label="Country"
                                            name="Country"
                                            placeholder="Enter Country"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.country}
                                            onChange={(e) => setForm({ ...form, country: e.target.value })}
                                            options={countryChoicesQuery?.data.countryList}
                                        />
                                    </div>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="State"
                                            name="State"
                                            placeholder="Enter State"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.state}
                                            onChange={(e) => setForm({ ...form, state: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.Tab__Secthree}>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="Postal Code"
                                            name="Postal Code"
                                            placeholder="Enter Postal Code"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.postal_code}
                                            onChange={(e) => setForm({ ...form, postal_code: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="Default Currency"
                                            name="Default Currency"
                                            placeholder="Enter Default Currency"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.default_currency}
                                            onChange={(e) => setForm({ ...form, default_currency: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.Tab__Sectwo__row}>
                                        <InputWithError
                                            label="Contact"
                                            name="Contact"
                                            placeholder="Enter Contact"
                                            type="text"
                                            disabled={!isEditable}
                                            value={form.contact}
                                            onChange={(e) => setForm({ ...form, contact: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.Btn}>
                                    {isEditable ? (
                                        <Button text="Save changes" type="submit" loading={updateOrg.isLoading} />
                                    ) : (
                                        <Button text="Edit" onClick={handleEditClick} />
                                    )}
                                </div>
                            </form>
                        )}
                    </Card>
                </div>
            )
            break
        case 'Person of Contact':
            content = (
                <div className={styles.Tab__Content}>
                    <Card>
                        <form onSubmit={handlePOCSubmit}>
                            <InputWithError
                                label="Name"
                                name="Name"
                                placeholder="Enter Name"
                                type="text"
                                value={poc.name}
                                onChange={(e) => setPoc({ ...poc, name: e.target.value })}
                            />
                            <InputWithError
                                label="Email"
                                name="Email"
                                placeholder="Enter Email"
                                type="text"
                                value={poc.email}
                                onChange={(e) => setPoc({ ...poc, email: e.target.value })}
                            />
                            <InputWithError
                                label="Phone"
                                name="Phone"
                                placeholder="Enter Phone"
                                type="text"
                                value={poc.phone}
                                onChange={(e) => setPoc({ ...poc, phone: e.target.value })}
                            />
                            <div className={styles.Btn}>
                                <Button text="Save changes" type="submit" />
                            </div>{' '}
                        </form>
                    </Card>
                </div>
            )
            break
        default:
            content = null
    }
    return (
        <OrganizationLayout active="settings" title="Settings">
            <div className={styles.orgSettings}>
                <div>
                    <SideSub />
                </div>
                <div className={styles.Tab}>
                    <Filter
                        className={styles.Filter}
                        filter={selectedTab}
                        options={['Buyer', 'Person of Contact']}
                        onChange={handleChange}
                    />
                    <div>{content}</div>
                </div>
            </div>
        </OrganizationLayout>
    )
}

export default Org
