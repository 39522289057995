// styles
import styles from './ProductDetails.module.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// packages
import { useParams, useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation, Pagination, A11y } from 'swiper/modules'
import { useState } from 'react'
import { useRef } from 'react'
import cx from 'classnames'

// components
import Card from '../../components/Card/Card'
import Loader from '../../components/Loader/Loader'

import ProductAPI from '../../api/ProductAPI'
import MeticsLogo from '../../assets/images/Metics-blue 1.png'

const ProductDetails = () => {
    const { id } = useParams()
    const swiperRef = useRef(null)
    const location = useLocation()
    const supplier = location.state
    const navigate = useNavigate()
    const [activeImage, setActiveImage] = useState(0)

    const getProductQuery = useQuery(['product', id], () => ProductAPI.getProduct({ id }))
    // mutation to delete product

    const productData = getProductQuery.data?.data

    const handleNavigate = () => {
        const { name, list_price_per_unit, description, images } = productData
        const data = { name, list_price_per_unit, description, images }
        navigate(`/supplier/products/edit/${id}`, { state: { data } })
    }

    //TODO: update image source to hide the port number

    return (
        <div className={styles.Container}>
            <div className={styles.LogoContainer}>
                <img className={styles.Logo} src={MeticsLogo} alt="logo" />
            </div>
            <div className={styles.Header}>
                <h1 className={styles.Header__title}>Products</h1>
                <h1 className={styles.Header__title}>{supplier}</h1>
            </div>
            <div className={styles.Content}>
                {getProductQuery.isError ? (
                    <Loader refresh />
                ) : getProductQuery.isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className={styles.Product__contentWrapper}>
                            <div className={styles.Product__imagesWrapper}>
                                <Card className={styles.Images}>
                                    <div className={styles.Product__mainImageWrapper}>
                                        <img
                                            alt="product"
                                            src={
                                                process.env.REACT_APP_API_BASE_URL +
                                                productData.images[activeImage]?.image
                                            }
                                            className={styles.Product__mainImage}
                                        />
                                    </div>
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={10}
                                        slidesPerView={3}
                                        ref={swiperRef}
                                        navigation
                                        loop
                                        onSlideChange={(swiper) => setActiveImage(swiper.realIndex)}
                                        centeredSlides
                                    >
                                        {productData.images.map((img, idx) => (
                                            <SwiperSlide key={idx}>
                                                {({ isActive }) => {
                                                    return (
                                                        <div
                                                            className={cx(
                                                                styles.Product__imageWrapper,
                                                                isActive && styles.Product__imageWrapper__active
                                                            )}
                                                        >
                                                            <img
                                                                alt="product"
                                                                src={process.env.REACT_APP_API_BASE_URL + img.image}
                                                                className={styles.Product__image}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Card>
                            </div>
                            <Card className={styles.Product__detailsWrapper}>
                                <h2 className={styles.Product__detailsTitle}>{productData.name}</h2>
                                <p className={styles.Product__details}>Category</p>
                                <p className={styles.Product__detailsValue}>{productData.product_sub_category}</p>
                                <p className={styles.Product__details}>Price</p>
                                <p className={styles.Product__detailsValue}>${productData.list_price_per_unit}</p>
                                <p className={styles.Product__details}>Availble</p>
                                <p className={styles.Product__detailsValue}>
                                    {productData.is_available ? 'Yes' : 'No'}
                                </p>
                                <p className={styles.Product__details}>Description</p>
                                <p className={styles.Product__detailsValue}>{productData.description}</p>
                            </Card>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductDetails
