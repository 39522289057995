import React from 'react'
import styles from './SupplierDashboard.module.scss'
import SeaIcon from '../../assets/icons/sea.svg'
import { useNavigate } from 'react-router'
import { useState, useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Welcome from '../Welcome/Welcome'
import Card from '../../components/Card/Card'
import TableControls from '../../components/Table/TableControls/TableControls'
import Filter from '../../components/Filter/Filter'
import TableContent from '../../components/Table/TableContent/TableContent'
import moment from 'moment'
import RightIcon from '../../assets/icons/dash/righticon.svg'
import LeftIcon from '../../assets/icons/dash/lefticon.svg'
import addRfq from '../../assets/icons/dash/createrfq.svg'
import currency from '../../assets/icons/dash/currency.svg'
import newSupplier from '../../assets/icons/dash/newSupplier.svg'
import FundTransfer from '../../assets/icons/dash/fundTransfer.svg'
import MoneyRecieve from '../../assets/icons/dash/MoneyRecieve.svg'
import SendMoney from '../../assets/icons/dash/sendmoney.svg'
import SplitBills from '../../assets/icons/dash/splitbills.svg'
import InterFundTransfer from '../../assets/icons/dash/internationalFT.svg'
import CustomeDate from '../../assets/icons/dash/coustomdate.svg'
import Sort from '../../assets/icons/dash/sort.svg'
import Transaction from '../../assets/icons/dash/transaction.svg'
import vieworders from '../../assets/icons/dash/vieworders.svg'
import viewbids from '../../assets/icons/dash/viewbids.svg'
import newClient from '../../assets/icons/dash/newSupplier.svg'
import productAdd from '../../assets/icons/dash/productAdd.svg'
import documentAdd from '../../assets/icons/dash/documentAdd.svg'

import SupplierAPI from '../../api/SupplierAPI'
import { useQuery } from '@tanstack/react-query'
import Badge from '../../components/Badge/Badge'
import Button from '../../components/Button/Button'

function SupplierDashboard() {
    const { user } = useContext(AuthContext)
    const [filter, setFilter] = useState('Today')
    const [selectedMonth, setSelectedMonth] = useState('')

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    }
    const activityLogData = useQuery(['activityLog'], () => SupplierAPI.getActivityLog({ token: user.token }), {
        select: (data) => {
            return {
                data: data?.data?.map((log) => ({
                    ...log,
                    created_at: moment(log.created_at).fromNow(),
                    updated_at: moment(log.updated_at).format('DD/MM/YYYY'),
                    description: log.description.replace('was created', ''),
                })),
            }
        },
    })

    const getDashboardData = useQuery(
        ['getDashboardData'],
        () => SupplierAPI.getSupplierDashboard({ token: user.token }),
        {
            select: (data) => {
                const last_five_auctions = data?.data?.last_five_auctions?.map((auction) => ({
                    id: auction.id,
                    title: auction.title,
                    requisition_number: auction.requisition_number,
                    buyer: auction.organisation_name,
                    need_by_date: moment(auction.need_by_date).format('DD/MM/YYYY'),
                    product_category: auction.product_category_name,
                    is_open: auction.is_open ? (
                        <Badge color="#1BBB6B" backgroundColor="#EDF8F3" value="Open" />
                    ) : (
                        <Badge color="#FC555B" backgroundColor="#FED7D8" value="Closed" />
                    ),
                }))
                return {
                    ...data?.data,
                    last_five_auctions,
                }
            },
        }
    )

    const navigate = useNavigate()
    const navigateToDetailPage = (auctionId) => {
        navigate(`/supplier/requests/${auctionId}`)
    }
    const handleFilterChange = (value) => setFilter(value)

    return (
        <SupplierLayout active="dashboard" title="Dashboard">
            {!user.welcome_screen_seen && <Welcome />}
            <>
                <div className={styles.Dashboard}>
                    <h1 className="ms-4">Overview</h1>
                    <div className={styles.Dashboard__select}>
                        <select
                            className={styles.Dashboard__select__value}
                            id="months"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                        >
                            <option value="">Select</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </select>
                    </div>
                    <div className="ms-auto">
                        <img src={LeftIcon} alt="" className="me-3" />
                        <img src={RightIcon} alt="" />
                    </div>
                </div>

                <div className="d-flex">
                    <Card className={styles.Onboard} onClick={() => navigate('/supplier/requests')}>
                        <p className="h4 text-muted">Total Active Requests</p>
                        <div className="d-flex gap-4">
                            <h1>{getDashboardData?.data?.total_auctions_count || 0} </h1>
                            <Badge value="+ 8.5%" color="#1BBB6B" backgroundColor="#EDF8F3" />
                        </div>
                        <div className="d-flex">
                            <h4>Completed</h4>
                            <strong className="text-success ms-3 h4">{0}</strong>
                        </div>
                    </Card>
                    <Card className={styles.Onboard} onClick={() => navigate('/supplier/clients')}>
                        <p className="h4 text-muted">Total Clients</p>
                        <div className="d-flex gap-4">
                            <h1>{getDashboardData?.data?.clients_count || 0} </h1>
                            <Badge value="+ 8.5%" color="#1BBB6B" backgroundColor="#EDF8F3" />
                        </div>
                        <div className="d-flex">
                            <h4>Recently Added</h4>
                            <strong className="text-success ms-3 h4">{0}</strong>
                        </div>
                    </Card>
                    <Card className={styles.Onboard} onClick={() => navigate('/supplier/purchaseorder')}>
                        <p className="h4 text-muted">Purchase Orders</p>
                        <div className="d-flex gap-4">
                            <h1>{getDashboardData?.data?.supplier_purchase_orders_count || 0}</h1>
                            <Badge value="-2.5%" color="#FC555B" backgroundColor="#FED7D8" />
                        </div>
                        <div className="d-flex">
                            <h4>Completed</h4>
                            <strong className="text-success ms-3 h4">
                                {getDashboardData?.data?.completed_purchase_orders_count || 0}
                            </strong>
                        </div>
                    </Card>
                </div>

                <div className={styles.section}>
                    <div className={styles.section__action}>
                        <h1 className="ms-4">Quick action</h1>
                        <div className="d-flex">
                            <Card className={styles.Onboard__card} onClick={() => navigate('/supplier/requests')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={addRfq} alt="" />
                                    <h4 className="m-0">View Requests</h4>
                                </div>
                            </Card>
                            <Card className={styles.Onboard__card} onClick={() => navigate('/supplier/purchaseorder')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={vieworders} alt="" />
                                    <h4 className="m-0">View Purchase Orders</h4>
                                </div>
                            </Card>
                        </div>
                        <div className="d-flex">
                            <Card className={styles.Onboard__card} onClick={() => navigate('/supplier/bids')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={viewbids} alt="" />
                                    <h4 className="m-0">View Bids</h4>
                                </div>
                            </Card>
                            <Card className={styles.Onboard__card} onClick={() => navigate('/supplier/clients')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={newClient} alt="" />
                                    <h4 className="m-0"> Add New Client</h4>
                                </div>
                            </Card>
                        </div>
                        <div className="d-flex">
                            <Card className={styles.Onboard__card} onClick={() => navigate('/supplier/products/add')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={productAdd} alt="" />
                                    <h4 className="m-0">Add Product</h4>
                                </div>
                            </Card>
                            <Card className={styles.Onboard__card} onClick={() => navigate('/supplier/documents')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={documentAdd} alt="" />
                                    <h4 className="m-0">Add Document</h4>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className={styles.section__updates}>
                        <div className="d-flex align-items-center justify-content-between">
                            <h1>Recent Updates</h1>
                            <h5 className={styles.ViewAll} onClick={() => navigate('/supplier/updates')}>
                                View All
                            </h5>
                        </div>
                        <Card className={styles.section__updates__card}>
                            {activityLogData.data?.data?.length === 0 && (
                                <h5 className="text-center">No recent updates found</h5>
                            )}
                            {activityLogData?.data?.data?.map((log, index) => (
                                <div className={`${styles.logEntry} b`} key={index}>
                                    <div className="d-flex justify-content-between">
                                        <h3 className={styles.logType}>{log.activity_type}</h3>
                                        <h5 className={styles.logDate}>{log.created_at}</h5>
                                    </div>
                                    <h5 className={styles.logDescription}>{log.description}</h5>
                                </div>
                            ))}
                        </Card>
                    </div>
                </div>

                <div className="d-flex mt-5">
                    <h1>Request History</h1>
                </div>
                <Card>
                    <TableControls>
                        <div className={styles.Rfq__tableControls}>
                            <div className={styles.Rfq__tableControlsLeft}>
                                <Filter
                                    filter={filter}
                                    options={['Today', 'This Week', 'This Month']}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className={styles.Rfq__row}>
                                <button className={styles.Rfq__btns}>
                                    <img src={CustomeDate} alt="" />
                                    <h5 className="mt-3">Custom Date</h5>
                                </button>
                                <Button
                                    text="View All Requests"
                                    color="rgb(14, 44, 36)"
                                    onClick={() => navigate('/supplier/requests')}
                                />
                            </div>
                        </div>
                    </TableControls>
                    <TableContent hoverable>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Title</th>
                                <th>Reference Number</th>
                                <th>Buyer</th>
                                <th>Need By Date</th>
                                <th>Product Category</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getDashboardData?.data?.last_five_auctions?.length === 0 ? (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                        No data found
                                    </td>
                                </tr>
                            ) : (
                                getDashboardData?.data?.last_five_auctions?.map((row, index) => (
                                    <tr key={row.id} onClick={() => navigateToDetailPage(row.id)}>
                                        <td>{index + 1}</td>
                                        {Object.entries(row)
                                            .filter(([key]) => key !== 'id')
                                            .map(([key, value], columnIndex) => (
                                                <td key={columnIndex}>{value}</td>
                                            ))}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </TableContent>
                </Card>
            </>
        </SupplierLayout>
    )
}

export default SupplierDashboard
