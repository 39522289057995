import { useEffect, useState } from 'react'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import styles from './OrganizationDashboardHome.module.scss'
import Card from '../../components/Card/Card'
import Filter from '../../components/Filter/Filter'
import RightIcon from '../../assets/icons/dash/righticon.svg'
import LeftIcon from '../../assets/icons/dash/lefticon.svg'
import addRfq from '../../assets/icons/dash/createrfq.svg'
import currency from '../../assets/icons/dash/currency.svg'
import newSupplier from '../../assets/icons/dash/newSupplier.svg'
import MoneyRecieve from '../../assets/icons/dash/MoneyRecieve.svg'
import SplitBills from '../../assets/icons/dash/splitbills.svg'
import InterFundTransfer from '../../assets/icons/dash/internationalFT.svg'
import CustomeDate from '../../assets/icons/dash/coustomdate.svg'
import Sort from '../../assets/icons/dash/sort.svg'
import Transaction from '../../assets/icons/dash/transaction.svg'
import vieworders from '../../assets/icons/dash/vieworders.svg'
import newuser from '../../assets/icons/dash/newuser.svg'

// api imports
import OrganizationAPI from '../../api/OrganizationAPI'
import { useNavigate } from 'react-router-dom'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControls from '../../components/Table/TableControls/TableControls'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import Badge from '../../components/Badge/Badge'
import Welcome from '../Welcome/Welcome'
import Button from '../../components/Button/Button'

const OrganizationDashboardHome = () => {
    const { user } = useContext(AuthContext)
    const [filter, setFilter] = useState('Today')
    const [selectedMonth, setSelectedMonth] = useState('')

    const activityLogData = useQuery(['activityLog'], () => OrganizationAPI.getActivityLog({ token: user.token }), {
        select: (data) => {
            return {
                data: data?.data?.map((log) => ({
                    ...log,
                    created_at: moment(log.created_at).fromNow(),
                    updated_at: moment(log.updated_at).format('DD/MM/YYYY'),
                    description: log.description.replace('was created', ''),
                })),
            }
        },
    })

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    }

    const getDashboardData = useQuery(['dashboard'], () => OrganizationAPI.getDashboardData({ token: user.token }), {
        select: (data) => {
            const auctions = data?.data?.auctions.map((auction) => ({
                title: auction.title,
                reference: auction.requisition_number,
                date: moment(auction.created_at).format('DD/MM/YYYY'),
                'due date': moment(auction.need_by_date).format('DD/MM/YYYY'),
                replies: auction.bid_count,
                'last updated': moment(auction.updated_at).format('DD/MM/YYYY'),
                status: auction.status,
                id: auction.id,
            }))
            return {
                ...data?.data,
                auctions,
            }
        },
    })

    const navigate = useNavigate()
    const navigateToDetailPage = (auctionId, status) => {
        navigate(`/organization/rfq/${status === 'draft' ? 'edit' : 'detail'}/${auctionId}`)
    }
    const handleFilterChange = (value) => setFilter(value)

    return (
        <OrganizationLayout active="dashboard" title="Dashboard">
            {!user.welcome_screen_seen && <Welcome />}
            <>
                <div className={styles.Dashboard}>
                    <h1 className="ms-4">Overview</h1>
                    {/* <div className={styles.Dashboard__select}>
                        <select
                            className={styles.Dashboard__select__value}
                            id="months"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                        >
                            <option value="">Select</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </select>
                    </div> */}
                    {/* <div className="ms-auto">
                        <img src={LeftIcon} alt="" className="me-3" />
                        <img src={RightIcon} alt="" />
                    </div> */}
                </div>

                <div className="d-flex">
                    <Card className={styles.Onboard} onClick={() => navigate('/organization/rfq')}>
                        <p className="h4 text-muted">Total Active RFQs</p>
                        <div className="d-flex gap-4 ">
                            <h1>{getDashboardData?.data?.total_rfq_count || 0} </h1>
                            <Badge value="+ 5.2%" color="#1BBB6B" backgroundColor="#EDF8F3" />
                        </div>
                        <div className="d-flex">
                            <h4>Completed</h4>
                            <strong className="text-success ms-3 h4">
                                {getDashboardData?.data?.completed_auctions_count || 0}
                            </strong>
                        </div>
                    </Card>
                    <Card className={styles.Onboard} onClick={() => navigate('/organization/supplier')}>
                        <p className="h4 text-muted">Total Suppliers</p>
                        <div className="d-flex gap-4">
                            <h1>{getDashboardData?.data?.total_suppliers_count || 0} </h1>
                            <Badge value="- 7.2%" color="#FC555B" backgroundColor="#FED7D8" />
                        </div>
                        <div className="d-flex">
                            <h4>Recently Added</h4>
                            <strong className="text-success ms-3 h4">
                                {getDashboardData?.data?.recently_added_suppliers_count || 0}
                            </strong>
                        </div>
                    </Card>
                    <Card className={styles.Onboard} onClick={() => navigate('/organization/purchaseorder')}>
                        <p className="h4 text-muted">Purchase Orders</p>
                        <div className="d-flex gap-4">
                            <h1>{getDashboardData?.data?.total_purchase_orders_count || 0}</h1>
                            <Badge value="+ 5.2%" color="#1BBB6B" backgroundColor="#EDF8F3" />
                        </div>
                        <div className="d-flex">
                            <h4>Completed</h4>
                            <strong className="text-success ms-3 h4">
                                {getDashboardData?.data?.completed_purchase_orders_count || 0}
                            </strong>
                        </div>
                    </Card>
                </div>

                <div className={styles.section}>
                    <div className={styles.section__action}>
                        <h1 className="ms-4">Quick action</h1>
                        <div className="d-flex">
                            <Card
                                className={styles.Onboard__card}
                                onClick={() => navigate('/organization/rfq/create_new')}
                            >
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={addRfq} alt="" />
                                    <h4 className="m-0">Create New RFQ</h4>
                                </div>
                            </Card>
                            <Card
                                className={styles.Onboard__card}
                                onClick={() => navigate('/organization/purchaseorder')}
                            >
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={vieworders} alt="" />
                                    <h4 className="m-0">View Purchase Orders</h4>
                                </div>
                            </Card>
                        </div>
                        <div className="d-flex">
                            <Card className={styles.Onboard__card} onClick={() => navigate('/organization/supplier')}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={newSupplier} alt="" />
                                    <h4 className="m-0">Add New Supplier</h4>
                                </div>
                            </Card>
                            <Card
                                className={styles.Onboard__card}
                                onClick={() => navigate('/organization/users/create')}
                            >
                                <div className="d-flex gap-2 align-items-center">
                                    <img src={newuser} alt="" />
                                    <h4 className="m-0"> Add New User</h4>
                                </div>
                            </Card>
                        </div>
                        <div className="d-flex">
                            <Card className={styles.Onboard__card}>
                                <div className="d-flex gap-4 align-items-center">
                                    <img src={SplitBills} alt="" />
                                    <h4 className="m-0">Split bills</h4>
                                </div>
                            </Card>
                            <Card className={styles.Onboard__card}>
                                <div className="d-flex gap-2 align-items-center">
                                    <img src={InterFundTransfer} alt="" />
                                    <h4 className="m-0">International fund transfer</h4>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className={styles.section__updates}>
                        <div className="d-flex align-items-center justify-content-between">
                            <h1>Recent Updates</h1>
                            <h5 className={styles.ViewAll} onClick={() => navigate('/organization/updates')}>
                                View All
                            </h5>
                        </div>
                        <Card className={styles.section__updates__card}>
                            {activityLogData.data?.data?.length === 0 && (
                                <h5 className="text-center">No recent updates found</h5>
                            )}
                            {activityLogData.data?.data?.map((log, index) => (
                                <div className={`${styles.logEntry} b`} key={index}>
                                    <div className="d-flex justify-content-between">
                                        <h3 className={styles.logType}>{log.activity_type}</h3>
                                        <h5 className={styles.logDate}>{log.created_at}</h5>
                                    </div>
                                    <h5 className={styles.logDescription}>{log.description}</h5>
                                </div>
                            ))}
                        </Card>
                    </div>
                </div>

                <div className="d-flex mt-5 align-items-center">
                    <h1>RFQ History</h1>
                </div>
                <Card>
                    <TableControls>
                        <div className={styles.Rfq__tableControls}>
                            <div className={styles.Rfq__tableControlsLeft}>
                                <Filter
                                    filter={filter}
                                    options={['Today', 'This Week', 'This Month']}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className={styles.Rfq__row}>
                                <Button
                                    className={styles.Rfq__btns}
                                    text="Custom Date"
                                    icon={CustomeDate}
                                    type="date"
                                />
                                <Button
                                    text="View All RFQs"
                                    color="rgb(14, 44, 36)"
                                    onClick={() => navigate('/organization/rfq')}
                                />
                            </div>
                        </div>
                    </TableControls>
                    <TableContent hoverable>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Title</th>
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Due Date</th>
                                <th>Replies</th>
                                <th>Last Updated</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getDashboardData?.data?.auctions?.length === 0 ? (
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center' }}>
                                        No RFQs found
                                    </td>
                                </tr>
                            ) : (
                                getDashboardData?.data?.auctions?.map((row, index) => (
                                    <tr key={row.id} onClick={() => navigateToDetailPage(row.id, row.Status)}>
                                        <td>{index + 1}</td>
                                        {Object.entries(row)
                                            .filter(([key]) => key !== 'id')
                                            .map(([key, value], columnIndex) => (
                                                <td key={columnIndex}>
                                                    {key === 'status' ? (
                                                        <>
                                                            {value.toLowerCase() === 'in-progress' && (
                                                                <Badge
                                                                    value={value}
                                                                    color="#1BBB6B"
                                                                    backgroundColor="#EDF8F3"
                                                                />
                                                            )}
                                                            {value.toLowerCase() === 'draft' && (
                                                                <Badge
                                                                    value={value}
                                                                    color="#f7a64f"
                                                                    backgroundColor="#fdebd8"
                                                                />
                                                            )}
                                                            {value.toLowerCase() === 'completed' && (
                                                                <Badge
                                                                    value={value}
                                                                    color="#FC555B"
                                                                    backgroundColor="#FED7D8"
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        value
                                                    )}
                                                </td>
                                            ))}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </TableContent>
                </Card>
            </>
        </OrganizationLayout>
    )
}

export default OrganizationDashboardHome
