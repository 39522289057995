import styles from './FormCheckbox.module.scss'
import { v4 as uuidv4 } from 'uuid'

const FormCheckbox = ({ handleChange, onChange = () => {}, name, errorMsg = '', label, id }) => {
    const change = (e) => {
        onChange(e)
        if (handleChange) {
            const x = {
                target: {
                    name: e.target.name,
                    value: e.target.checked,
                },
            }
            handleChange(x)
        }
    }
    return (
        <div className={styles.FormCheckbox}>
            <div>
                <input className={styles.FormCheckbox__input} onChange={change} name={name} type="checkbox" id={id} />
                {label && (
                    <label className={styles.FormCheckbox__label} htmlFor={id}>
                        {label}
                    </label>
                )}
            </div>
            <span className={styles.FormCheckbox__msg}>{errorMsg}</span>
        </div>
    )
}

export default FormCheckbox
