import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // Import carousel styles
import AuthContext from '../../context/AuthContext'
import MeticsLogo from '../../assets/images/Metics-blue 1.png'
import HandshakeImage from '../../assets/images/handshake.png'
import styles from './Login.module.scss'
import { useContext } from 'react'
import InputWithError from '../../components/InputWithError/InputWithError'
import Button from '../../components/Button/Button'
import AuthAPI from '../../api/AuthAPI'
import OrganizationAPI from '../../api/OrganizationAPI'

const Login = () => {
    const [form, setForm] = useState({})
    const [spinnerActive, setSpinnerActive] = useState(false)
    const [loginType, setLoginType] = useState('organization')
    const [passwordError, setPasswordError] = useState('')
    const navigate = useNavigate()
    const { user, login, logout } = useContext(AuthContext)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (spinnerActive) return
        setSpinnerActive(true)
        setPasswordError('')

        try {
            const loginResponse = await AuthAPI.userLogin(form)
            const data = loginResponse.data
            const userDetailsResponse = await AuthAPI.getUserDetails(data.token)
            const userData = userDetailsResponse.data
            let organizationData = {}
            if (userData.organisation) {
                const organizationResponse = await OrganizationAPI.getOrganization(data.token)
                organizationData = organizationResponse.data
            }
            await login({
                ...data,
                ...userData,
                organizationData,
            })
            navigate(loginType === 'organization' ? '/organization/dashboard' : '/supplier/dashboard')
        } catch (err) {
            console.error(err)
            handleLoginError(err)
        } finally {
            setSpinnerActive(false)
        }
    }

    const handleLoginError = (err) => {
        const defaultErrorMessage = 'An unexpected error occurred.'
        if (err.response) {
            const { data } = err.response
            if (data.non_field_errors) {
                toast.error(data.non_field_errors[0])
                setPasswordError('Invalid Credentials')
                return
            }
            const fieldErrors = Object.entries(data).find(([key, value]) => key !== 'non_field_errors')
            if (fieldErrors) {
                const [field, errorMessages] = fieldErrors
                toast.error(`${field.toUpperCase()}: ${errorMessages.join(', ')}`)
                if (field === 'password') {
                    setPasswordError(errorMessages[0])
                }
                return
            }
        }
        toast.error(defaultErrorMessage)
    }

    const handleInputChange = (e) => {
        setForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    return (
        <div className={styles.Login}>
            <div className={styles.Login__sections}>
                <section className={styles.Login__sections__left}>
                    <div className={styles.Login__logoContainer}>
                        <img className={styles.Login__logo} src={MeticsLogo} alt="logo" />
                    </div>
                    <div className={styles.Login__contentContainer}>
                        <div className={styles.Login__welcomeContainer}>
                            <h1 className={styles.Login__title}>
                                {/* <h1 className={styles.Login__title__sub}>Welcome to Metics</h1> */}
                                <span className={styles.Login__title__main}>Welcome to Metics</span>
                            </h1>
                            <div className={styles.Login__toggleSwitchWrapper}>
                                <p className={styles.welcometext}> Login as</p>
                                <div
                                    className={`${styles.ToggleSwitch} ${
                                        loginType === 'supplier'
                                            ? styles['ToggleSwitch--supplierActive']
                                            : styles['ToggleSwitch--organizationActive']
                                    }`}
                                >
                                    <span onClick={() => setLoginType('supplier')}>Supplier</span>
                                    <span className={styles.buyer} onClick={() => setLoginType('organization')}>
                                        Buyer
                                    </span>
                                </div>
                            </div>
                        </div>
                        <form className={styles.Login__form} onSubmit={handleSubmit}>
                            <div className={styles.Login__formGroup}>
                                <InputWithError
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleInputChange}
                                    value={form.email}
                                />
                            </div>
                            <div className={styles.Login__formGroup}>
                                <InputWithError
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    onChange={handleInputChange}
                                    value={form.password}
                                    errorMsg={passwordError}
                                />
                            </div>
                            <div className={styles.Login__formRow}>
                                {/* <div className={`${styles.Login__formGroup} ${styles.Login__formRememberContainer}`}>
                                    <input
                                        className={styles.Login__formInput__checkbox}
                                        type="checkbox"
                                        id="remember-me"
                                    />
                                    <label htmlFor="remember-me">Remember me</label>
                                </div> */}
                                <Link className={styles.Login__formForgotPassword} to="/forgot-password">
                                    Forgot Password?
                                </Link>
                            </div>
                            <Button className={styles.submit} type="submit" text="Submit" loading={spinnerActive} />
                            <p className={styles.Login__formRegisterText}>
                                Don't have an account? <Link to="/register">Sign Up</Link>
                            </p>
                        </form>
                    </div>
                </section>
                <section className={styles.Login__sections__right}>
                    <div className={styles.Login__carouselContainer}>
                        <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators={false} showStatus={false}>
                            <div className={styles.Login__carouselSlide}>
                                {/* <img src={HandshakeImage} alt="Handshake" /> */}
                                <h1 className={styles.Login__carouselText}>Catalyzing your business needs</h1>
                                <p className={styles.Login__carouselDescription}>
                                    Metics helps you connect to a wide range of vendors and customers, streamlining your
                                    buying or selling process with our unbeatable business management tools.
                                </p>
                            </div>
                            <div className={styles.Login__carouselSlide}>
                                {/* <img src={HandshakeImage} alt="Partnership" /> */}
                                <h1 className={styles.Login__carouselText}>Empowering Your Network</h1>
                                <p className={styles.Login__carouselDescription}>
                                    Our platform enables you to build strong partnerships and optimize your supply chain
                                    for better efficiency and profitability.
                                </p>
                            </div>
                            <div className={styles.Login__carouselSlide}>
                                {/* <img src={HandshakeImage} alt="Growth" /> */}
                                <h1 className={styles.Login__carouselText}>Driving Business Growth</h1>
                                <p className={styles.Login__carouselDescription}>
                                    Leverage our tools to drive growth, enhance your market presence, and achieve your
                                    business goals seamlessly.
                                </p>
                            </div>
                        </Carousel>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Login
