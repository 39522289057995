import React, { useState } from 'react'
import styles from './Filter.module.scss'

const Filter = ({ filter, options, onChange, className }) => {
    return (
        <div className={styles.FilterContainer}>
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`${styles.Tab__Items} ${filter === option && styles.Tab__Selected} ${className}`}
                    onClick={() => onChange(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    )
}

export default Filter
