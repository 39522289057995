import { useState } from 'react'
import Button from '../../../components/Button/Button'
import InputWithError from '../../../components/InputWithError/InputWithError'
import Modal from '../../../components/Modal/Modal'
import AttachmentTable from '../AttachmentTable/AttachmentTable'
import styles from './AddLineModal.module.scss'
import cx from 'classnames'
import LineAttachmentModal from '../LineAttachmentModal/LineAttachmentModal'
import { v4 as uuidv4 } from 'uuid'
import FormSelect from '../../../components/FormSelect/FormSelect'

const AddLineModal = ({
    onClose,
    handleSubmit = () => {},
    initialErrors = {},
    setPageNumber,
    measurements,
    isLoading,
}) => {
    const [attachementData, setAttachmentData] = useState([])
    const [attachmentModalActive, setAttachmentModalActive] = useState(false)
    const [errors, setErrors] = useState(initialErrors)

    const [form, setForm] = useState({
        additional_attributes: [],
        uom_code: 'EA',
    })

    const handleAttachmentModalSubmit = (data) => {
        setAttachmentData((prev) => [...prev, data])
        setAttachmentModalActive(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        if ((name === 'target_price' || name === 'quantity') && !/^\d*$/.test(value)) {
            return
        }
        setForm((prev) => ({ ...prev, [name]: value }))

        if (value) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()

        let newErrors = {}
        let isValid = true

        if (!form.product_name || form.product_name.trim() === '') {
            newErrors.product_name = 'Name is required'
            isValid = false
        }

        if (!form.quantity || form.quantity.trim() === '') {
            newErrors.quantity = 'Quantity is required'
            isValid = false
        }

        if (!form.uom_code || form.uom_code.trim() === '') {
            newErrors.uom_code = 'UOM is required'
            isValid = false
        }

        if (!isValid) {
            setErrors(newErrors)
            return
        }

        handleSubmit({ id: uuidv4(), ...form, attachments: attachementData })
    }

    const handleAttachmentTableRemove = (id) => {
        setAttachmentData(attachementData.filter((d) => d.file.info?.id !== id))
    }

    return (
        <>
            <Modal title="Add Item" onClose={onClose}>
                <form onSubmit={handleFormSubmit}>
                    <div className={cx(styles.CreateRFQ__FormWrapper, styles['row'])}>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="product_name"
                                label="Name"
                                placeholder="Name"
                                type="text"
                                value={form.product_name}
                                errorMsg={errors.product_name}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="product_id"
                                label="Product Id"
                                placeholder="Product Id (if any)"
                                type="text"
                                value={form.product_id}
                                errorMsg={errors.product_id}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="target_price"
                                label="Price Per Item"
                                placeholder="Price Per Item (Optional)"
                                type="text"
                                value={form.target_price}
                                errorMsg={errors.target_price}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="quantity"
                                label="Quantity"
                                placeholder="Quantity"
                                type="text"
                                value={form.quantity}
                                errorMsg={errors.quantity}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="brand"
                                label="Brand"
                                placeholder="Brand"
                                type="text"
                                value={form.brand}
                                errorMsg={errors.brand}
                            />
                        </div>
                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                            <FormSelect
                                onChange={handleChange}
                                name="uom_code"
                                label="Unit Of Measurement"
                                placeholder="UOM"
                                type="text"
                                options={measurements}
                                value={form.uom_code}
                                errorMsg={errors.uom_code}
                            />
                        </div>

                        <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-12'])}>
                            <InputWithError
                                onChange={handleChange}
                                name="product_description"
                                label="Comment"
                                placeholder="comment"
                                type="text"
                                value={form.product_description}
                                errorMsg={errors.product_description}
                            />
                        </div>

                        <div className={styles.CreateRFQ__attachmentsWrapper}>
                            <h2 className={styles.CreateRFQ__tertiaryTitle}>Attachments</h2>
                            <p className={styles.CreateRFQ__note}>
                                <strong>NOTE:</strong> Attachments should be in PDF format and must not exceed 10 MB in
                                size.
                            </p>
                            <AttachmentTable data={attachementData} handleDelete={handleAttachmentTableRemove} />
                            <div className={styles.CreateRFQ__buttonWrapper}>
                                <br />
                                <div className={styles.CreateRFQ__buttonGroup}>
                                    <Button
                                        text="Upload Attachment"
                                        color="rgb(14, 44, 36)"
                                        onClick={() => setAttachmentModalActive(true)}
                                    />
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className={styles.modal_actionButton}>
                        <Button
                            text="Save"
                            type="submit"
                            className={styles.CreateRFQ__fullWidthButton}
                            loading={isLoading}
                        />
                        <Button text="Cancel" className={styles.CancelBtn} onClick={onClose} />
                    </div>
                </form>
            </Modal>

            {attachmentModalActive && (
                <LineAttachmentModal
                    onClose={() => setAttachmentModalActive(false)}
                    handleSubmit={handleAttachmentModalSubmit}
                    errors={{}}
                />
            )}
        </>
    )
}

export default AddLineModal
