import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierProductRequests.module.scss'
import AuthContext from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import SupplierAPI from '../../api/SupplierAPI'
import moment from 'moment/moment'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import Badge from '../../components/Badge/Badge'
import Table from '../../components/Table/Table'
import TableControls from '../../components/Table/TableControls/TableControls'
import Button from '../../components/Button/Button'
import Filter from '../../components/Filter/Filter'
import TableContent from '../../components/Table/TableContent/TableContent'
import SupplierCard from '../SupplierRFQ/SupplierCard/SupplierCard'
import SeaIcon from '../../assets/icons/sea.svg'
import FormSelect from '../../components/FormSelect/FormSelect'

const SupplierProductRequests = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [filter, setFilter] = useState('All')
    const [org, setOrg] = useState('')
    const [month, setMonth] = useState('')
    const [search, setSearch] = useState('')
    const [auctions, setAuctions] = useState([])

    const res = useQuery(['auctions'], () => SupplierAPI.getAuctions(user.token))

    const bids = res.data?.data || {}
    // a variable to store data to map into the table, based on the filter
    useEffect(() => {
        if (filter === 'Open') {
            setAuctions(bids.open_auctions)
        } else if (filter === 'Closed') {
            setAuctions(bids.closed_auctions)
        } else {
            setAuctions(bids.open_auctions?.concat(bids.closed_auctions))
        }
    }, [filter, bids])

    const handleFilterChange = (filter) => {
        setFilter(filter)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
    }

    const handleOrgChange = (org) => {
        setOrg(org)
    }

    const handleMonthChange = (month) => {
        setMonth(month)
    }
    return (
        <SupplierLayout active="requests" title="Requests">
            <div className={styles.Rfq__container}>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <SupplierCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="All"
                            value={bids?.open_auctions?.length + bids?.closed_auctions?.length || 0}
                        />
                    </div>

                    <div className={styles.Cards__card}>
                        <SupplierCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="Open"
                            value={bids?.open_auctions?.length || 0}
                        />
                    </div>
                    <div className={styles.Cards__card}>
                        <SupplierCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="Closed"
                            value={bids?.closed_auctions?.length || 0}
                        />
                    </div>
                </div>
                <br />
                <Table>
                    <TableControls>
                        <div className={styles.Rfq__tableControls}>
                            <div className={styles.Rfq__tableControlsLeft}>
                                <Button text="Export CSV" color="#0E2C24" />
                                <Filter
                                    filter={filter}
                                    options={['All', 'Open', 'Closed']}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className={styles.Rfq__row}>
                                <TableControlSearch onSearchChange={handleSearchChange} />
                                <FormSelect
                                    filter
                                    name={'org'}
                                    borderRadius
                                    options={{
                                        '7leven': '7leven',
                                        Medmax: 'Medmax',
                                        'Wayne Enterprises': 'Wayne Enterprises',
                                    }}
                                    value={org}
                                    onChange={(e) => handleOrgChange(e.target.value)}
                                    placeholder={'Buyer'}
                                />
                                <FormSelect
                                    filter
                                    name={'month'}
                                    options={{
                                        January: 'January',
                                        February: 'February',
                                        March: 'March',
                                        April: 'April',
                                        May: 'May',
                                        June: 'June',
                                        July: 'July',
                                        August: 'August',
                                        September: 'September',
                                        October: 'October',
                                        November: 'November',
                                        December: 'December',
                                    }}
                                    value={month}
                                    onChange={(e) => handleMonthChange(e.target.value)}
                                    placeholder={'Month'}
                                />
                            </div>
                        </div>
                    </TableControls>
                    <TableContent hoverable={!(res.isError || res.isLoading)}>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Title</th>
                                <th>Reference Number</th>
                                <th>Buyer</th>
                                <th>Need By Date</th>
                                <th>Product Category</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {res.isError || res.isLoading ? (
                                <tr>
                                    <td colSpan="7">
                                        <Loader refresh={res.isError} height="30vh" />
                                    </td>
                                </tr>
                            ) : auctions?.length === 0 ? (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                        No data found
                                    </td>
                                </tr>
                            ) : (
                                auctions?.map((data, index) => (
                                    <tr key={data?.id} onClick={() => navigate(`/supplier/requests/${data?.id}`)}>
                                        <td>{index + 1}</td>
                                        <td>{data?.title}</td>
                                        <td>{data?.requisition_number}</td>
                                        <td>{data?.organization_name}</td>
                                        <td>{moment(data?.need_by_date).format('DD/MM/YYYY')}</td>
                                        <td>{data?.product_category}</td>
                                        <td>
                                            <Badge
                                                color={data?.is_open === true ? '#1BBB6B' : '#FC555B'}
                                                backgroundColor={data?.is_open === true ? '#EDF8F3' : '#FED7D8'}
                                                value={data?.is_open === true ? 'Open' : 'Closed'}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </TableContent>
                </Table>
            </div>
        </SupplierLayout>
    )
}

export default SupplierProductRequests
