import styles from './FormMultiSelect.module.scss'
import Select from 'react-select'

const FormMultiSelect = ({ data, onChange, name, placeholder = '', label, value, isLoading }) => {
    const categories = Object.keys(data).map((key) => {
        return {
            value: key,
            label: data[key],
        }
    })
    const selectedValues = value?.map((val) => ({ value: val, label: data[val] }))

    const handleChange = (newValue) => {
        const selectedValues = newValue.map((option) => option.value)
        const syntheticEvent = {
            target: {
                name: name,
                value: selectedValues,
            },
        }
        onChange(syntheticEvent)
    }

    return (
        <div className={styles.Select}>
            {label && <label>{label}</label>}
            <Select
                value={selectedValues}
                options={categories}
                className={styles.Select__inputField}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: '2px solid #e2e8f0',
                        '&:hover': {
                            border: '2px solid #e2e8f0',
                        },
                        boxShadow: 'none',
                        padding: '0.4rem 0',
                        outline: 'none',
                        width: '100%',
                        borderRadius: '8px',
                        cursor: 'pointer',
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        cursor: 'pointer',
                        padding: '0.4rem 1rem',
                        fontSize: '1.4rem',
                        color: 'black',
                        backgroundColor: state.isFocused ? 'rgba(112, 188, 255, 0.162)' : 'white',
                    }),
                    menuList: (base) => ({ ...base, padding: 0, borderRadius: '4px' }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure it's on top of other elements
                }}
                menuPortalTarget={document.body} // Renders dropdown in a portal appended to body
                menuPosition={'fixed'}
                isMulti
                onChange={handleChange}
                name={name}
                placeholder={placeholder}
                isLoading={isLoading}
            />
        </div>
    )
}

export default FormMultiSelect
