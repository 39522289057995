import Card from '../../../components/Card/Card'
import styles from './OrganizationCard.module.scss'
import cx from 'classnames'

const OrganizationCard = ({ icon, color, className, name, value, ...rest }) => {
    return (
        <Card className={cx(styles.OrganizationCard, className && className)} {...rest}>
            <div className={styles.OrganizationCard__left}>
                <p>{name}</p>
                <p>{value}</p>
            </div>
            <div className={styles.OrganizationCard__right} style={{ backgroundColor: color }}>
                <img src={icon} />
            </div>
        </Card>
    )
}

export default OrganizationCard
