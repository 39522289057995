import React from 'react'
import Modal from '../../components/Modal/Modal'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import Button from '../../components/Button/Button'
import { useContext, useState } from 'react'
import AuthAPI from '../../api/AuthAPI'
import { useMutation, useQuery } from '@tanstack/react-query'
import AuthContext from '../../context/AuthContext'
import { toast } from 'react-toastify'

const Support = ({ onClose }) => {
    const { user } = useContext(AuthContext)
    const [formErrors, setFormErrors] = useState()

    const [msg, setMsg] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        submitMutation.mutate({
            token: user.token,
            data: { msg: msg },
        })

        console.log(msg)
        onClose()
    }

    const submitMutation = useMutation(AuthAPI.supportUs, {
        onSuccess: () => {
            toast.success('Mail Send')
            // navigate('/supplier/products')
        },
        onError: (err) => {
            if (err.response.status === 400) {
                return setFormErrors(err.response.data)
            }
            return toast.error(err.response.data?.message)
        },
    })

    return (
        <Modal title="Support" onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <FormTextarea
                    label="Message"
                    placeholder="Enter your message here"
                    type="input"
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                />

                <Button type="submit" onClick={(e) => handleSubmit(e)} text="Send" />
            </form>
        </Modal>
    )
}

export default Support
