import { useState } from 'react'
import styles from './InputWithError.module.scss'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const InputWithError = ({
    onBlur,
    onChange,
    name,
    type,
    placeholder,
    errorMsg = '',
    label,
    value = '',
    disabled = false,
    multiple = false,
}) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleToggleEye = () => setShowPassword(!showPassword)

    return (
        <div className={styles.InputWithError}>
            {label && <label className={styles.InputWithError__label}>{label}</label>}
            <div className={styles.InputWithError__inputWrapper}>
                <input
                    className={styles.InputWithError__input}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    multiple={multiple}
                />
                {type === 'password' && (
                    <div className={styles.InputWithError__eye} onClick={handleToggleEye}>
                        {showPassword ? <FaEyeSlash color="#718096" /> : <FaEye color="#718096" />}
                    </div>
                )}
            </div>
            {errorMsg && <pre className={styles.InputWithError__msg}>{errorMsg}</pre>}
        </div>
    )
}

export default InputWithError
