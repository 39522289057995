import Table from '../../../components/Table/Table'
import TableButton from '../../../components/Table/TableButton/TableButton'
import TableContent from '../../../components/Table/TableContent/TableContent'
import styles from './AttachmentTable.module.scss'
import { FaEye, FaTrash } from 'react-icons/fa'

const AttachmentTable = ({ data = [], handleDelete }) => {
    const downloadFile = (fileName) => {
        const baseURL = process.env.REACT_APP_API_BASE_URL
        const fileUrl = baseURL + fileName
        window.open(fileUrl, '_blank')
    }
    const getFileType = (file) => {
        const extension = file.split('.').pop() // Extracts the extension from the file name
        return extension
    }
    return (
        <Table outline={false}>
            <TableContent>
                <thead>
                    <tr>
                        <th>SL No</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        console.log(row)
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.name || row?.title}</td>
                                <td>{row?.file?.info?.type || getFileType(row.file || row.file_url)}</td>
                                {!row.file?.info?.id ? (
                                    <TableButton>
                                        <FaEye
                                            className={styles.CreateRFQ__editIcon}
                                            onClick={() => downloadFile(row.file)}
                                        />
                                    </TableButton>
                                ) : (
                                    <td></td>
                                )}
                                <TableButton>
                                    <FaTrash
                                        className={styles.CreateRFQ__editIcon}
                                        onClick={() => {
                                            row.file?.info?.id ? handleDelete(row.file?.info?.id) : handleDelete(row.id)
                                        }}
                                    />
                                </TableButton>
                            </tr>
                        )
                    })}
                </tbody>
            </TableContent>
        </Table>
    )
}

export default AttachmentTable
