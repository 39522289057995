import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import styles from './Client.module.scss'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import SeaIcon from '../../assets/icons/sea.svg'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import Table from '../../components/Table/Table'

const OrganizationClient = () => {
    const [tableData, setTableData] = useState([
        {
            vendor_id: 'AMZ-012',
            name: 'Amazon India',
            person_of_contact: 'Neil Berfosa',
            currency: 'USD',
            location: 'Noida, India',
            email: 'sales@amazon.in',
            status: 'Active',
        },
        {
            vendor_id: 'AMZ-012',
            name: 'Amazon India',
            person_of_contact: 'Neil Berfosa',
            currency: 'USD',
            location: 'Noida, India',
            email: 'sales@amazon.in',
            status: 'Active',
        },
        {
            vendor_id: 'AMZ-012',
            name: 'Amazon India',
            person_of_contact: 'Neil Berfosa',
            currency: 'USD',
            location: 'Noida, India',
            email: 'sales@amazon.in',
            status: 'Active',
        },
    ])

    const navigate = useNavigate()

    return (
        <OrganizationLayout title="Client Management" active="supplier">
            <div className={styles.Supplier__container}>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="All" value="130" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Approved" value="90" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Public" value="9" />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard
                            icon={SeaIcon}
                            color="#FFFFFF"
                            style={{
                                backgroundColor: '#7800EF',
                                color: 'white',
                                cursor: 'pointer',
                            }}
                            value="Add a new Client"
                            onClick={() => navigate('/organization/client')}
                        />
                    </div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Client Id</th>
                            <th>Name</th>
                            <th>Person of Contact</th>
                            <th>Currency</th>
                            <th>Location</th>
                            <th>Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, id) => {
                            return (
                                <tr
                                    key={id}
                                    onClick={() => {
                                        navigate('/organization/client/details')
                                    }}
                                >
                                    {Object.values(row).map((field) => (
                                        <td>{field}</td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </OrganizationLayout>
    )
}

export default OrganizationClient
