import styles from './Header.module.scss'
import LogoImage from '../../assets/images/logo.png'
import Button from '../Button/Button'
import { useContext, useState, useRef } from 'react'
import AuthContext from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import DropdownCard from '../DropdownCard/DropdownCard'
import Back from '../../pages/OrganizationSupplierDetails/Back'
import { MdArrowDropDown } from 'react-icons/md'

const Header = ({ title, showLogout = false }) => {
    const { user, logout } = useContext(AuthContext)
    const dropdownToggleRef = useRef(null)

    const [dropdownActive, setDropdownActive] = useState(false)

    const handleLogout = () => {
        logout()
        console.log('logout')
        navigate('/login')
    }

    const toggleDropdown = () => {
        setDropdownActive((prev) => !prev)
    }

    const navigate = useNavigate()

    return (
        <div className={styles.Header}>
            <Back />
            <div className={styles.Header__container}>
                <h1 className={styles.Header__title}>{title}</h1>
                {/* <img className={styles.Header__image} src={LogoImage} alt="logo"/> */}
                {/* <div className={styles.Header__welcomeWrapper}> */}
                <div className={styles.Header__welcomeContainer}>
                    <p className={styles.Header__welcome}>
                        <span className={styles.Header__organizationName} style={{ color: 'gray' }}>
                            {/* {user.organizationData.name} */}
                            {user.organizationData ? user.organizationData.name : 'Organization Name'}
                        </span>
                    </p>
                    <p ref={dropdownToggleRef} className={styles.Header__welcome} onClick={toggleDropdown}>
                        {/* Hi {user.first_name} */}
                        <span>Hi {user.first_name ? user.first_name : 'User'}</span>
                        <MdArrowDropDown />
                    </p>
                </div>
                <div className={styles.Header__dropdown}>
                    <DropdownCard active={dropdownActive} close={setDropdownActive} parentRef={dropdownToggleRef}>
                        <li onClick={() => navigate('/organization/dashboard')}>Buyer</li>
                        <li onClick={() => navigate('/supplier/dashboard')}>Supplier</li>
                        {showLogout && <li onClick={logout}>Logout</li>}
                    </DropdownCard>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default Header
