import { useState } from 'react'
import Button from '../../../components/Button/Button'
import InputWithError from '../../../components/InputWithError/InputWithError'
import Modal from '../../../components/Modal/Modal'
import styles from './HeaderAttachmentModal.module.scss'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'

const HeaderAttachmentModal = ({ onClose = () => {}, handleSubmit = () => {} }) => {
    const [form, setForm] = useState({})
    const [selectedFileName, setSelectedFileName] = useState('No file chosen')
    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const [fileError, setFileError] = useState('')

    const handleFormSubmit = (e) => {
        e.preventDefault()
        let hasError = false
        if (!form.title || form.title.trim() === '') {
            hasError = true
            setErrors((prev) => ({ ...prev, title: 'Title is required' }))
        }
        if (!form.file) {
            hasError = true
            setFileError('Please upload the file')
        }
        if (!hasError) {
            setFileError('')
            handleSubmit(form)
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        const info = {
            id: uuid(),
            name: file.name,
            type: file.type,
            size: file.size,
            lastModified: file.lastModified,
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            setForm((prev) => ({ ...prev, file: { info, data: reader.result } }))
            setSelectedFileName(file.name)
            setFileError('')
        }
    }

    return (
        <Modal title="Add Document" onClose={onClose}>
            <form onSubmit={handleFormSubmit}>
                <div className={styles['row']}>
                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-12'])}>
                        <InputWithError
                            onChange={handleChange}
                            name="title"
                            label="Title"
                            placeholder="Title"
                            type="text"
                            value={form.title}
                            errorMsg={errors.title}
                        />
                    </div>
                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-12'])}>
                        <InputWithError
                            onChange={handleChange}
                            name="description"
                            label="Description"
                            placeholder="Description"
                            type="text"
                            value={form.description}
                            errorMsg={errors.description}
                        />
                    </div>
                    <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-12'])}>
                        <div className={styles.customFileInput}>
                            <label htmlFor="fileInput" className={styles.customFileInputLabel}>
                                Browse File
                            </label>
                            <input
                                id="fileInput"
                                name="files"
                                type="file"
                                onChange={handleFileChange}
                                className={styles.customFileInputField}
                            />
                            <div className={styles.customSelectedFileName}>{selectedFileName}</div>
                            {fileError && <div className={styles.fileInputError}>{fileError}</div>}
                        </div>
                    </div>
                </div>
                <div className={styles.BtnContainer}>
                    <Button text="Save" type="submit" className={styles.CreateRFQ__fullWidthButton} />
                    <Button text="Cancel" className={styles.CancelBtn} onClick={onClose} />
                </div>
            </form>
        </Modal>
    )
}

export default HeaderAttachmentModal
