import React from 'react'
import styles from './SupplierClientDetails.module.scss'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import { useLocation } from 'react-router-dom'

const SupplierClientDetails = () => {
    const { state } = useLocation()
    const clientData = state?.clientData

    return (
        <SupplierLayout active="clients" title="Client Management">
            <div className={styles.ClientDetails__container}>
                <div className={styles.Card}>
                    <p className={styles.Request__organizationDetails}>
                        <span className={styles.Request__organizationName}>Client Details</span>
                    </p>
                    <div className={styles.Request__organizationContents}>
                        <p className={styles.Request__text}>
                            <span>Name</span>
                            <span>{clientData?.name}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Email</span>
                            <span>{clientData?.email}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Contact</span>
                            <span>{clientData?.contact}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Address</span>
                            <span>{`${clientData?.address_line1}, ${clientData?.address_line2}`}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>City</span>
                            <span>{clientData?.city}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>State</span>
                            <span>{clientData?.state}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Country</span>
                            <span>{clientData?.country}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Postal Code</span>
                            <span>{clientData?.postal_code}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Default Currency</span>
                            <span>{clientData?.default_currency}</span>
                        </p>
                    </div>
                </div>

                <div className={styles.Card}>
                    <p className={styles.Request__organizationDetails}>
                        <span className={styles.Request__organizationName}>Additional Details</span>
                    </p>
                    <div className={styles.Request__organizationContents}>
                        <p className={styles.Request__text}>
                            <span>Number of Employees</span>
                            <span>{clientData?.number_of_employees}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Total Turnover</span>
                            <span>{clientData?.total_turnover}</span>
                        </p>
                        <p className={styles.Request__text}>
                            <span>Active Status</span>
                            <span>{clientData?.is_active ? 'Active' : 'Inactive'}</span>
                        </p>
                    </div>
                </div>
            </div>
        </SupplierLayout>
    )
}

export default SupplierClientDetails
