import React from 'react'
import { useNavigate } from 'react-router-dom'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Button from '../../components/Button/Button'
import AddTemplate from './AddTemplate'

import styles from './Templates.module.scss'

const SupplierOrg = () => {
    const navigate = useNavigate()

    return (
        <SupplierLayout active="organization" title="Templates">
            <Button
                className={styles.btn}
                text="Add template"
                onClick={() => navigate('/supplier/organization/temp')}
            />
        </SupplierLayout>
    )
}

export default SupplierOrg
