import styles from './StepSlider.module.scss'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    slider: {
        '&::after': {
            width: ({ width }) => `${width}%`,
        },
    },
    span: {
        '&::before': {
            backgroundColor: 'green !important',
        },
    },
})

const StepSlider = ({ steps = [], activeIndex = 3 }) => {
    const width = activeIndex * (100 / (steps.length - 1))
    const classes = useStyles({ width })

    return (
        <div className={cx(styles.StepSlider, classes.slider)}>
            {steps.map((step, idx) => (
                <span key={idx} onClick={step.onClick} className={cx(idx <= activeIndex && classes.span)}>
                    {step.title}
                </span>
            ))}
        </div>
    )
}

export default StepSlider
