import React, { useState, useEffect } from 'react'

import Card from '../../components/Card/Card'
import styles from './OrganizationSupplierDetailsNew.module.scss'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Loader from '../../components/Loader/Loader'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import AuthContext from '../../context/AuthContext'
import { useContext } from 'react'
import SupplierAPI from '../../api/SupplierAPI'
import OrganizationAPI from '../../api/OrganizationAPI'
import { IoArrowBackOutline } from 'react-icons/io5'

const OrganizationSupplierDetailsNew = () => {
    const navigate = useNavigate()
    // const { auctionId } = useParams()
    const { id } = useParams()
    const { user } = useContext(AuthContext)
    const detailsQuery = useQuery(['my-auctions'], () => SupplierAPI.getSupplierDetails({ token: user.token, id: id }))
    console.log(detailsQuery.data)
    // const getAuthorizedSellersQuery = useQuery(
    //     ['authorized-sellers'],
    //     () => OrganizationAPI.getAuthorizedSellers({ token: user.token, orgId: user.organisation }),
    //     {
    //         select: (data) =>
    //             data?.data.map((seller) => {
    //                 // console.log(seller)
    //                 return {
    //                     Name: seller.name,
    //                     Email: seller.email,
    //                     Contact: seller.contact,
    //                 }
    //             }),
    //     }
    // )

    return (
        <OrganizationLayout active="supplier" title="Details">
            {detailsQuery.isError ? (
                <Loader />
            ) : detailsQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.details}>
                        <div className={styles.Card}>
                            <p className={styles.Request__organizationDetails}>
                                <span className={styles.Request__organizationName}>Buyer Details</span>
                            </p>
                            <div className={styles.Request__organizationContents}>
                                <p className={styles.Request__text}>
                                    <span>Name</span>
                                    <span>{detailsQuery.data?.data?.name}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Email</span>
                                    <span>{detailsQuery.data?.data?.email}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Contact</span>
                                    <span>{detailsQuery.data?.data?.contact}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Address</span>
                                    <span>{detailsQuery.data?.data?.address_line_1}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>City</span>
                                    <span>{detailsQuery.data?.data?.city}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>State</span>
                                    <span>{detailsQuery.data?.data?.state}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Country</span>
                                    <span>{detailsQuery.data?.data?.country}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Postal Code</span>
                                    <span>{detailsQuery.data?.data?.postal_code}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Default currency</span>
                                    <span>{detailsQuery.data?.data?.default_currency}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Number of Employees</span>
                                    <span>{detailsQuery.data?.data?.number_of_employees}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Total Turnover</span>
                                    <span>{detailsQuery.data?.data?.total_turnover}</span>
                                </p>
                            </div>
                        </div>
                        <div className={styles.Card}>
                            <p className={styles.Request__organizationDetails}>
                                <span className={styles.Request__organizationName}>Contact Details</span>
                            </p>
                            <div className={styles.Request__organizationContents}>
                                <p className={styles.Request__text}>
                                    <span>Department Mail</span>
                                    <span>{detailsQuery.data?.data?.name}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Telephone Line</span>
                                    <span>{detailsQuery.data?.data?.email}</span>
                                </p>

                                <p className={styles.Request__text}>
                                    <span>Founder's Name</span>
                                    <span>{detailsQuery.data?.data?.contact}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Founder's Email</span>
                                    <span>{detailsQuery.data?.data?.address_line_1}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Founder's Phone</span>
                                    <span>{detailsQuery.data?.data?.city}</span>
                                </p>
                            </div>
                        </div>
                        <br />
                    </div>
                </>
            )}
        </OrganizationLayout>
    )
}

export default OrganizationSupplierDetailsNew
