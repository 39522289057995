import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import { useQuery, useMutation } from '@tanstack/react-query'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import Loader from '../../components/Loader/Loader'
import styles from './SupplierOrderDetails.module.scss'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import SupplierAPI from '../../api/SupplierAPI'
import Badge from '../../components/Badge/Badge'
import moment from 'moment/moment'
import TableButton from '../../components/Table/TableButton/TableButton'
import { FaEye } from 'react-icons/fa'
import { toast } from 'react-toastify'

const Modal = ({ isOpen, onClose, onConfirm, action, loader }) => {
    return (
        <>
            {isOpen && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={onClose}>
                            &times;
                        </span>
                        <h4 className="mt-3 mb-5 text-center">Are you sure you want to {action} this order?</h4>
                        <div className={styles.modalButtons}>
                            <Button className="bg-danger" text="Cancel" onClick={onClose} />
                            <Button text={action} onClick={onConfirm} loading={loader} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const PurchaseOrderDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalAction, setModalAction] = useState('')
    const { id } = useParams()
    const { user } = useContext(AuthContext)

    const fetchOrderDetails = useQuery(
        ['purchaseOrderDetails', id],
        () => SupplierAPI.getSupplierPO({ token: user.token, id }),
        {
            enabled: !!id,
        }
    )

    const approveMutation = useMutation(SupplierAPI.postSupplierPO, {
        onSuccess: () => {
            toast.success('Order has been updated successfully')
            fetchOrderDetails.refetch()
            setIsModalOpen(false)
        },
        onError: (error) => {
            setIsModalOpen(false)
            if (error.response.data.error === 'Purchase Order Status Already changed.') {
                return toast.error('Purchase Order already Approved')
            }
            toast.error('Something went wrong')
        },
    })

    const handleAction = async (status) => {
        await approveMutation.mutateAsync({ token: user.token, po_id: id, status: status })
    }

    const toggleModal = (action) => {
        setModalAction(action)
        setIsModalOpen(!isModalOpen)
    }

    const orderDetails = fetchOrderDetails.data?.data
    const supplierDetails = orderDetails?.supplier_organisation_details
    const buyerDetails = orderDetails?.buyer_organisation_details
    const bidHeaderDetails = orderDetails?.bid_header_details
    const auctionHeader = orderDetails?.bid_header_details?.auction_header
    const attachments = orderDetails?.bid_header_details?.auction_header?.attachments
    const bidDetails = orderDetails?.bid_header_details
    const auctionDetails = bidDetails?.auction_header
    const purchaseOrderDetails = orderDetails?.details
    const intStatus = orderDetails?.int_status

    const formatIntStatus = (status) => {
        switch (status) {
            case 0:
                return <Badge backgroundColor="#fdebd8" color="#f7a64f" value="PENDING" />
            case 1:
                return <Badge backgroundColor="#EDF8F3" color="#1BBB6B" value="APPROVED" />
            case -1:
                return <Badge backgroundColor="#FED7D8" color="#FC555B" value="DECLINED" />
            default:
                return 'Unknown Status'
        }
    }

    return (
        <SupplierLayout title="Purchase Order Details" active="orders">
            <div className={styles.Request__Main}>
                <p className={styles.Request__text}>
                    <span>Order Status:</span> {formatIntStatus(intStatus)}
                </p>
                <div className={styles.Request__Wrapper}>
                    <div className={styles.Request__buttonWrapper}>
                        <Button text="Approve" onClick={() => toggleModal('Approve')} />
                    </div>
                    <div className={styles.Request__buttonWrapper}>
                        <Button text="Reject" color="rgba(255, 31, 0, 0.76)" onClick={() => toggleModal('Reject')} />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => handleAction(modalAction)}
                action={modalAction === 'Approve' ? 'Approve' : 'Reject'}
                loader={approveMutation.isLoading}
            />

            <Card className={styles.Card}>
                <h2 className={styles.Request__title}>Buyer Details</h2>
                <Card className={styles.Card}>
                    <p className={styles.Request__text}>
                        <span>Name:</span> {buyerDetails?.name}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Address:</span> {buyerDetails?.address_line1}, {buyerDetails?.address_line2}
                    </p>
                    <p className={styles.Request__text}>
                        <span>City:</span> {buyerDetails?.city}
                    </p>
                    <p className={styles.Request__text}>
                        <span>State:</span> {buyerDetails?.state}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Postal Code:</span> {buyerDetails?.postal_code}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Country:</span> {buyerDetails?.country}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Email:</span> {buyerDetails?.email}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Contact:</span> {buyerDetails?.contact}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Number of Employees:</span> {buyerDetails?.number_of_employees}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Total Turnover:</span> {buyerDetails?.total_turnover}
                    </p>

                    <p className={styles.Request__text}>
                        <span>Code:</span> {buyerDetails?.code}
                    </p>
                </Card>
            </Card>
            <Card className={styles.Card}>
                <h2 className={styles.Request__title}>Supplier Details</h2>
                <Card className={styles.Card}>
                    <p className={styles.Request__text}>
                        <span>Name:</span> {supplierDetails?.name}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Address:</span> {supplierDetails?.address_line1}, {supplierDetails?.address_line2}
                    </p>
                    <p className={styles.Request__text}>
                        <span>City:</span> {supplierDetails?.city}
                    </p>
                    <p className={styles.Request__text}>
                        <span>State:</span> {supplierDetails?.state}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Postal Code:</span> {supplierDetails?.postal_code}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Country:</span> {supplierDetails?.country}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Email:</span> {supplierDetails?.email}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Contact:</span> {supplierDetails?.contact}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Number of Employees:</span> {supplierDetails?.number_of_employees}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Total Turnover:</span> {supplierDetails?.total_turnover}
                    </p>

                    <p className={styles.Request__text}>
                        <span>Code:</span> {supplierDetails?.code}
                    </p>
                </Card>
            </Card>
            <Card className={styles.Card}>
                <h2 className={styles.Request__title}>Bid Details</h2>
                <Card className={styles.Card}>
                    <p className={styles.Request__text}>
                        <span>Created At:</span> {moment(bidHeaderDetails?.created_at).format('DD/MM/YYYY')}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Updated At:</span> {moment(bidHeaderDetails?.updated_at).format('DD/MM/YYYY')}
                    </p>

                    <p className={styles.Request__text}>
                        <span>Organization Name:</span> {bidHeaderDetails?.organisation?.name}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Address:</span> {bidHeaderDetails?.organisation?.address_line1},{' '}
                        {bidHeaderDetails?.organisation?.address_line2}
                    </p>
                    <p className={styles.Request__text}>
                        <span>City:</span> {bidHeaderDetails?.organisation?.city}
                    </p>
                    <p className={styles.Request__text}>
                        <span>State:</span> {bidHeaderDetails?.organisation?.state}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Postal Code:</span> {bidHeaderDetails?.organisation?.postal_code}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Country:</span> {bidHeaderDetails?.organisation?.country}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Email:</span> {bidHeaderDetails?.organisation?.email}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Contact:</span> {bidHeaderDetails?.organisation?.contact}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Number of Employees:</span> {bidHeaderDetails?.organisation?.number_of_employees}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Total Turnover:</span> {bidHeaderDetails?.organisation?.total_turnover}
                    </p>
                </Card>
            </Card>

            <Card className={styles.Card}>
                <h2 className={styles.Request__title}>Auction Details</h2>
                <Card className={styles.Card}>
                    <p className={styles.Request__text}>
                        <span>Organization Name:</span> {auctionHeader?.organization_name}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Title:</span> {auctionHeader?.title}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Requisition Number:</span> {auctionHeader?.requisition_number}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Product Category:</span> {auctionHeader?.product_category}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Product Sub Category:</span> {auctionHeader?.product_sub_category}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Is Open:</span> {auctionHeader?.is_open ? 'Yes' : 'No'}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Is Editable:</span> {auctionHeader?.is_editable ? 'Yes' : 'No'}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Need By Date:</span> {moment(auctionHeader?.need_by_date).format('DD/MM/YYYY')}
                    </p>
                </Card>
            </Card>

            <Card>
                <div className={styles.Request__bidResponsesTitle}>
                    <span className={styles.Request__title}>Items</span>
                </div>

                <Table>
                    <TableContent>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Promised Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails?.details?.map((detail, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{detail.product_name}</td>
                                    <td>{detail.quantity}</td>
                                    <td>{detail.price}</td>
                                    <td>{moment(detail.bid_line_details?.promised_date).format('DD/MM/YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </TableContent>
                </Table>
            </Card>

            <Card className={styles.Card}>
                <h2 className={styles.Request__title}>Bid Detail</h2>
                <Card className={styles.Card}>
                    <p className={styles.Request__text}>
                        <span>Status:</span> {auctionDetails?.status}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Bid Count:</span> {auctionDetails?.bid_count}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Partial Bidding:</span> {bidDetails?.partial_bidding ? 'Yes' : 'No'}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Partial Quantity Bidding:</span> {bidDetails?.partial_quantity_bidding ? 'Yes' : 'No'}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Bidders Bid Number:</span> {bidDetails?.bidders_bid_number}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Bid Status:</span> {bidDetails?.bid_status}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Type of Response:</span> {bidDetails?.type_of_response}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Bid Expiration Date:</span> {moment(bidDetails?.bid_expiration_date).format('DD/MM/YYYY')}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Cancellation Date:</span>{' '}
                        {bidDetails?.cancellation_date
                            ? moment(bidDetails.cancellation_date).format('DD/MM/YYYY')
                            : 'N/A'}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Disqualify Reason:</span> {bidDetails?.disqualify_reason || 'N/A'}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Submit Stage:</span> {bidDetails?.submit_stage}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Note to Supplier:</span> {bidDetails?.note_to_supplier}
                    </p>
                </Card>
            </Card>

            <Card className={styles.Card}>
                <h2 className={styles.Request__title}>Order Details</h2>
                <Card className={styles.Card}>
                    <ul>
                        {purchaseOrderDetails?.map((detail, index) => (
                            <li key={index}>
                                <p className={styles.Request__text}>
                                    <span>Product Name:</span> {detail.product_name}
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Quantity:</span> {detail.quantity}
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Price:</span> {detail.price}
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Total Price:</span> {detail.total_price}
                                </p>
                            </li>
                        ))}
                    </ul>
                    <p className={styles.Request__text}>
                        <span>Total Price:</span> {orderDetails?.total_price}
                    </p>
                    <p className={styles.Request__text}>
                        <span>Order Status:</span> {formatIntStatus(intStatus)}
                    </p>
                </Card>
            </Card>

            {/* {attachments && attachments.length > 0 && (
                  <Card className={styles.Card}>
                    <h2>Attachments</h2>
                <Card className={styles.Card}>
                    <ul>
                        {attachments.map((attachment, index) => (
                            <li key={attachment.id}>
                                <p className={styles.Request__text}><span>Name:</span> {attachment.name}</p>
                                <p className={styles.Request__text}><span>Description:</span> {attachment.description}</p>
                               
                                <a href={`{Your_Base_Media_URL}${attachment.file}`} target="_blank" rel="noopener noreferrer">
                                    View Attachment
                                </a>
                            </li>
                        ))}
                    </ul>
                </Card>
                </Card>
            )} */}
            {orderDetails?.bid_header_details?.auction_header?.attachments && (
                <Card>
                    <div className={styles.Request__bidResponsesTitle}>
                        <span className={styles.Request__title}>Attachments</span>
                    </div>

                    <Table>
                        <TableContent>
                            <thead>
                                <tr>
                                    <th>SL No</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderDetails?.bid_header_details?.auction_header?.attachments.map(
                                    (attachment, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{attachment.name}</td>
                                            <td>{attachment.description}</td>
                                            <TableButton>
                                                <FaEye
                                                    onClick={() =>
                                                        window.open(
                                                            process.env.REACT_APP_API_BASE_URL + attachment.file
                                                        )
                                                    }
                                                />
                                            </TableButton>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </TableContent>
                    </Table>
                </Card>
            )}
        </SupplierLayout>
    )
}

export default PurchaseOrderDetails
