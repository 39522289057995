import React, { useEffect } from 'react'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import InputWithError from '../../components/InputWithError/InputWithError'
import FormSelect from '../../components/FormSelect/FormSelect'
import styles from './OrganizationCreateUser.module.scss'
import cx from 'classnames'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import { useQuery, useMutation } from '@tanstack/react-query'
import AuthAPI from '../../api/AuthAPI'
import Loader from '../../components/Loader/Loader'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import OrganizationAPI from '../../api/OrganizationAPI'
import AuthContext from '../../context/AuthContext'
import { useState, useContext } from 'react'

const validatePassword = (password) => {
    const errors = []

    if (typeof password === 'undefined') {
        errors.push('Password is required')
    } else {
        console.log(password)
        if (!/[a-z]/.test(password)) {
            errors.push('Password must contain at least one lowercase letter')
        }
        if (!/[A-Z]/.test(password)) {
            errors.push('Password must contain at least one uppercase letter')
        }
        if (!/\d/.test(password)) {
            errors.push('Password must contain at least one number')
        }
        if (!/[@$!%*#?&]/.test(password)) {
            errors.push('Password must contain at least one special character (@, $, !, %, *, #, ?, or &)')
        }
        if (password.length < 8) {
            errors.push('Password must be at least 8 characters long')
        }
    }

    return errors
}

const OrganizationEditUser = () => {
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const data = useParams()

    const [form, setForm] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [passwordForm, setPasswordForm] = useState({})
    const [passwordFormErrors, setPasswordFormErrors] = useState({})

    const editMutation = useMutation(OrganizationAPI.editOrganizationStaff, {
        onSuccess: (data) => {
            toast.success('User updated successfully!')
            navigate('/organization/users')
        },
        onError: (error) => {
            if (error.response?.status === 400) {
                setFormErrors(error.response.data)
            } else {
                toast('Some error occurred!', {
                    type: 'error',
                })
            }
        },
    })
    const updatePasswordMutation = useMutation(OrganizationAPI.editOrganizationStaffPassword, {
        onSuccess: (data) => {
            toast.success('Password updated successfully!')
            navigate('/organization/users')
        },
        onError: (error) => {
            if (error.response?.status === 400) {
                setPasswordFormErrors(error.response.data)
            } else {
                toast('Some error occurred!', {
                    type: 'error',
                })
            }
        },
    })
    const handlePasswordSubmit = (e) => {
        if (passwordForm.new_password !== passwordForm.password2) {
            return setPasswordFormErrors({
                password: ['Passwords should be the same'],
                password2: ['Passwords should be the same'],
            })
        }
        e.preventDefault()
        const passwordErrors = validatePassword(passwordForm.new_password)
        if (passwordErrors.length > 0) {
            setPasswordFormErrors({
                new_password: passwordErrors,
            })
            passwordErrors.forEach((error) => {
                toast.error(error)
            })
            return
        }

        updatePasswordMutation.mutate({
            token: user.token,
            orgId: user.organisation,
            userId: data.id,
            data: passwordForm,
        })
    }

    const getUserQuery = useQuery(
        ['user', data.id],
        () => OrganizationAPI.getOrganizationStaff({ token: user.token, id: data.id }),
        {
            onSuccess: (data) => {
                setForm(data.data)
            },
        }
    )

    const countryChoicesQuery = useQuery(['country-choices'], AuthAPI.getCountryChoices, {
        select: (data) => {
            data = data?.data
            const list = {}
            const countryList = {}
            const dialCode = {}
            Object.keys(data).forEach((code) => {
                dialCode[`${data[code].name} ${data[code].dial_code}`] = code.dial_code
            })
            Object.keys(data).forEach((code) => {
                countryList[data[code].name] = code
            })
            list['countryList'] = countryList
            list['dialCode'] = dialCode
            list['data'] = data
            return list
        },
    })

    const getSellersQuery = useQuery(['sellers'], () => OrganizationAPI.getAllSellers(user.token), {
        select: (data) => {
            const sellers = {}
            data.data?.map((seller) => (sellers[seller.id] = seller.name))
            return sellers
        },
    })
    const sellersArray = getSellersQuery.data ? Object.values(getSellersQuery.data) : []
    const getSellersNames = sellersArray.reduce((acc, name) => {
        acc[name] = name
        return acc
    }, {})

    console.log(getSellersNames)
    const checkPasswordMatch = () => {
        if (form.password !== form.password2) {
            setFormErrors((prev) => ({ ...prev, password2: ["Passwords don't match"] }))
        } else {
            setFormErrors((prev) => {
                const { password2, ...rest } = prev
                return rest
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        editMutation.mutate({
            token: user.token,
            orgId: user.organisation,
            data: form,
            userId: data.id,
        })
    }
    return (
        <OrganizationLayout title="Edit User" active="users">
            {countryChoicesQuery.isError || getSellersQuery.isError || getUserQuery.isError ? (
                <Loader refresh />
            ) : countryChoicesQuery.isLoading || getSellersQuery.isLoading || getUserQuery.isLoading ? (
                <Loader />
            ) : (
                <>
                    <Card>
                        <form onSubmit={handleSubmit}>
                            <div className={styles['row']}>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Username"
                                        name="username"
                                        value={form.username}
                                        placeholder={'Enter Username'}
                                        type={'text'}
                                        errorMsg={formErrors['username'] && formErrors['username'][0]}
                                        onChange={(e) => setForm({ ...form, username: e.target.value })}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-6'])}>
                                    <InputWithError
                                        label="Email"
                                        name="email"
                                        value={form.email}
                                        placeholder={'Enter Email'}
                                        type={'email'}
                                        errorMsg={formErrors['email'] && formErrors['email'][0]}
                                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="First Name"
                                        name="first_name"
                                        value={form.first_name}
                                        placeholder={'Enter First Name'}
                                        type={'text'}
                                        errorMsg={formErrors['first_name'] && formErrors['first_name'][0]}
                                        onChange={(e) => setForm({ ...form, first_name: e.target.value })}
                                    />
                                </div>
                                {/* <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Middle Name"
                                        name="middle_name"
                                        value={form.middle_name}
                                        placeholder={'Enter Middle Name'}
                                        type={'text'}
                                        errorMsg={formErrors['middle_name'] && formErrors['middle_name'][0]}
                                        onChange={(e) => setForm({ ...form, middle_name: e.target.value })}
                                    />
                                </div> */}
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Last Name"
                                        name="last_name"
                                        value={form.last_name}
                                        placeholder={'Enter Last Name'}
                                        type={'text'}
                                        errorMsg={formErrors['last_name'] && formErrors['last_name'][0]}
                                        onChange={(e) => setForm({ ...form, last_name: e.target.value })}
                                    />
                                </div>
                                {/* <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Old Password"
                                        name="old_password"
                                        value={form.old_password}
                                        placeholder={'Enter Old Password'}
                                        type={'password'}
                                        errorMsg={formErrors['old_password'] && formErrors['old_password'].join('\n')}
                                        onChange={(e) => setForm({ ...form, old_password: e.target.value })}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="New Password"
                                        name="password"
                                        value={form.password}
                                        placeholder={'Enter Password'}
                                        type={'password'}
                                        errorMsg={formErrors['password'] && formErrors['password'].join('\n')}
                                        onChange={(e) => setForm({ ...form, password: e.target.value })}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Confirm Password"
                                        name="password2"
                                        value={form.password2}
                                        placeholder={'Enter Confirm Password'}
                                        type={'password'}
                                        errorMsg={formErrors['password2'] && formErrors['password2'][0]}
                                        onChange={(e) => setForm({ ...form, password2: e.target.value })}
                                        onBlur={checkPasswordMatch}
                                    />
                                </div> */}
                                <div className={styles.separator} />
                                <h2 className={styles.Details}>Business Details</h2>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Designation"
                                        name="designation"
                                        placeholder={'Enter Designation'}
                                        type={'text'}
                                        errorMsg={formErrors['designation'] && formErrors['designation'][0]}
                                        value={form.designation}
                                        onChange={(e) => setForm({ ...form, designation: e.target.value })}
                                    />
                                </div>
                                {/* <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <FormSelect
                                        label="Organization"
                                        name="organization"
                                        placeholder={'Choose Organization'}
                                        value={form.organization}
                                        onChange={(e) => setForm({ ...form, organization: e.target.value })}
                                        options={getSellersNames}
                                        errorMsg={formErrors['organization'] && formErrors['organization'][0]}
                                    />
                                </div>  */}
                                <div className={styles.separator} />
                                <h2 className={styles.Details}>Contact Details</h2>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <FormTextarea
                                        label="Address"
                                        name="address"
                                        placeholder={'Enter Address'}
                                        type={'text'}
                                        value={form.address}
                                        onChange={(e) => setForm({ ...form, address: e.target.value })}
                                        minRows={1}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="City"
                                        name="city"
                                        placeholder={'Enter City'}
                                        type={'text'}
                                        errorMsg={formErrors['city'] && formErrors['city'][0]}
                                        value={form.city}
                                        onChange={(e) => setForm({ ...form, city: e.target.value })}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <FormSelect
                                        label="Country"
                                        name="country"
                                        placeholder={'Choose Country'}
                                        value={form.country}
                                        errorMsg={formErrors['country'] && formErrors['country'][0]}
                                        onChange={(e) => setForm({ ...form, country: e.target.value })}
                                        options={countryChoicesQuery?.data.countryList}
                                    />
                                </div>

                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-2'])}>
                                    <InputWithError
                                        label="Zip Code"
                                        name="zipcode"
                                        placeholder={'Enter Zip Code'}
                                        type={'text'}
                                        errorMsg={formErrors['zipcode'] && formErrors['zipcode'][0]}
                                        value={form.zipcode}
                                        onChange={(e) => setForm({ ...form, zipcode: e.target.value })}
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Phone"
                                        name="phone"
                                        placeholder={'Enter Phone'}
                                        type={'text'}
                                        errorMsg={formErrors['phone'] && formErrors['phone'][0]}
                                        value={form.phone}
                                        onChange={(e) => setForm({ ...form, phone: e.target.value })}
                                    />
                                </div>
                            </div>
                            <Button text="Update" type="submit" className={styles.CreateUserBtn} />
                        </form>
                    </Card>
                    <h1 className={styles.User__Title}>Edit Password</h1>
                    <Card>
                        <form onSubmit={handlePasswordSubmit}>
                            <div className={styles['row']}>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Old Password"
                                        name="old_password"
                                        value={passwordForm.old_password}
                                        placeholder={'Enter Old Password'}
                                        type={'password'}
                                        errorMsg={
                                            passwordFormErrors['old_password'] &&
                                            passwordFormErrors['old_password'].join('\n')
                                        }
                                        onChange={(e) =>
                                            setPasswordForm({ ...passwordForm, old_password: e.target.value })
                                        }
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="New Password"
                                        name="new_password"
                                        value={passwordForm.new_password}
                                        placeholder={'Enter Password'}
                                        type={'password'}
                                        errorMsg={
                                            passwordFormErrors['new_password'] &&
                                            passwordFormErrors['new_password'].join('\n')
                                        }
                                        onChange={(e) =>
                                            setPasswordForm({ ...passwordForm, new_password: e.target.value })
                                        }
                                    />
                                </div>
                                <div className={cx(styles.CreateRFQ__inputWrapper, styles['col-4'])}>
                                    <InputWithError
                                        label="Confirm Password"
                                        name="password2"
                                        value={passwordForm.password2}
                                        placeholder={'Enter Confirm Password'}
                                        type={'password'}
                                        errorMsg={passwordFormErrors['password2'] && passwordFormErrors['password2'][0]}
                                        onChange={(e) =>
                                            setPasswordForm({ ...passwordForm, password2: e.target.value })
                                        }
                                    />
                                </div>
                            </div>
                            <Button text="Update" type="submit" className={styles.CreateUserBtn} />
                        </form>
                    </Card>
                </>
            )}
        </OrganizationLayout>
    )
}

export default OrganizationEditUser
