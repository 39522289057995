import Card from '../Card/Card'
import styles from './index.module.scss'
import Content from './Content'
import Controls from './Controls'

const Table = ({ children, outline = true }) => {
    return outline ? <Card>{children}</Card> : <>{children}</>
}

Table.Content = Content
Table.Controls = Controls

export default Table
