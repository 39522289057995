import React from 'react'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Card from '../../components/Card/Card'
import SupplierAPI from '../../api/SupplierAPI'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment/moment'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import styles from '../SupplierDashboard/SupplierDashboard.module.scss'

const SupplierUpdates = () => {
    const { user } = useContext(AuthContext)

    const activityLogData = useQuery(
        ['activityLog'],
        () => SupplierAPI.getActivityLog({ token: user.token, viewAll: true }),
        {
            select: (data) => {
                return {
                    data: data?.data?.map((log) => ({
                        ...log,
                        created_at: moment(log.created_at).fromNow(),
                        updated_at: moment(log.updated_at).format('DD/MM/YYYY'),
                        description: log.description.replace('was created', ''),
                    })),
                }
            },
        }
    )
    return (
        <SupplierLayout active="dashboard" title="Updates">
            <Card className={styles.section__updates__card}>
                {activityLogData.data?.data?.length === 0 && <h5 className="text-center">No recent updates found</h5>}
                {activityLogData?.data?.data?.map((log, index) => (
                    <div className={`${styles.logEntry} b`} key={index}>
                        <div className="d-flex justify-content-between">
                            <h3 className={styles.logType}>{log.activity_type}</h3>
                            <h5 className={styles.logDate}>{log.created_at}</h5>
                        </div>
                        <h5 className={styles.logDescription}>{log.description}</h5>
                    </div>
                ))}
            </Card>
        </SupplierLayout>
    )
}

export default SupplierUpdates
