import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Oval } from 'react-loader-spinner'

import LogoImage from '../../assets/images/Metics-blue 1.png'
import HandshakeImage from '../../assets/images/handshake.png'
import styles from './ForgotPassword.module.scss'

import AuthContext from '../../context/AuthContext'
import { useContext } from 'react'
import InputWithError from '../../components/InputWithError/InputWithError'
import { useEffect } from 'react'
import SideImage from '../../assets/images/forgot_pass_promo.png'
import Button from '../../components/Button/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import { FaKey } from 'react-icons/fa'

const OPT_RESENT_TIME = 20

const ForgotPassword = () => {
    const [page, setPage] = useState(1)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [userId, setUserId] = useState('')

    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('')
    const [otpResentDisabled, setOtpResentDisabled] = useState(false)
    const [time, setTime] = useState(OPT_RESENT_TIME)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const getIconColor = (pageNumber) => {
        return pageNumber <= page ? 'green' : 'rgb(196,198,200)'
    }

    const navigate = useNavigate()

    let intervalId

    const startTimer = () => {
        intervalId = setInterval(() => {
            setTime((prev) => {
                if (prev <= 0) {
                    setOtpResentDisabled(false)
                    setTime(OPT_RESENT_TIME)
                    clearInterval(intervalId)
                    return OPT_RESENT_TIME
                } else {
                    return prev - 1
                }
            })
        }, 1000)
    }

    const validation = (type, e, cb = () => {}) => {
        console.log(e)
        const str = e.target.value
        switch (type) {
            case 'number':
                ;/^\d*$/.test(str) && cb(str)
                break
            default:
                cb(str)
        }
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault()
        console.log('email submit')
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-password/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
            }),
        })
        if (!res.ok) {
            toast('Account Not Registered', {
                type: 'error',
            })
            return
        }
        const data = await res.json()
        setUserId(data['user_id'])
        console.log(data['user_id'])
        toast('OTP sent to your email', {
            type: 'success',
        })
        setPage(2)
        setOtpResentDisabled(true)
        startTimer()
    }

    const handleRecaptchaChange = (value) => {
        console.log('ReCAPTCHA value:', value)
        // You can store the reCAPTCHA value in state or perform further validation
    }

    const handleOtpSubmit = async () => {
        // console.log({otp})
        setPage(3)
    }

    const handlePasswordSubmit = async (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match')
            return
        }

        console.log({
            otp,
            new_password: password,
        })
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-password/${userId}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                otp,
                new_password: password,
            }),
        })
        if (!res.ok) {
            setPage(2)
            if (res.status === 400) {
                const data = await res.json()
                toast(data['message'], {
                    type: 'error',
                })
                return
            }
            toast('Some error occurred!', {
                type: 'error',
            })
            return
        }
        toast('Password has been reset', {
            type: 'success',
        })
        navigate('/login')
    }

    return (
        <div className={styles.ForgotPassword}>
            <div className={styles.ForgotPassword__left}>
                <div className={styles.ForgotPassword__logoContainer}>
                    <img
                        className={styles.ForgotPassword__logo}
                        src={LogoImage}
                        alt="logo"
                        onClick={() => navigate('/login')}
                    />
                </div>
                <ul>
                    <li className={`${styles.listItem} ${page >= 1 ? styles.active : ''}`}>
                        <div className={styles.titleContainer}>
                            <div className={styles.title}>
                                <svg
                                    className={styles.icon}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="11"
                                        stroke={getIconColor(1)}
                                        fill="none"
                                        strokeWidth="2"
                                    />
                                    <path
                                        d="M8 13.5l3 3 6-6"
                                        stroke={getIconColor(1)}
                                        fill="none"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Forgot Password
                            </div>
                            <div className={styles.subtitleContainer}>
                                <span className={styles.subtitle}>Please enter your email</span>
                            </div>
                        </div>
                    </li>
                    <li className={`${styles.listItem} ${page >= 2 ? styles.active : ''}`}>
                        <div className={styles.titleContainer}>
                            <div className={styles.title}>
                                <svg
                                    className={styles.icon}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="11"
                                        stroke={getIconColor(2)}
                                        fill="none"
                                        strokeWidth="2"
                                    />
                                    <path
                                        d="M8 13.5l3 3 6-6"
                                        stroke={getIconColor(2)}
                                        fill="none"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Enter OTP
                            </div>
                            <div className={styles.subtitleContainer}>
                                <span className={styles.subtitle}>Please enter the OTP sent to your email</span>
                            </div>
                        </div>
                    </li>
                    <li className={`${styles.listItem} ${page >= 3 ? styles.active : ''}`}>
                        <div className={styles.titleContainer}>
                            <div className={styles.title}>
                                <svg
                                    className={styles.icon}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="11"
                                        stroke={getIconColor(3)}
                                        fill="none"
                                        strokeWidth="2"
                                    />
                                    <path
                                        d="M8 13.5l3 3 6-6"
                                        stroke={getIconColor(3)}
                                        fill="none"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Enter New Password
                            </div>
                            <div className={styles.subtitleContainer}>
                                <span className={styles.subtitle}>Please enter your new password</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={styles.ForgotPassword__right}>
                <div className={styles.ForgotPassword__contentContainer}>
                    <form className={styles.ForgotPassword__form}>
                        <div className={styles.ForgotPassword__formContainer}>
                            {page === 1 && (
                                <>
                                    <div className={styles.ForgotPassword__titleIcon}>
                                        <FaKey color="#00b976" />
                                    </div>
                                    <h1 className={styles.ForgotPassword__title}>Reset Password</h1>
                                    <InputWithError
                                        name="email"
                                        label="Enter Your Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        type="email"
                                        errorMsg={emailError}
                                        value={email}
                                    />

                                    <div className={styles['mb-3']}>
                                        <ReCAPTCHA
                                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                            onChange={handleRecaptchaChange}
                                        />
                                    </div>
                                    <br />
                                    <Button type="button" text="Next" onClick={handleEmailSubmit} />
                                    <p className={styles.ForgotPassword__contentContainer}>
                                        Remember Your Password ?&nbsp;
                                        <Link to="/login">Login</Link>
                                    </p>
                                </>
                            )}
                            {page === 2 && (
                                <>
                                    <div className={styles.ForgotPassword__titleIcon}>
                                        <FaKey color="#00b976" />
                                    </div>
                                    <h1 className={styles.ForgotPassword__title}>Enter Your OTP</h1>

                                    <InputWithError
                                        name="email"
                                        label="Enter OTP"
                                        onChange={(e) => validation('number', e, setOtp)}
                                        placeholder="Enter Otp"
                                        type="text"
                                        errorMsg={otpError}
                                        value={otp}
                                    />
                                    <div className={styles.ForgotPassword__formButtonGroup}>
                                        <Button type="button" text="Next" onClick={handleOtpSubmit} />
                                        <Button
                                            type="button"
                                            text="Resend OTP"
                                            onClick={handleEmailSubmit}
                                            disabled={otpResentDisabled}
                                        />
                                        {<span className={styles.ForgotPassword__time}>{time}s</span>}
                                    </div>
                                </>
                            )}
                            {page === 3 && (
                                <>
                                    <div className={styles.ForgotPassword__titleIcon}>
                                        <FaKey color="#00b976" />
                                    </div>
                                    <h1 className={styles.ForgotPassword__title}>Confirm Password</h1>

                                    <InputWithError
                                        name="password"
                                        label="New Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter new password"
                                        type="password"
                                        errorMsg={passwordError}
                                        value={password}
                                    />
                                    <InputWithError
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm new password"
                                        type="password"
                                        errorMsg={passwordError}
                                        value={confirmPassword}
                                    />
                                    <Button type="button" text="Submit" onClick={handlePasswordSubmit} />
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
