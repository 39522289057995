// stylesheet
import styles from './PurchaseOrder.module.scss'

// assets
import SeaIcon from '../../assets/icons/sea.svg'

// context
import AuthContext from '../../context/AuthContext'

// api
import OrganizationAPI from '../../api/OrganizationAPI'

// packages
import { useNavigate } from 'react-router-dom'
import { useState, useContext, useRef } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

// components
import Button from '../../components/Button/Button'
import DropdownCard from '../../components/DropdownCard/DropdownCard'
import FormMultiSelect from '../../components/FormMultiSelect/FormMultiSelect'
import InputWithError from '../../components/InputWithError/InputWithError'
import Loader from '../../components/Loader/Loader'
import Modal from '../../components/Modal/Modal'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import SupplierAPI from '../../api/SupplierAPI'
import { FaEdit, FaFilePdf } from 'react-icons/fa'

const PurchaseOrder = () => {
    const { user } = useContext(AuthContext)

    const [showDropdown, setShowDropdown] = useState(false)
    const dropdownToggleRef = useRef(null)

    const [searchQuery, setSearchQuery] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [modalForm, setModalForm] = useState({})
    const [modalFormErrors, setModalFormErrors] = useState({})

    const [showAuthorizedSellerModal, setShowAuthorizedSellerModal] = useState(false)
    const [authorizedSellerModalForm, setAuthorizedSellerModalForm] = useState(false)
    const [authorizedSellerModalFormErrors, setAuthorizedSellerModalFormErrors] = useState(false)

    const navigate = useNavigate()

    const handleSearchChange = (query) => {
        setSearchQuery(query)
    }

    const data = useQuery(['purchaseOrders'], () => OrganizationAPI.getPurchaseOrder(user.token))

    const purchaseOrders = data && Array.isArray(data?.data?.data) ? data?.data?.data : []
    const totalPurchaseOrders = purchaseOrders.length

    // if (isLoading) {
    //     return <Loader />
    // }

    // if (error) {
    //     toast.error('Error fetching purchase orders')
    //     return null
    // }

    const handleRowClick = (orderId) => {
        navigate(`/organization/purchaseorder/${orderId}`)
    }

    const handlePrint = async (order) => {
        try {
            const pdfBlob = await OrganizationAPI.printPDF({ token: user.token, id: order.id })
            const pdfUrl = URL.createObjectURL(pdfBlob)
            const newWindow = window.open(pdfUrl)
            if (newWindow) {
                newWindow.onload = () => {
                    newWindow.print()
                }
            }
        } catch (error) {
            console.error('Error fetching PDF:', error)
        }
    }

    return (
        <OrganizationLayout title="Purchase Order" active="orders">
            <>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="All" value={totalPurchaseOrders} />
                    </div>
                    {/* <div className={styles.Cards__card}>
                            <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Accepted" value="0" />
                        </div>
                        <div className={styles.Cards__card}>
                            <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Draft" value="0" />
                        </div> */}
                </div>
                <br />
                <Table>
                    <TableControls>
                        <div className={styles.Table__tableControls}>
                            <div>
                                <Button text="Export CSV" color="#0E2C24" />
                            </div>
                            <div className={styles.Table__row}>
                                <TableControlSearch onSearchChange={handleSearchChange} />
                            </div>
                        </div>
                    </TableControls>
                    <TableContent hoverable={!(data?.isLoading || data?.error)}>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Supplier</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Title</th>
                                <th>Requisition Number</th>
                                <th>Status</th>
                                <th>Bid Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.isLoading || data?.error ? (
                                <tr>
                                    <td colSpan="9">
                                        <Loader height="30vh" refresh={data.error} />
                                    </td>
                                </tr>
                            ) : purchaseOrders.length === 0 ? (
                                <tr>
                                    <td colSpan="9" style={{ textAlign: 'center' }}>
                                        No purchase orders found
                                    </td>
                                </tr>
                            ) : (
                                purchaseOrders?.map((order, index) => {
                                    const orgDetails = order?.bid_header_details?.organisation
                                    const auctionDetails = order?.bid_header_details?.auction_header
                                    return (
                                        <tr key={order.id} onClick={() => handleRowClick(order.id)}>
                                            <td>{index + 1}</td>
                                            <td>{orgDetails?.name}</td>
                                            <td>{orgDetails?.email}</td>
                                            <td>{orgDetails?.contact}</td>
                                            <td>{auctionDetails?.title}</td>
                                            <td>{auctionDetails?.requisition_number}</td>
                                            <td>{order.bid_header_details?.bid_status}</td>
                                            <td>{order.bid_header_details?.bidders_bid_number}</td>
                                            <td>
                                                <FaFilePdf
                                                    className={styles.PrintIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handlePrint(order)
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            )}
                        </tbody>
                    </TableContent>
                </Table>
            </>
        </OrganizationLayout>
    )
}

export default PurchaseOrder
